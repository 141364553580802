import React, { useMemo } from "react";
import TopNav from "./TopNav";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import { Box, Center, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { RatingStarSvg } from "../../../assets/icons";
import BarRatingItem from "./BarRatingItem";
import ReviewsTable from "./ReviewsTable";

const ProductReviews = ({ id, summary, count }) => {
  const {
    isLoading,
    data: res,
    refetch,
  } = useCustomQuery(Paths.productReviewsUrl + `/${id}?page_size=500`);
  // fetch current product

  const groupedRatings = useMemo(() => {
    let newList = {};
    let stats = {};

    if (res?.data?.results) {
      for (let rat of res?.data?.results) {
        newList[rat?.rating] = newList[rat?.rating]
          ? [...newList[rat?.rating], rat]
          : [rat];
      }

      for (let k of Object.keys(newList)) {
        const total_rating = newList[k].reduce((accumulator, object) => {
          return accumulator + object.rating;
        }, 0);

        stats[k] = total_rating;
      }
    }

    return { stats, newList };
  }, [res?.data?.results]);

  const totalRatings = useMemo(() => {
    if (Object.keys(groupedRatings?.stats)?.length > 0) {
      let total = 0;
      for (let r of Object.keys(groupedRatings?.stats)) {
        total += groupedRatings?.stats[r];
      }

      return total;
    }
  }, [groupedRatings]);

  return (
    <div
      className={
        "bg-white rounded-md overflow-hidden border border-[#D9D9D9] w-full"
      }
    >
      <TopNav name={"Product Reviews"} />

      {isLoading && (
        <div
          className={
            "flex-1 flex justify-center items-center py-10 font-Barlow font-[500] gap-x-3 text-lg"
          }
        >
          <Spinner /> fetching reviews . . .
        </div>
      )}

      <HStack>
        <Box className={"flex justify-end pt-6 pb-8 space-y-3 flex-1 pr-32"}>
          <div className={"flex flex-col gap-y-4 items-center"}>
            <Text className={"font-Barlow font-[700] text-black text-[32px]"}>
              {summary?.toFixed(1) || 0}

              <span className={"text-slate-400 text-xl"}>/5.0</span>
            </Text>

            <HStack className={"flex-row space-3"}>
              {Array.from(Array(parseInt(summary)).keys())?.map((item, ind) => (
                <Center key={ind}>
                  <RatingStarSvg
                    color={ind + 1 <= summary ? "#F1C94C" : "#EEEEEE"}
                    h={"26"}
                    w={"27"}
                  />
                </Center>
              ))}
            </HStack>

            <Text
              className={"text-gray-400 font-Barlow font-[500] text-[13px]"}
            >
              Based on {count} reviews
            </Text>
          </div>
        </Box>

        <Box flex={1}>
          <VStack className={"w-full py-3"}>
            {BAR_RATINGS?.map((item, ind) => {
              const currentG = groupedRatings?.stats[item?.num]
                ? groupedRatings?.stats[item?.num]
                : 0;

              return (
                <BarRatingItem
                  key={ind}
                  text={item?.name}
                  color={item?.color}
                  width={(currentG / totalRatings) * 100}
                  noRates={groupedRatings?.newList[item?.num]?.length}
                />
              );
            })}
          </VStack>
        </Box>
      </HStack>

      <ReviewsTable
        data={res?.data?.results}
        isLoading={isLoading}
        refetch={refetch}
      />
    </div>
  );
};

export default ProductReviews;

const BAR_RATINGS = [
  {
    name: "Excellent",
    rate: 0.85,
    color: "#0BA135",
    num: 5,
  },
  {
    name: "Good",
    rate: 0.75,
    color: "#95DC21",
    num: 4,
  },
  {
    name: "Average",
    rate: 0.3,
    color: "#FFE606",
    num: 3,
  },
  {
    name: "Below average",
    rate: 0.15,
    color: "#FF9B06",
    num: 2,
  },
  {
    name: "Poor",
    rate: 0.1,
    color: "#FF3306",
    num: 1,
  },
];
