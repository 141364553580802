import { PrivateAxiosUtility } from "./AxiosServices";


const uploadQuote = async (body, id) => {
    const res = await PrivateAxiosUtility({
        method: "put",
        url: `/api/v1/core/roof-plan/respond/${id}/`,
        data: body,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data
}
const downloadQuote = async (id) => {
    const res = await PrivateAxiosUtility.get(
        `/api/v1/core/roof-plan/download/${id}`
    )
    return res.data
}

const QuoteServices = {
    uploadQuote,
    downloadQuote
}
export default QuoteServices