import React, { useState } from "react";
import CButton from "../../general/CButton";
import { ConfigProvider, Popconfirm, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import { DeleteSvg, EditSvg } from "../../../assets/icons";
import { Text } from "@chakra-ui/react";
import { useMutation } from "react-query";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import AddProductPricing from "./AddProductPricing";

const PricingModels = ({ pricing, refetch }) => {
  const showToast = useCustomToast();

  const [mode, setMode] = useState("create");
  const [showForm, setShowForm] = useState(false);
  const [productData, setProductData] = useState({});

  const deletePricingMutation = useMutation(
    (id) => productServices?.deletePricing(id),
    {
      onSuccess: async () => {
        // handleClose();
        await refetch();
        showToast("Pricing Deleted.");
      },
    }
  );

  const handleEdit = (item) => {
    setProductData(item);
    setShowForm("true");
    setMode("update");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Gauge Size",
      dataIndex: "gauge_size",
      key: "gauge_size",
      render: (_, item) => (
        <div className={"flex gap-x-5 font-Barlow"}>
          <Text>{item?.gauge_size || "__"}</Text>
        </div>
      ),
    },
    {
      title: "Finish",
      dataIndex: "finish",
      key: "finish",
      render: (_, item) => (
        <div className={"flex gap-x-5 font-Barlow"}>
          <Text>{item?.finish || "__"}</Text>
        </div>
      ),
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
      render: (_, item) => (
        <div className={"flex gap-x-5 font-Barlow"}>
          <Text>{item?.width || "__"}</Text>
        </div>
      ),
    },
    {
      title: "unit Price(Kshs)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Stock Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Actions",
      width: 250,
      render: (_, item) => (
        <div className={"flex gap-x-5"}>
          <CButton
            className={
              " h-10 px-3 border border-primary-red rounded flex items-center text-primary-red font-Barlow font-[500] gap-x-2"
            }
            onClick={() => handleEdit(item)}
          >
            <EditSvg />
            Edit
          </CButton>

          <Popconfirm
            title="Delete the category"
            description={`Are you sure to delete pricing?`}
            onConfirm={() => deletePricingMutation.mutate(item?.id)}
            okText="Yes"
            cancelText="No"
          >
            <CButton>
              <DeleteSvg />
            </CButton>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div
      className={
        "pt-5 bg-white rounded-md overflow-hidden border border-[#D9D9D9] w-full"
      }
    >
      {/*header */}
      <div className={"flex justify-between px-8 py-3"}>
        <div />

        <CButton
          onClick={() => {
            setShowForm(true);
            setMode("create");
          }}
          className={
            "bg-primary-blue py-0 px-4 h-10 text-white font-inter flex items-center justify-center gap-x-3"
          }
          text={"Add Pricing"}
          icon={<span className={"text-xl leading-3 mb-1"}>+</span>}
        />
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={deletePricingMutation.isLoading}
          dataSource={pricing}
          columns={columns}
          rowKey={"id"}
        />
      </ConfigProvider>

      <AddProductPricing
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        product={productData}
        reset={() => {
          setMode("create");
          setProductData({});
        }}
        refetch={refetch}
        mode={mode}
      />
    </div>
  );
};

export default PricingModels;
