import { PrivateAxiosUtility } from "./AxiosServices";

const addRegion = async (body) => {
    const res = await PrivateAxiosUtility({
        method: "post",
        url: `/api/v1/cart/deliverycosts/add/`,
        data: body,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data
}

const updateRegion = async (data, r_id) => {
    const res = await PrivateAxiosUtility.patch(
        `/api/v1/cart/deliverycosts/update/${r_id}/`,
        data,
    );

    return res?.data;
};
const deleteRegion = async (id) => {
    const res = await PrivateAxiosUtility.delete(
        `/api/v1/cart/deliverycost/delete/${id}/`,
    );

    return res?.data;
};



const RegionService = {
    addRegion, updateRegion, deleteRegion
}
export default RegionService