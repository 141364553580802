import React from "react";

const Staff = ({ color = "black" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2212 20.2267C15.8732 17.8394 16.3552 15.6505 16.4693 14.3288C17.3463 14.6409 18.0623 15.0301 18.5778 15.4645C19.2065 15.9944 19.5 16.5564 19.5 17.1122V20.6122H13.9544L14.2212 20.2267ZM13.3169 20.0249L13.4154 20.6122H10.5846L10.6831 20.0249L11.4931 15.1949L11.5201 15.0343L11.4472 14.8886L10.8329 13.66C11.2152 13.6302 11.6047 13.6122 12 13.6122C12.3953 13.6122 12.7848 13.6302 13.1671 13.66L12.5528 14.8886L12.4799 15.0343L12.5069 15.1949L13.3169 20.0249ZM9.77885 20.2267L10.0456 20.6122H4.5V17.1122C4.5 16.5574 4.79496 15.9954 5.42565 15.4648C5.94185 15.0305 6.65744 14.6415 7.53077 14.3296C7.64496 15.6514 8.12709 17.8399 9.77885 20.2267ZM12 3.61218C13.9339 3.61218 15.5 5.17832 15.5 7.11218C15.5 9.04604 13.9339 10.6122 12 10.6122C10.0661 10.6122 8.5 9.04604 8.5 7.11218C8.5 5.17832 10.0661 3.61218 12 3.61218Z"
        stroke={color}
      />
    </svg>
  );
};

export default Staff;
