import React, { useEffect, useRef, useState } from "react";
import { Box, Center, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";
import CInput from "../../general/CInput";
import CButton from "../../general/CButton";
import { useMutation } from "react-query";
import ProductServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import useError from "../../../hooks/useError";
import CLoadingBtn from "../../general/CLoadingBtn";
import axios from "axios";
import { ENDPOINT } from "../../../services/AxiosServices";

const PricingModal = ({ handleClose: cHandleClose, isOpen, prodID }) => {
  const handleClose = () => {
    cHandleClose();
  };
  const [data, setData] = useState([]);

  const fetchPricing = async () => {
    const url = ENDPOINT + "/api/v1/core/product/pricing/";
    const response = await axios.get(url, { product_id: prodID });
    setData(response.data?.results);
  };
  useEffect(() => {
    fetchPricing();
  }, []);
  console.log(data);
  console.log("pro_id", prodID);
  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            Pricing Model
          </Text>

          {data.length === 0 ? (
            <div>No Pricing</div>
          ) : (
            data.map((item) => (
              <div className="gap-6" key={item.id}>
                <div className="flex justify-between text-xl border p-8">
                  <span>Gauge Size</span>
                  <span>{item.gauge_size || "null"}</span>
                </div>
                <div className="flex justify-between text-xl border p-8">
                  <span>Finish</span>
                  <span>{item.finish}</span>
                </div>
                <div className="flex justify-between text-xl border p-8">
                  <span>Width</span>
                  <span>{item.width}</span>
                </div>
              </div>
            ))
          )}
          <div className="flex justify-end">
            <CButton
              onClick={handleClose}
              text={"Cancel"}
              className={"rounded border-[#DC2A25] border text-[#DC2A25] mt-20"}
            />
          </div>
        </Box>
      </form>
    </Modal>
  );
};

export default PricingModal;
