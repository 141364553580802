import React, { useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import CInput from "../../../components/general/CInput";
import { ConfigProvider, Popconfirm, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import CButton from "../../../components/general/CButton";
import { DeleteSvg, EditSvg } from "../../../assets/icons";
import { Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import { AddCategory } from "../../../components/product_category";
import { ENDPOINT } from "../../../services/AxiosServices";

const ProductCategory = () => {
  const { isLoading, data, refetch } = useCustomQuery(
    Paths.productCategoriesUrl + "?page_size=1000"
  );

  // search query state
  const [searchQuery, setSearchQuery] = useState("");
  const { isLoading: loadingSearch, data: searchData } = useCustomQuery(
    `${Paths.productCategorySearchUrl}?search_query=${searchQuery}&page_size=1000`,
    searchQuery?.trim() !== ""
  );
  const showToast = useCustomToast();

  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState("create");
  const [editState, setEditState] = useState({});

  // activate/deactivate staff mutation
  const deleteMutation = useMutation(
    (data) => productServices?.deleteCategory(data),
    {
      onSuccess: async () => {
        // handleClose();
        await refetch();
        showToast("Category Deleted.");
        queryClient.invalidateQueries(Paths.productCategoriesUrl);
      },
    }
  );
  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const handleEdit = (item) => {
    setMode("update");
    setEditState(item);
    setShowForm(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "priority",
      dataIndex: "priority",
      key: "priority",
    },

    {
      title: "Actions",
      width: 250,
      render: (_, item) => (
        <div className={"flex gap-x-5 "}>
          <CButton
            className={
              " h-10 px-3 border border-primary-red rounded flex items-center text-primary-red font-Barlow font-[500] gap-x-2"
            }
            onClick={() => handleEdit(item)}
          >
            <EditSvg />
            Edit
          </CButton>

          <Popconfirm
            title="Delete the category"
            description={`Are you sure to delete category ${item?.name}?`}
            onConfirm={() => handleDelete(item?.id)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <CButton>
              <DeleteSvg />
            </CButton>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav />

      <div className={"pt-5 "}>
        {/*header */}
        <div className={"flex justify-between px-8 py-3"}>
          <CInput
            placeholder={"Search"}
            className={"rounded-xl min-w-[340px]"}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <CButton
            onClick={() => setShowForm(true)}
            className={
              "bg-primary-blue py-0 px-4 h-10 text-white font-inter flex items-center justify-center gap-x-3"
            }
            text={"Add Category"}
            icon={<span className={"text-xl leading-3 mb-1"}>+</span>}
          />
        </div>

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS["primary-blue"],
            },
          }}
        >
          <Table
            loading={isLoading || deleteMutation?.isLoading || loadingSearch}
            dataSource={
              searchQuery?.trim() !== ""
                ? searchData?.data
                : data?.data?.results
            }
            columns={columns}
            rowKey={"id"}
          />
        </ConfigProvider>
      </div>

      <AddCategory
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        editData={editState}
        reset={() => {
          setMode("create");
          setEditState({});
        }}
        mode={mode}
        refetch={refetch}
      />
    </div>
  );
};

export default ProductCategory;

const TopNav = () => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    <h6 className={"text-xl font-Barlow font-[700] py-4"}>
      Product Categories
    </h6>
  </div>
);
