import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf'; // Import from react-pdf
import { ENDPOINT } from '../../../services/AxiosServices';

const QuoteImage = ({ filecode, classname }) => {
    const [imageData, setImageData] = useState(null);
    const [isPDF, setIsPDF] = useState(false);

    useEffect(() => {
        const imageUrl = `${ENDPOINT}/api/v1/core/quote/file/${filecode}`;

        const fetchImage = async () => {
            const state = await JSON.parse(localStorage?.getItem('_token'));
            let authToken;
            authToken = state['access-token'];

            try {
                const response = await fetch(imageUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                if (response.ok) {
                    // Check if the response is a PDF
                    if (response.headers.get('content-type')?.includes('pdf')) {
                        setIsPDF(true); // Set to true if it's a PDF
                    } else {
                        const blob = await response.blob();
                        const imageurl = URL.createObjectURL(blob);
                        setImageData(imageurl);

                    }
                } else {
                    console.error('Failed to fetch image:', response.status, response.statusText);
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [filecode]);

    return (

        <img className={classname} src={imageData} alt={'img'} />

    );
};

export default QuoteImage;
