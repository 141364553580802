import React, { useEffect, useState } from "react";
import { Box, FormLabel, HStack, Select, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";
import { useCustomToast } from "../../../hooks/useToast";
import CInput from "../../general/CInput";
import CButton from "../../general/CButton";
import { useMutation } from "react-query";
import productServices from "../../../services/ProductServices";
import { useParams } from "react-router-dom";
import CLoadingBtn from "../../general/CLoadingBtn";

const AddProductPricing = ({
  isOpen,
  handleClose: cHandleClose,
  reset,
  product,
  mode,
  refetch,
}) => {
  const { id } = useParams();

  const [state, setState] = useState({
    gauge_size: null,
    finish: "",
    width: "",
    unit_price: "",
    stock_quantity: null,
    id: null,
  });
  const showToast = useCustomToast();

  const handleClose = () => {
    setState({
      gauge_size: null,
      finish: "",
      width: "",
      unit_price: "",
      stock_quantity: null,
      id: null,
    });
    cHandleClose();
  };

  const createMutation = useMutation(
    (data) => productServices.createProductPricing(data),
    {
      onSuccess: async () => {
        reset();
        showToast("Pricing saved.");
        handleClose();
        await refetch();
      },
    }
  );

  const updateMutation = useMutation(
    (data) => productServices.updateProductPricing(data, state?.id),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        handleClose();
        showToast("Pricing updated.");
        reset();

        await refetch();
      },
    }
  );

  // useEffect(() => {
  //   if (mutation?.isError) {
  //     handleError(mutation?.error);
  //   } else if (updateMutation?.isError) {
  //     handleError(updateMutation?.error);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mutation, updateMutation]);
  useEffect(() => {
    if (mode === "update") {
      setState({
        gauge_size: product?.gauge_size,
        id: product.id,
        unit_price: product?.price,
        stock_quantity: product?.quantity,
        finish: product?.finish,
        width: product?.width,
      });
    }
  }, [mode, product]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mode === "update") {
      updateMutation.mutate({
        gauge_size: state?.gauge_size,
        finish: state?.finish,
        width: state?.width,
        price: state?.unit_price,
        quantity: state?.stock_quantity,
      });
    } else {
      createMutation?.mutate({
        product: parseInt(id),
        gauge_size: parseInt(state?.gauge_size),
        finish: state?.finish,
        width: state?.width,
        price: parseInt(state?.unit_price),
        quantity: parseInt(state?.stock_quantity),
      });
    }
  };
  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            Add Pricing
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex w-full">
          <VStack gap={5} w={"full"}>
            <Box w={"full"}>
              <FormLabel className={"font-Barlow"}>Gauge Size</FormLabel>

              <CInput
                type={"number"}
                className={"rounded-md"}
                placeholder={"Gauge Size"}
                value={state?.gauge_size}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    gauge_size: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box w={"full"}>
              <FormLabel className={"font-Barlow"}>Finish</FormLabel>

              <CInput
                className={"rounded-md"}
                placeholder={"Finish"}
                value={state?.finish}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    finish: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box w={"full"}>
              <FormLabel className={"font-Barlow"}>Width</FormLabel>

              <Select
                placeholder="Select width"
                h={"14"}
                borderRadius={"sm"}
                className={
                  "font-Barlow focus:outline-none placeholder-gray-400"
                }
                value={state?.width}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    width: e.target.value,
                  }));
                }}
              >
                {widths?.map((w) => (
                  <option value={w?.value}>{w?.name}</option>
                ))}
              </Select>
            </Box>

            <Box w={"full"}>
              <FormLabel className={"font-Barlow"}>Unit Price (ksh)</FormLabel>

              <CInput
                className={"rounded-md"}
                placeholder={"price"}
                type={"number"}
                value={state?.unit_price}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    unit_price: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box w={"full"}>
              <FormLabel className={"font-Barlow"}>Stock Quantity</FormLabel>

              <CInput
                className={"rounded-md"}
                placeholder={"in stock"}
                type={"number"}
                value={state?.stock_quantity}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    stock_quantity: e.target.value,
                  }));
                }}
              />
            </Box>
          </VStack>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />
          {createMutation.isLoading || updateMutation?.isLoading ? (
            <div>
              <CLoadingBtn
                className={"min-w-44"}
                text={
                  updateMutation?.isLoading
                    ? "Updating product pricing"
                    : "Adding product pricing"
                }
              />
            </div>
          ) : (
            <CButton
              className={
                "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
              }
              text={mode === "update" ? "Update" : "Complete"}
              type={"submit"}
            />
          )}
        </HStack>
      </form>
    </Modal>
  );
};

export default AddProductPricing;

const widths = [
  {
    name: "1000mm",
    value: "1000mm",
  },
  {
    name: "1220mm",
    value: "1220mm",
  },
];
