import {Button, Form, Input, Popconfirm} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {CustomTable} from "../../components/CustomTable";
import HeaderBar from "../../components/HeaderBar";
import CustomModal from "../../components/Modal";
import AdminLayout from "../../layouts/Admin";
import {Context} from "../../store/MainContext";
import {PAGE_SIZE} from "../../utils/Constants";
import {useCustomToast} from "../../hooks/useToast";
import CustomerServices from "../../services/CustomerServices";

const Staff = () => {
    const {customerData, getCustomers, loading, setLoading} = useContext(Context)
    const showToast = useCustomToast();

    const [openModal, setOpenModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        getCustomers(1, PAGE_SIZE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsEditing(false);
    };


    const handleDeactivateActivate = async (data) => {
        try {
            await CustomerServices.activateDeactivateCustomer(data?.id, {
                status: data?.is_terminated ? 'activate' : 'deactivate'
            })

            setLoading(false)
            showToast(`Customer ${data?.first_name} ${data?.is_terminated ? 'Activated' : 'Deactivated'}.`)

            getCustomers(1, PAGE_SIZE);

        } catch (e) {
            showToast("Customer Update error. " + e?.message, "error")
            setLoading(false)
        }
    }


    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            defaultSortOrder: "ascend",
            render: (_, data) => (
                <span>{data?.first_name + " " + data?.last_name}</span>
            )
        },

        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            //   sorter: (a, b) => new Date(a.date) - new Date(b.date),
        },

        {
            title: "Phone",
            dataIndex: "phone_number",
            key: "phone_number",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            //   sorter: (a, b) => new Date(a.date) - new Date(b.date),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, data) => (
                <>
                    <Popconfirm
                        title={`Are you sure to ${data?.is_terminated ? 'Activate' : 'Deactivate'} ${data?.first_name}?`}
                        onConfirm={() => handleDeactivateActivate(data)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            className={`${data?.is_terminated ? "bg-green-600" : "bg-red-600"} font-medium text-gray-100`}>
                            {data?.is_terminated ? 'Activate' : 'Deactivate'}
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <AdminLayout>
                <HeaderBar
                    handlePress={handleOpenModal}
                    text={"ADD USER"}
                    title={"Users"}
                    subtext={"Manage users"}
                    hideBtn
                />

                <CustomTable
                    cols={columns}
                    loading={loading}
                    rowKey={(data, i) => i}
                    rows={customerData?.data || []}
                    style={{marginTop: "40px"}}
                    pagination={{
                        defaultPageSize: PAGE_SIZE,
                        total: parseInt(customerData.totalCount) / PAGE_SIZE,
                        onChange: page => {
                            getCustomers(page, PAGE_SIZE)
                        }
                    }}
                />
            </AdminLayout>

            <CustomModal
                handleCancel={handleCloseModal}
                handleOk={handleCloseModal}
                isModalOpen={openModal}
                title={isEditing ? "Update User" : "Create User"}
                w={800}
            >
                <Form.Item required label="First Name" name="first_name">
                    <Input/>
                </Form.Item>

                <Form.Item label="Last Name" name="last_name">
                    <Input/>
                </Form.Item>

                <Form.Item required label="Email" name="email">
                    <Input/>
                </Form.Item>

                <Form.Item required label="Phone" name="phone">
                    <Input/>
                </Form.Item>


            </CustomModal>
        </>
    );
};

export default Staff;


