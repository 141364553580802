import React from "react";
import { ConfigProvider, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import { Center, HStack, Switch, Text } from "@chakra-ui/react";
import { RatingStarSvg } from "../../../assets/icons";
import moment from "moment";
import { useMutation } from "react-query";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";

const ReviewsTable = ({ data, isLoading, refetch }) => {
  const showToast = useCustomToast();

  const hideReviewMutation = useMutation(
    (id) => productServices?.hideReview(id),
    {
      onSuccess: async () => {
        // handleClose();
        await refetch();
        showToast("Review hidden.");
      },
    },
  );
  const showReviewMutation = useMutation(
    (id) => productServices?.unhideReview(id),
    {
      onSuccess: async () => {
        // handleClose();
        await refetch();
        showToast("Review exposed.");
      },
    },
  );

  /**
   *
   * @param {number|string} r_id review id
   * @param {boolean} isChecked switch checked state
   */
  const handleReviewVisibility = (r_id, isChecked) => {
    if (isChecked) {
      showReviewMutation.mutate(r_id);
    } else {
      hideReviewMutation.mutate(r_id);
    }
  };

  const columns = [
    {
      title: "Rating",
      dataIndex: "rating",

      render: (_, item) => {
        return (
          <HStack gap={3}>
            <Text className={"font-Barlow text-sm font-[400] text-gray-500"}>
              ({item?.rating})
            </Text>

            <HStack className={"flex-row space-3"}>
              {Array.from(Array(parseInt(item?.rating)).keys())?.map(
                (item, ind) => (
                  <Center key={ind}>
                    <RatingStarSvg
                      color={ind + 1 <= item?.rating ? "#F1C94C" : "#EEEEEE"}
                      h={"26"}
                      w={"27"}
                    />
                  </Center>
                ),
              )}
            </HStack>
          </HStack>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Posted On",
      dataIndex: "review_date",
      render: (_, item) => {
        return (
          <Text className={"font-Barlow text-sm font-[500]"}>
            {moment(item?.review_date).format("DD MMMM YYYY")}
          </Text>
        );
      },
    },
    {
      title: "Show on Site",
      dataIndex: "hidden",
      render: (_, item) => {
        return (
          <HStack gap={3}>
            <Text className={"font-Barlow text-sm font-[500]"}>
              {item?.hidden ? "Hidden" : "Visible"}
            </Text>

            <Switch
              size={"sm"}
              colorScheme={"green"}
              isChecked={!item.hidden}
              onChange={(e) =>
                handleReviewVisibility(item?.id, e.target.checked)
              }
            />
          </HStack>
        );
      },
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: COLORS["primary-blue"],
        },
      }}
    >
      <Table
        loading={
          isLoading ||
          hideReviewMutation.isLoading ||
          showReviewMutation.isLoading
        }
        dataSource={data}
        columns={columns}
        rowKey={"id"}
        rowClassName={"font-Barlow"}
      />
    </ConfigProvider>
  );
};

export default ReviewsTable;
