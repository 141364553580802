import {Modal} from "antd";
import React from "react";

const CustomModal = ({
                         isModalOpen,
                         handleOk,
                         handleSubmit,
                         handleCancel,
                         title,
                         w,
                         children,
                     }) => {
    return (
        <Modal
            title={title}
            open={isModalOpen}
            onOk={handleSubmit}
            onCancel={handleCancel}
            width={w}
        >

            <div className="flex flex-col mb-4">{children}</div>


        </Modal>
    );
};

export default CustomModal;
