import React, {createContext, useState} from "react";
import {useCustomToast} from "../hooks/useToast";
import CustomerServices from "../services/CustomerServices";
import StaffServices from "../services/StaffServices";
import {PAGE_SIZE} from "../utils/Constants";

export const Context = createContext();

const MainContext = ({children}) => {
    const showToast = useCustomToast();

    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false)
    const [customerData, setCustomerData] = useState({
        nextPage: '',
        prevPage: '',
        data: [],
        totalCount: 0,
    })
    const [staffData, setStaffData] = useState({
        nextPage: '',
        prevPage: '',
        data: [],
        totalCount: 0,

    })

    /**
     * @desc fetches all customers and stores them to the customer state
     * @returns {Promise<void>}
     */
    const getCustomers = async (page = 1) => {

        setCustomerData(prev => ({

            nextPage: null, prevPage: null, data: []
        }))
        setLoading(true)

        try {

            let res = await CustomerServices.fetchCustomers(page, PAGE_SIZE);

            setCustomerData(prev => ({

                nextPage: page, data: [...prev?.data, ...res?.results], totalCount: res?.count
            }))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            showToast("Fetch customers error. " + e?.message, "error")
        }

    }

    /**
     * @desc fetches all staff and stores them to the staff state
     * @returns {Promise<void>}
     */
    const getStaff = async (page = 1) => {
        setStaffData(prev => ({
            nextPage: null, prevPage: null, data: []
        }))
        setLoading(true)

        try {
            let res = await StaffServices.fetchStaff(page, PAGE_SIZE)

            setStaffData(prev => ({

                nextPage: page, data: [...prev?.data, ...res?.results], totalCount: res?.count
            }))
            setLoading(false)

        } catch (e) {
            setLoading(false)
            showToast("Fetch customers error." + e?.message, "error")
        }
    }

    return (
        <Context.Provider
            value={{
                token,
                setToken,
                customerData,
                getCustomers,
                getStaff,
                staffData,
                loading, setLoading
            }}>{children}</Context.Provider>
    );
};

export default MainContext;
