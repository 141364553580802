import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import {
  Box,
  Center,
  FormLabel,
  HStack,
  MenuOptionGroup,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { ProductDetails } from "../../../components/products";
import ProductImages from "../../../components/products/view/ProductImages";
import ProductRoofImages from "../../../components/products/view/ProductRoofImages";
import ProductReviews from "../../../components/products/view/ProductReviews";
import PricingModels from "../../../components/products/view/PricingModels";
import TopNav from "../../../components/products/view/TopNav";
import CButton from "../../../components/general/CButton";
import CInput from "../../../components/general/CInput";
import { Select } from "antd";
import ProductServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import { ENDPOINT } from "../../../services/AxiosServices";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { SketchPicker } from "react-color";
import { CloseOutlined } from "@ant-design/icons";

const ViewProduct = () => {
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const {
    isLoading,
    data: res,
    refetch,
  } = useCustomQuery(Paths.singleProductPreUrl + `${id}?page_size=500`);

  const { data: categories } = useCustomQuery(Paths.productCategoriesUrl);
  let _categories = categories?.data?.results;

  const [thumbnails, setThumbnails] = useState({});
  const [roof_details, setRoofDetails] = useState({});
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [length, setLength] = useState(null);
  const [description, setDescription] = useState("");
  const [unitname, setUnitName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [thumbnail_color, setThumbnailColor] = useState({});
  const [roof_color, setRoofColor] = useState({});
  const [hideRoofPallete, setHideRoofPallete] = useState(false);
  const [hideProdPallete, setHideProdPallete] = useState(true);
  const [hideProdSave, setHideProdSave] = useState(false);
  const [hideRoofSave, setHideRoofSave] = useState(false);
  const [editingImageIndex, setEditingImageIndex] = useState(null);
  const [editingThumbnail, setEditingThumbnail] = useState(null);
  const [editedImage, setEditedImage] = useState({});
  const [editedThumbnail, setEditedThumbnail] = useState({});

  const showToast = useCustomToast();

  const handleSaveThumbnail = async () => {
    setLoading2(true);
    let form = new FormData();

    form.append("thumbnail_color", Object.values(thumbnail_color).join(","));

    for (const key of Object.keys(thumbnails)) {
      form.append("image", thumbnails[key]);
    }
    await ProductServices.addThumbnail(form, id);
    setLoading2(false);
  };
  //....
  const handleSaveRoofPlan = async () => {
    setLoading(true);
    let form = new FormData();
    form.append("roof_detail_color", Object.values(roof_color).join(","));

    for (const key of Object.keys(roof_details)) {
      form.append("roof_thumbnail", roof_details[key]);
    }
    await ProductServices.addRoofPlan(form, id);
    setLoading(false);
  };

  //...
  const mutation = useMutation(() => handleSaveThumbnail(), {
    onSuccess: async () => {
      showToast("Product updated successfully.");
    },
  });
  const addRoofMutation = useMutation(() => handleSaveRoofPlan(), {
    onSuccess: async () => {
      showToast("Roof plan added successfully.");
    },
  });
  const handleSubmitThumbnail = () => {
    mutation.mutate();
    setTimeout(() => {
      setHideProdSave(true);
      window.location.reload();
    }, 5000);
  };

  const handleSubmitRoofPlan = () => {
    addRoofMutation.mutate();
    setTimeout(() => {
      setHideRoofSave(true);
      window.location.reload();
    }, 5000);
  };

  let formData = new FormData();
  formData.append("name", name);
  formData.append("category", category);
  formData.append("description", description);
  formData.append("unit_name", unitname);

  const updateMutation = useMutation(
    () => ProductServices.updateProduct(id, formData),
    {
      onSuccess: async () => {
        showToast("Product updated.");
      },
    }
  );

  const handleSubmit = () => {
    updateMutation.mutate();
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const handleEditImage = (index) => {
    setEditingImageIndex(index);
  };
  const handleEditThumbnailImage = (index) => {
    setEditingThumbnail(index);
  };

  const handleRemoveRoofPlan = async (r_id) => {
    await ProductServices.removeRoofPlan(r_id);
    showToast("Roof Plan deleted");
    window.location.reload();
  };
  const handleRemoveThumbnail = async (t_id) => {
    await ProductServices.removeThumbnail(t_id);
    showToast("Product Thumbnail deleted..");
    window.location.reload();
  };
  const handleColorChange = (type, key, color) => {
    if (type === "thumbnails") {
      setThumbnailColor((prevColors) => ({
        ...prevColors,
        [key]: color.hex,
      }));
    } else if (type === "roofthumbnails") {
      setRoofColor((prevColors) => ({
        ...prevColors,
        [key]: color.hex,
      }));
    }
  };

  const handleRemoveImage = () => {
    // Add a function to remove the existing image from the list
    // After successfully removing the image, reset the state
    window.location.reload();
    setEditedImage({});
    setEditedThumbnail({});
  };

  const handleEditRoof = async (id) => {
    let roofData = new FormData();
    for (const key of Object.keys(editedImage)) {
      roofData.append("roof_thumbnail", editedImage[key]);
    }
    roofData.append("roof_detail_color", Object.values(roof_color).join(","));
    await ProductServices.updateRoofThumbnail(id, roofData);
    showToast("Roof thumbnail updated successfully!!");
    window.location.reload();
  };

  const handleSaveEditThumbnail = async (id) => {
    let prodData = new FormData();
    prodData.append(
      "thumbnail_color",
      Object.values(thumbnail_color).join(",")
    );

    for (const key of Object.keys(editedThumbnail)) {
      prodData.append("image", editedThumbnail[key]);
    }
    await ProductServices.updateProdThumbnail(id, prodData);
    showToast("Product thumbnail updated successfully...");
    window.location.reload();
  };

  useEffect(() => {
    setCategory(res?.data?.category?.id);
    setName(res?.data?.name);
    setUnitName(res?.data?.unit_name);
    setDescription(res?.data?.description);
    setLength(res?.data?.length);
    // setColor(res?.data?.thumbnail_color);
  }, [res?.data]);

  // fetch current product
  if (isLoading) {
    return (
      <div
        className={
          "flex-1 flex justify-center items-center pt-20 font-Barlow font-[500] gap-x-3 text-lg"
        }
      >
        <Spinner /> Loading . . .
      </div>
    );
  }
  return (
    <>
      <div className="flex justify-between w-auto">
        <TopNav name={res?.data?.name} />
        <CButton
          text={"Edit Product"}
          className={
            "bg-none border border-primary-red text-primary-red h-8 mr-10 p-0 py-0 text-sm font-Barlow font-medium px-3 mt-5"
          }
          onClick={() => setEdit(true)}
        />
      </div>

      <VStack gap={5} className={"bg-[#FBFBFF] h-full border-none"}>
        {/*    product details    */}
        {edit ? (
          <>
            <VStack w={"full"} className="mt-5">
              <HStack w={"full"}>
                <Box flex={1}>
                  <FormLabel>Product Name</FormLabel>
                  <CInput
                    className={"rounded-md"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>
                <Box flex={1}>
                  <FormLabel>Category</FormLabel>
                  <select
                    className="w-[100%] h-12 bg-white "
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    {_categories?.map((_category) => (
                      <option key={_category.id} value={_category.id}>
                        {_category.name}
                      </option>
                    ))}
                  </select>
                </Box>
              </HStack>
              <HStack w={"full"}>
                <Box flex={1}>
                  <FormLabel>Unit Name</FormLabel>
                  <CInput
                    className={"rounded-md"}
                    value={unitname}
                    onChange={(e) => setUnitName(e.target.value)}
                  />
                </Box>

                <Box flex={1}>
                  <FormLabel>Length</FormLabel>
                  <CInput
                    className={"rounded-md"}
                    value={length}
                    onChange={(e) => {
                      setLength(e.target.value);
                    }}
                  />
                </Box>
              </HStack>
              <HStack w={"full"}>
                <Box flex={1}>
                  <FormLabel>Description</FormLabel>
                  <textarea
                    className="h-56 w-full"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Box>
              </HStack>
            </VStack>
            <HStack
              py={"4"}
              px={"8"}
              w={"full"}
              fontFamily={"Barlow"}
              className={
                "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
              }
            >
              <CButton
                className={
                  "text-primary-red border border-primary-red font-[700] text-base px-8"
                }
                text={"Cancel"}
                onClick={() => setEdit(false)}
                type={"button"}
              />
              <CButton
                className={
                  "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
                }
                text={"Save"}
                onClick={() => handleSubmit()}
                type={"button"}
              />
            </HStack>
          </>
        ) : (
          <ProductDetails
            name={res?.data?.name}
            category={res?.data?.category?.name}
            unit={`${res?.data?.unit_name}`}
            description={res?.data?.description}
            colors={res?.data?.thumbnail_colors}
            lengths={res?.data?.length}
            guageSize={res?.data?.pricing?.at(0)?.gauge_size}
          />
        )}
        {/*    Product images  */}
        <Box mt={2} w={"full"}>
          <HStack justifyContent={"space-between"} flex={1}>
            <FormLabel className={"font-Barlow text-sm text-gray-400"}>
              Product Images
            </FormLabel>
          </HStack>

          <Box
            className={
              "flex-wrap border border-gray-400 justify-between  h-auto"
            }
            display={"flex"}
            mt={"2"}
            p={"3"}
            borderRadius={"sm"}
          >
            {res?.data?.thumbnails.map((thumbnail, index) => (
              <div className={"w-[30%] relative  "} key={thumbnail?.id}>
                {editingThumbnail === thumbnail.id ? (
                  <>
                    {Object.keys(editedThumbnail).map((i) => (
                      <div className={"w-[100%] h-auto relative "}>
                        <img
                          src={URL.createObjectURL(editedThumbnail[i])}
                          className={"h-full max-h-40 object-contain mb-2"}
                          alt={URL.createObjectURL(editedThumbnail[i])}
                        />

                        <div className="flex justify-between gap-8">
                          <a
                            className="bg-none border border-primary-red text-primary-red h-8 mb-10 p-0 py-0 text-sm font-Barlow  px-3"
                            onClick={() => setHideProdPallete(true)}
                          >
                            open color
                          </a>
                          <div className="w-32">
                            <input
                              placeholder="add color"
                              className={`border w-full bg-[${
                                thumbnail_color[i] || "#FFFFFF"
                              }] `}
                              value={thumbnail_color[i] || ""}
                              onChange={(e) => {
                                handleColorChange("thumbnails", i, {
                                  hex: e.target.value,
                                });
                              }}
                            />
                            {hideProdPallete && (
                              <>
                                <a
                                  className="mt-10"
                                  onClick={() => setHideProdPallete(false)}
                                >
                                  <CloseOutlined
                                    style={{ fontSize: 20, color: "red" }}
                                  />
                                </a>
                                <SketchPicker
                                  color={thumbnail_color[i] || ""}
                                  onChange={(color) => {
                                    handleColorChange("thumbnails", i, color);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="flex gap-4  ">
                      <input
                        type="file"
                        onChange={(e) => setEditedThumbnail(e.target.files)}
                        accept="image/*"
                      />

                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-12 w-32 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => handleSaveEditThumbnail(thumbnail?.id)}
                      >
                        Save
                      </CButton>
                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-12 w-32 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={handleRemoveImage}
                      >
                        Cancel
                      </CButton>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={thumbnail.image}
                      className={"h-auto max-h-60 object-cover mb-2"}
                      alt={`Product Thumbnail ${thumbnail.id}`}
                    />
                    <div className="flex gap-5">
                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => handleRemoveThumbnail(thumbnail.id)}
                      >
                        Remove
                      </CButton>
                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-blue text-primary-blue h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => handleEditThumbnailImage(thumbnail.id)}
                      >
                        Edit
                      </CButton>
                      <input
                        className="h-12 border w-12 rounded-[50%]"
                        style={{ backgroundColor: thumbnail.thumbnail_color }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}

            {Object?.keys(thumbnails)?.map((i) => {
              if (!thumbnails[i]) return <></>;
              return (
                <div className={"w-[30%] h-auto relative "} key={i}>
                  <img
                    src={URL.createObjectURL(thumbnails[i])}
                    className={"h-full max-h-40 object-contain mb-2"}
                    alt={URL.createObjectURL(thumbnails[i])}
                  />

                  <div className="flex justify-between gap-8">
                    <CButton
                      type={"button"}
                      className={
                        "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                      }
                      onClick={() => {
                        setThumbnails((prev) => ({ ...prev, [i]: null }));
                        setThumbnailColor((prevColors) => {
                          const { [i]: deletedColor, ...restColors } =
                            prevColors;
                          return restColors;
                        });
                      }}
                    >
                      Remove
                    </CButton>
                    <a
                      className="bg-none border border-primary-red text-primary-red h-8 p-0 py-0 text-sm font-Barlow font-medium px-3"
                      onClick={() => setHideProdPallete(true)}
                    >
                      open color
                    </a>
                    <div className="w-32">
                      <input
                        placeholder="add color"
                        className={`border w-full bg-[${
                          thumbnail_color[i] || ""
                        }] `}
                        value={thumbnail_color[i] || ""}
                        onChange={(e) => {
                          handleColorChange("thumbnails", i, {
                            hex: e.target.value,
                          });
                        }}
                      />
                      {hideProdPallete && (
                        <>
                          <a
                            className="mt-10"
                            onClick={() => setHideProdPallete(false)}
                          >
                            <CloseOutlined
                              style={{ fontSize: 20, color: "red" }}
                            />
                          </a>
                          <SketchPicker
                            color={thumbnail_color[i] || ""}
                            onChange={(color) => {
                              handleColorChange("thumbnails", i, color);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <Uploader handleChange={(e) => setThumbnails(e.target?.files)} />
          </Box>
          {Object.keys(thumbnails).length > 0 && !hideProdSave && (
            <HStack
              py={"4"}
              px={"8"}
              w={"full"}
              fontFamily={"Barlow"}
              className={
                "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
              }
            >
              <CButton
                className={
                  "bg-primary-white text-primary-red border border-primary-red font-[700] text-base px-8"
                }
                onClick={() => window.location.reload()}
                text={"Cancel"}
                type={"button"}
              />
              <CButton
                className={
                  "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
                }
                text={loading2 ? "Saving.." : "Save"}
                onClick={() => handleSubmitThumbnail()}
                type={"button"}
              />
            </HStack>
          )}
        </Box>
        /**................ */
        <Box mt={2} w={"full"}>
          <HStack justifyContent={"space-between"} flex={1}>
            <FormLabel className={"font-Barlow text-sm text-gray-400"}>
              Roof Images
            </FormLabel>
          </HStack>

          <Box
            className={
              "flex-wrap border border-gray-400 justify-center  h-auto"
            }
            display={"flex"}
            mt={"2"}
            p={"3"}
            borderRadius={"sm"}
          >
            {res?.data?.roof_details.map((images, index) => (
              <div className={"w-[30%] relative  "} key={images?.id}>
                {editingImageIndex === images.id ? (
                  <>
                    {Object.keys(editedImage).map((i) => (
                      <div className={"w-[100%] h-auto relative "}>
                        <img
                          src={URL.createObjectURL(editedImage[i])}
                          className={"h-full max-h-40 object-contain mb-2"}
                          alt={URL.createObjectURL(editedImage[i])}
                        />

                        <div className="flex justify-between gap-8">
                          <a
                            className="bg-none border border-primary-red text-primary-red h-8 mb-10 p-0 py-0 text-sm font-Barlow  px-3"
                            onClick={() => setHideRoofPallete(true)}
                          >
                            open color
                          </a>
                          <div className="w-32">
                            <input
                              placeholder="add color"
                              className={`border w-full bg-[${
                                roof_color[i] || "#FFFFFF"
                              }] `}
                              value={roof_color[i] || ""}
                              onChange={(e) => {
                                handleColorChange("roofthumbnails", i, {
                                  hex: e.target.value,
                                });
                              }}
                            />
                            {hideRoofPallete && (
                              <>
                                <a
                                  className="mt-10"
                                  onClick={() => setHideRoofPallete(false)}
                                >
                                  <CloseOutlined
                                    style={{ fontSize: 20, color: "red" }}
                                  />
                                </a>
                                <SketchPicker
                                  color={roof_color[i] || ""}
                                  onChange={(color) => {
                                    handleColorChange(
                                      "roofthumbnails",
                                      i,
                                      color
                                    );
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="flex gap-4  ">
                      <input
                        type="file"
                        onChange={(e) => setEditedImage(e.target.files)}
                        accept="image/*"
                      />

                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-12 w-32 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => handleEditRoof(images?.id)}
                      >
                        Save
                      </CButton>
                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-12 w-32 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={handleRemoveImage}
                      >
                        Cancel
                      </CButton>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={ENDPOINT + images.roof_thumbnail}
                      className={"h-auto max-h-40 object-cover mb-2"}
                      alt={`Product Thumbnail ${images.id}`}
                    />
                    <div className="flex gap-5">
                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => handleRemoveRoofPlan(images.id)}
                      >
                        Remove
                      </CButton>
                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-blue text-primary-blue h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => handleEditImage(images.id)}
                      >
                        Edit
                      </CButton>
                      <input
                        className="h-12 border w-12 rounded-[50%]"
                        style={{ backgroundColor: images.roof_detail_color }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}

            {Object?.keys(roof_details)?.map((i) => {
              if (!roof_details[i]) return <></>;
              return (
                <div className={"w-[30%] h-auto relative "} key={i}>
                  <img
                    src={URL.createObjectURL(roof_details[i])}
                    className={"h-full max-h-40 object-contain mb-2"}
                    alt={URL.createObjectURL(roof_details[i])}
                  />

                  <div className="flex justify-between gap-8">
                    <CButton
                      type={"button"}
                      className={
                        "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                      }
                      onClick={() => {
                        setRoofDetails((prev) => ({ ...prev, [i]: null }));
                        setRoofColor((prevColors) => {
                          const { [i]: deletedColor, ...restColors } =
                            prevColors;
                          return restColors;
                        });
                      }}
                    >
                      Remove
                    </CButton>
                    <a
                      className="bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                      onClick={() => setHideRoofPallete(true)}
                    >
                      open color
                    </a>
                    <div className="w-32">
                      <input
                        placeholder="add color"
                        className={`border w-full bg-[${
                          roof_color[i] || "#FFFFFF"
                        }] `}
                        value={roof_color[i] || ""}
                        onChange={(e) => {
                          handleColorChange("roofthumbnails", i, {
                            hex: e.target.value,
                          });
                        }}
                      />
                      {hideRoofPallete && (
                        <>
                          <a
                            className="mt-10"
                            onClick={() => setHideRoofPallete(false)}
                          >
                            <CloseOutlined
                              style={{ fontSize: 20, color: "red" }}
                            />
                          </a>
                          <SketchPicker
                            color={roof_color[i] || ""}
                            onChange={(color) => {
                              handleColorChange("roofthumbnails", i, color);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <Uploader handleChange={(e) => setRoofDetails(e.target?.files)} />
          </Box>
          {Object.keys(roof_details).length > 0 && !hideRoofSave && (
            <HStack
              py={"4"}
              px={"8"}
              w={"full"}
              fontFamily={"Barlow"}
              className={
                "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
              }
            >
              <CButton
                className={
                  "bg-primary-white text-primary-red border border-primary-red font-[700] text-base px-8"
                }
                onClick={() => window.location.reload()}
                text={"Cancel"}
                type={"button"}
              />
              <CButton
                className={
                  "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
                }
                text={loading ? "Saving.." : "Save"}
                onClick={() => handleSubmitRoofPlan()}
                type={"button"}
              />
            </HStack>
          )}
        </Box>
        {/*    Product Reviews  */}
        <ProductReviews
          id={id}
          summary={res?.data?.review_summary}
          count={res?.data?.total_reviews}
        />
        {/*    Pricing models  */}
        <PricingModels pricing={res?.data?.pricing} refetch={refetch} />
      </VStack>
    </>
  );
};

export default ViewProduct;

const Uploader = ({ handleChange }) => {
  const uploadRef = useRef();

  const handleUpload = () => {
    if (uploadRef?.current) {
      uploadRef.current.click();
    }
  };

  return (
    <Center
      className={
        "border border-dashed border-grey_500 h-40 pt-2 w-[30%] rounded-lg"
      }
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload Image"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={uploadRef}
        onChange={handleChange}
      />
    </Center>
  );
};
