import React from "react";
import CButton from "./CButton";
import { Spinner } from "./index";

const CLoadingBtn = ({ text = "loading", className }) => {
  return (
    <CButton
      className={`bg-primary-red text-white font-medium text-lg p-3 opacity-70 w-10/12 mx-auto ${className} `}
      type={"submit"}
    >
      <div className="flex justify-center text-base gap-3 items-center">
        {text} <Spinner />
      </div>
    </CButton>
  );
};

export default CLoadingBtn;
