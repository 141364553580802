import { Button, Popconfirm } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CustomTable } from "../../components/CustomTable";
import HeaderBar from "../../components/HeaderBar";
import CustomModal from "../../components/Modal";
import { Context } from "../../store/MainContext";
import { PAGE_SIZE } from "../../utils/Constants";
import { useCustomToast } from "../../hooks/useToast";
import StaffServices from "../../services/StaffServices";

const Staff = () => {
  const { staffData, getStaff, loading, setLoading } = useContext(Context);
  const showToast = useCustomToast();

  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    getStaff(1, PAGE_SIZE);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditing(false);
  };

  const handleEdit = (data) => {
    const { first_name, last_name, email, id } = data;
    setState((prev) => ({ ...prev, id, first_name, last_name, email }));
    setOpenModal(true);
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreate = async (e) => {
    e?.preventDefault();
    setLoading(true);

    try {
      await StaffServices.registerStaff(state);

      handleCloseModal();
      setLoading(false);
      showToast("Index Registered");
      getStaff(1, PAGE_SIZE);
    } catch (e) {
      showToast("Index Creation error. " + e?.message, "error");
      setLoading(false);
    }
  };
  const handleUpdate = async (e) => {
    e?.preventDefault();
    setLoading(true);
    const { id, phone_number, ...rest } = state;

    try {
      handleCloseModal();
      await StaffServices.updateStaff(state?.id, rest);

      setLoading(false);
      showToast(`Staff ${state?.first_name} Updated`);

      // reset state
      setIsEditing(false);
      setState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
      });

      getStaff(1, PAGE_SIZE);
    } catch (e) {
      showToast("Index Update error. " + e?.message, "error");
      setLoading(false);
    }
  };

  const handleActivateDeactivate = async (data) => {
    try {
      await StaffServices.activateDeactivateStaff(data?.id, {
        status: data?.is_terminated ? "activate" : "deactivate",
      });

      setLoading(false);
      showToast(
        `Staff ${data?.first_name} ${
          data?.is_terminated ? "Activated" : "Deactivated"
        }.`,
      );

      getStaff(1, PAGE_SIZE);
    } catch (e) {
      showToast("Index Update error. " + e?.message, "error");
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    if (isEditing) {
      handleUpdate(e);
    } else {
      handleCreate(e);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      render: (_, data) => (
        <div>{`${data?.first_name} ${data?.last_name}`}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      //   sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      //   sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, data) => (
        <>
          <Button
            className="bg-blue-600 font-medium text-gray-100"
            onClick={() => handleEdit(data)}
          >
            Edit
          </Button>
          &nbsp;
          <Popconfirm
            title={`Are you sure you want to ${
              data?.is_terminated ? "activate" : "deactivate"
            } ${data?.first_name} ?`}
            onConfirm={() => handleActivateDeactivate(data)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className={`${
                data?.is_terminated ? "bg-green-600" : "bg-red-600"
              } font-medium text-gray-100`}
            >
              {data?.is_terminated ? "Activate" : "Deactivate"}
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <HeaderBar
          handlePress={handleOpenModal}
          text={"ADD STAFF"}
          title={"Staff"}
          subtext={"Manage staff"}
        />

        <CustomTable
          loading={loading}
          rowKey={(data, i) => i}
          cols={columns}
          rows={staffData.data || []}
          style={{ marginTop: "40px" }}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            total: parseInt(staffData.totalCount),
            onChange: (page) => {
              getStaff(page, PAGE_SIZE);
            },
          }}
        />
      </div>

      <CustomModal
        handleCancel={handleCloseModal}
        handleOk={handleCloseModal}
        isModalOpen={openModal}
        title={isEditing ? "Update User" : "Create User"}
        w={800}
        handleSubmit={handleSubmit}
      >
        <form className={"flex flex-col gap-3 "}>
          <div className={"flex gap-1 items-center  "}>
            <label className={"min-w-[130px] "}>First Name</label>
            <input
              value={state?.first_name}
              name={"first_name"}
              className={"px-3 flex-1 h-10 rounded border border-gray-300"}
              onChange={handleChange}
            />
          </div>

          <div className={"flex gap-1 items-center"}>
            <label className={"min-w-[130px] "}>Last Name</label>
            <input
              name={"last_name"}
              value={state?.last_name}
              className={"px-3 flex-1 h-10 rounded border border-gray-300"}
              onChange={handleChange}
            />
          </div>

          {!isEditing && (
            <div className={"flex gap-1 items-center"}>
              <label className={"min-w-[130px] "}>Phone number</label>
              <input
                name={"phone_number"}
                value={state?.phone_number}
                className={"px-3 flex-1 h-10 rounded border border-gray-300"}
                onChange={handleChange}
              />
            </div>
          )}

          <div className={"flex gap-1 items-center"}>
            <label className={"min-w-[130px] "}>Email</label>
            <input
              name={"email"}
              value={state?.email}
              className={"px-3 flex-1 h-10 rounded border border-gray-300"}
              onChange={handleChange}
            />
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default Staff;
