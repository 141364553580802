import React, { useContext, useEffect, useState } from "react";
import { Context } from "../store/MainContext";
import { useNavigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";

const PrivateRoute = ({ children }) => {
  const { token, setToken } = useContext(Context);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleToken = async () => {
      // check if token does not exist
      if (token && token["access-token"]) {
        setLoading(false);
      } else {
        // retrieve token data from local storage
        /**@type {import("../services/AuthServices").Auth} */
        const savedToken = await localStorage.getItem("_token");
        if (savedToken) {
          savedToken && setToken(JSON.parse(savedToken));
          setLoading(false);
        } else {
          navigate("/");
          setLoading(false);
        }
      }
    };

    handleToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  if (loading) {
    return (
      <Center flex={1} className={"h-screen"}>
        <Spinner color={"black"} size={"xl"} thickness="4px" speed="0.65s" />
      </Center>
    );
  }

  return <>{children}</>;
};

export default PrivateRoute;
