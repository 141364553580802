import {PrivateAxiosUtility} from "./AxiosServices";

/**
 *
 * @param {Number} page
 * @param {Number} size
 * @returns {Promise<Object>}
 */
const fetchStaff = async (page = 1, size = 10) => {
  const res = await PrivateAxiosUtility.get(
    `/dashboard/staff/?page=${page}&page_size=${size}`,
  );

  return res?.data;
};

/**
 * @param {String} user_id
 * @returns {Promise<Object>}
 */
const fetchSingleStaff = async (user_id) => {
  const res = await PrivateAxiosUtility.get(`/dashboard/staff/${user_id}/`);

  return res?.data;
};

/**
 * @param {String} user_id
 * @returns {Promise<Object>}
 * @desc updates customer details
 */
const updateStaff = async (user_id, data) => {
  const res = await PrivateAxiosUtility.put(
    `/dashboard/staff/update/${user_id}/`,
    data,
  );

  return res.data;
};

/**
 * @param {Number} user_id
 * @param {{status:String}} aData
 * @returns {Promise<void>}
 */
const activateDeactivateStaff = async (user_id, aData) => {
  const res = await PrivateAxiosUtility.put(
    `dashboard/staff/${user_id}/activate-or-deactivate/`,
    aData,
  );

  return res.data;
};

const registerStaff = async (rData) => {
  const res = await PrivateAxiosUtility.post(
    `dashboard/staff/registration/`,
    rData,
  );

  return res.data;
};
const StaffServices = {
  fetchStaff,
  fetchSingleStaff,
  updateStaff,
  activateDeactivateStaff,
  registerStaff,
};

export default StaffServices;
