import React, { useEffect, useState } from "react";
import { Box, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import CInput from "../../general/CInput";
import CButton from "../../general/CButton";
import { Modal } from "antd";
import { useMutation } from "react-query";
import { useCustomToast } from "../../../hooks/useToast";
import customerServices from "../../../services/CustomerServices";
import CLoadingBtn from "../../general/CLoadingBtn";

const UpdateCustomer = ({
  isOpen,
  handleClose,

  mode,
  user,
  reset,
  refetch,
}) => {
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    id: null,
  });
  const showToast = useCustomToast();

  const updateMutation = useMutation(
    () => customerServices.updateCustomer(state?.id, state),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        handleClose();
        showToast("Customer updated.");
        reset();
        await refetch();
      },
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mode === "update") {
      updateMutation.mutate();
    } else {
    }
  };

  useEffect(() => {
    if (mode === "update") {
      setState({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number,
        id: user?.id,
      });
    }
  }, [mode, user]);
  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            Update Customer Account
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex w-full">
          <VStack gap={5} w={"full"}>
            <HStack gap={5} w={"full"}>
              <Box flex={1}>
                <FormLabel>First Name</FormLabel>

                <CInput
                  required
                  className={"rounded-md"}
                  placeholder={"Enter name"}
                  value={state?.first_name}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      first_name: e.target.value,
                    }));
                  }}
                />
              </Box>

              <Box flex={1}>
                <FormLabel>Last Name</FormLabel>

                <CInput
                  required
                  className={"rounded-md"}
                  placeholder={"Enter name"}
                  value={state?.last_name}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      last_name: e.target.value,
                    }));
                  }}
                />
              </Box>
            </HStack>

            <Box w={"full"}>
              <FormLabel>Phone Number</FormLabel>

              <CInput
                required
                className={"rounded-md"}
                placeholder={"+254 712345678"}
                value={state?.phone_number}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    phone_number: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box w={"full"}>
              <FormLabel>Email</FormLabel>

              <CInput
                required
                className={"rounded-md"}
                placeholder={"you@mail.com"}
                type={"email"}
                value={state?.email}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </Box>
          </VStack>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />
          {updateMutation?.isLoading ? (
            <div>
              <CLoadingBtn
                className={"w-44"}
                text={
                  updateMutation?.isLoading
                    ? "Updating customer"
                    : "Adding customer"
                }
              />
            </div>
          ) : (
            <CButton
              className={
                "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
              }
              text={mode === "update" ? "Update" : "Complete"}
              type={"submit"}
            />
          )}
        </HStack>
      </form>
    </Modal>
  );
};

export default UpdateCustomer;
