import React, { useEffect, useState } from "react";
import { ConfigProvider, Popconfirm, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import { useCustomToast } from "../../../hooks/useToast";
import { ENDPOINT } from "../../../services/AxiosServices";
import axios from "axios";
import { Box } from "@chakra-ui/react";
import OrderNotApproved from "../../../assets/icons/OrderNotApproved";
import OrderApproved from "../../../assets/icons/OrderApproved";
import { Link } from "react-router-dom";
import sort_png from "../../../assets/icons/bx_sort.svg";
import arrow_down from "../../../assets/icons/fe_arrow-down.svg";
import heroicons_adjustments from "../../../assets/icons/heroicons_adjustments-vertical.svg";
import ReactSelect from "react-select";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import CsvDownload from "react-csv-downloader";

const OwnCollection = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date_from, setDate_from] = useState("");
  const [date_to, setDate_to] = useState("");
  const [query, setQuery] = useState("");
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [sortingOption, setSortingOption] = useState(null);

  const showToast = useCustomToast();

  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = "en-US";

    return new Date(serverDate).toLocaleDateString(locale, options);
  }
  const fetchOrders = async () => {
    setLoading(true);
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken = state["access-token"];
    const sortingParam =
      sortingOption?.value === "most-recent" ? "desc" : "asc";
    const url = `${ENDPOINT}/api/v1/auth/users/orders?order_status=&order_type=OWN_COLLECTION&date_from=${date_from}&date_to=${date_to}&payment_plan=${
      selectedPaymentPlan?.value || ""
    }&ordering=${sortingParam}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    setData(response?.data?.results);
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [date_from, date_to, selectedPaymentPlan, sortingOption]);

  const handleUpdateAccept = async (id) => {
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url = ENDPOINT + `/api/v1/cart/order/update/${id}/`;

    await axios.post(
      url,
      { order_status: "ACCEPTED" }, // Include the order_status in the request body
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    window.location.reload();
  };
  const handleUpdateDecline = async (id) => {
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url = ENDPOINT + `/api/v1/cart/order/update/${id}/`;

    await axios.post(
      url,
      { order_status: "ADMIN_CANCELED" }, // Include the order_status in the request body
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    window.location.reload();
  };

  const filteredOrders = data.filter((order) => {
    return order.order_code.toLowerCase().includes(query.toLowerCase());
  });

  const payment_plan = [
    { value: "FULL_PAYMENT", label: "Full Payment" },
    { value: "3_MONTHS", label: "3 Months" },
    { value: "6_MONTHS", label: "6 Months" },
    { value: "9_MONTHS", label: "9 Months" },
    { value: "12_MONTHS", label: "12 Months" },
  ];

  const transformDataForCSV = (orders) => {
    return orders.map((order) => {
      return {
        "Order Code": order.order_code,
        "Customer Name": `${order.user.first_name} ${order.user.last_name}`,
        "Payment Plan": order.payment_plan,
        "Payment Status": order.order_status,
        "Pickup Center": order?.pickup_center?.name,
        "Ordered On": `"${formatDate(order.created_at)}"`,
        "Total Price": order.grand_total_price,
        "Order Status":
          order.order_status === "ACCEPTED" ? "Approved" : "Declined",
      };
    });
  };
  const csvHeaders = [
    { id: "Order Code", displayName: "Order Code" },
    { id: "Customer Name", displayName: "Customer Name" },
    { id: "Payment Plan", displayName: "Payment Plan" },
    { id: "Payment Status", displayName: "Payment Status" },
    { id: "Ordered On", displayName: "Ordered On" },
    { id: "Total Price", displayName: "Total Price" },
    { id: "Order Status", displayName: "Order Status" },
  ];

  const columns = [
    {
      title: "Order Code",
      dataIndex: "order_code",
    },
    {
      title: "Customer Name",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return (
          <Link to={`${item.id}`}>
            <span>
              {item.user.first_name} {item.user.last_name}
            </span>
          </Link>
        );
      },
    },
    {
      title: "Payment Plan",
      dataIndex: "payment_plan",
      key: "payment_plan",
    },
    {
      title: "Payment Status",
      dataIndex: "order_status",
      key: "order_status",
      render: (_, item) => {
        return (
          <span>
            <div className="flex gap-1">
              <span className="bg-[#FFC806] rounded sm p-1 pl-2 pr-2">
                Pending
              </span>
            </div>
          </span>
        );
      },
    },
    {
      title: "Pick-up Center",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <span>
            {item?.pickup_center?.name ? item?.pickup_center.name : "Null"}
          </span>
        );
      },
    },
    {
      title: "Ordered On",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return <span>{formatDate(item?.created_at)}</span>;
      },
    },
    {
      title: "Total Price",
      dataIndex: "grand_total_price",
      key: "grand_total_price",
      render: (_, item) => {
        return <span>Ksh {item.grand_total_price}</span>;
      },
    },
    {
      title: "Order status",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <span>
            {item.order_status === "ACCEPTED" ? (
              <p className="bg-primary-blue flex justify-center rounded text-white">
                Approved
              </p>
            ) : (
              <p className="bg-primary-red flex justify-center rounded text-white">
                Declined
              </p>
            )}
          </span>
        );
      },
    },
    {
      title: "Action",

      render: (_, item) => {
        return (
          <div className=" flex gap-4">
            <a onClick={() => handleUpdateAccept(item?.id)}>
              <OrderApproved />
            </a>
            <a onClick={() => handleUpdateDecline(item?.id)}>
              <OrderNotApproved />
            </a>
          </div>
        );
      },
    },
  ];
  return (
    <div className={"pt-5"}>
      <CsvDownload
        text="Export to CSV"
        datas={transformDataForCSV(filteredOrders)}
        filename={`Own-Collection${Date.now()}`}
        extension=".csv"
        className="h-12  border bg-blue-500 text-white items-center w-[300px] p-2 rounded-xl ml-8"
        columns={csvHeaders}
      />
      {/*header */}
      <div className={"flex justify-between px-8 py-3 text-center"}>
        <input
          className="h-12 border"
          placeholder="search order..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <ReactSelect
          options={[{ value: "most-recent", label: "Most Recent" }]}
          isClearable={true}
          placeholder="Sort orders.."
          className="w-48"
          onChange={setSortingOption}
        />
        <ReactSelect
          options={payment_plan}
          isClearable={true}
          placeholder="filter plan"
          className="w-48 h-12 text-black"
          onChange={setSelectedPaymentPlan}
        />
        <div className="h-10 items-center flex border ">
          <input
            type="date"
            placeholder="from date"
            className=" text-center w-32"
            value={date_from}
            onChange={(e) => setDate_from(e.target.value)}
          />
          <input
            type="date"
            placeholder="to date"
            className=" text-center w-32"
            value={date_to}
            onChange={(e) => setDate_to(e.target.value)}
          />
        </div>
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredOrders}
          rowKey={"id"}
        />
      </ConfigProvider>
    </div>
  );
};

export default OwnCollection;
