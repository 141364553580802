import React, { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  function getToken() {
    const state = JSON.parse(localStorage?.getItem("_token"));
    const token = state["access-token"];
    const user = jwtDecode(token);
    if (user) {
      setData(user);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    return navigate("/");
  };

  return (
    <div
      className={
        " sticky top-0 right-0 left-0 z-20 h-20 bg-primary-blue flex items-center justify-between pl-24 pr-10 font-Barlow "
      }
    >
      <img src={"/images/royal-mabati-logo.png"} className={"h-16"} alt="img" />

      <div className={"flex gap-x-8"}>
        {/*    search icon  */}

        <div className={" flex items-center justify-between gap-x-5"}>
          <img
            src={"https://image.pngaaa.com/117/4811117-small.png"}
            className={"h-10 w-10 border rounded-xl"}
            alt="img"
          />

          <span className={"text-base font-[400] text-white"}>
            {data.first_name} {data.last_name}
          </span>
          <a className="" onClick={handleLogout}>
            <LogoutOutlined style={{ fontSize: 20, color: "white" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
