import React from "react";

const ViewPricing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 3V9.75H4.5V4.5H9.75V3H3ZM14.25 3V4.5H19.5V9.75H21V3H14.25ZM3 14.25V21H9.75V19.5H4.5V14.25H3ZM19.5 14.25V19.5H14.25V21H21V14.25H19.5Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export default ViewPricing;
