import React, { useEffect, useState } from "react";
import TopNav from "../../products/view/TopNav";
import { Table } from "antd";
import { ENDPOINT } from "../../../services/AxiosServices";
import axios from "axios";
import { useParams } from "react-router-dom";

const PaymentBreakDown = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const fetchOrders = async () => {
    setLoading(true);
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url = ENDPOINT + `/api/v1/cart/order/${id}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const orderData = response?.data;

      // Flatten the order items and add each item separately to the data array
      const flattenedData = orderData?.order_items?.flatMap((ord) =>
        ord.items.map((k) => ({ ...k, vat: orderData.vat }))
      );

      setData((prev) => [...prev, ...flattenedData]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      title: "Product",
      dataIndex: "product.name",
      key: "product_name",
      render: (_, item) => {
        return <>{item.product.name}</>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Product Cost (Ksh)",
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return <>{item.pricing.price}</>;
      },
    },
    {
      title: "VAT (Ksh)",
      dataIndex: "vat",
      key: "vat",
      render: (_, item) => {
        return <>{item?.vat}</>;
      },
    },
    {
      title: "Delivery Cost (Ksh)",
      dataIndex: "delivery_cost.total_fuel_cost",
      key: "delivery_cost",
      render: (_, item) => {
        return (
          <>
            {item.delivery_cost?.total_fuel_cost
              ? item.delivery_cost?.total_fuel_cost
              : "Null"}
          </>
        );
      },
    },
    {
      title: "Security Fee (Ksh)",
      dataIndex: "delivery_cost.security_fee",
      key: "security_fee",
      render: (_, item) => {
        return (
          <>
            {item.delivery_cost?.security_fee
              ? item.delivery_cost?.security_fee
              : "Null"}
          </>
        );
      },
    },
    {
      title: "Total Cost (Ksh)",
      dataIndex: "total_price",
      key: "total_price",
    },
  ];

  return (
    <>
      <TopNav name={"Payment Breakdown"} />
      <Table columns={columns} dataSource={data} loading={loading} />
    </>
  );
};

export default PaymentBreakDown;
