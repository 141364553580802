import React, { useEffect, useState } from "react";
import {
  CustomerSvg,
  ProductCategoriesSvg,
  ProductInfoSvg,
  ProductsSvg,
  StaffSvg,
  UsersSvg,
} from "../assets/icons";
import { COLORS } from "../assets/Theme";
import { Link } from "react-router-dom";
import heroIcon from "../assets/icons/mingcute_web-line.svg";
import CarouselSvg from "../assets/icons/CarouselSvg";
import RoofplanSvg from "../assets/icons/RoofplanSvg";

import jwtDecode from "jwt-decode";
const SideNav = () => {
  const [current, setCurrent] = useState(0);
  const [currSub, setCurrSub] = useState(0);
  const [is_superuser, setIsSuperUser] = useState([]);

  function getToken() {
    const state = JSON.parse(localStorage?.getItem("_token"));
    const token = state["access-token"];
    const user = jwtDecode(token);
    setIsSuperUser(user.is_superuser);
  }

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div
      className={
        "fixed top-[90px] bottom-0 left-2 z-10 w-[260px] overflow-hidden bg-white border border-[#D9D9D9] rounded-md "
      }
      style={{ overflowY: "auto" }}
    >
      <NavItem
        icon={
          <UsersSvg color={current === 0 ? COLORS["primary-blue"] : "#000"} />
        }
        title={"Manage users"}
        isCurrent={current === 0}
        handleClick={() => {
          setCurrent(0);
          setCurrSub(null);
        }}
      >
        {current === 0 && (
          <div className={`flex flex-col gap-y-2 pl-12 `}>
            {is_superuser ? (
              <SubNavItem
                isCurrent={current === 0 && currSub === 0}
                icon={
                  <StaffSvg
                    color={
                      current === 0 && currSub === 0
                        ? COLORS["primary-blue"]
                        : "#000"
                    }
                  />
                }
                title={"Staff"}
                path={"/admin/staff"}
                handleClick={() => setCurrSub(0)}
              />
            ) : (
              <></>
            )}

            <SubNavItem
              isCurrent={currSub === 1}
              icon={
                <CustomerSvg
                  color={currSub === 1 ? COLORS["primary-blue"] : "#000"}
                />
              }
              title={"Customers"}
              path={"/admin/customers"}
              handleClick={() => setCurrSub(1)}
            />
          </div>
        )}
      </NavItem>

      {/*product info*/}
      <NavItem
        icon={
          <ProductInfoSvg
            color={current === 1 ? COLORS["primary-blue"] : "#000"}
          />
        }
        title={"Product Info"}
        isCurrent={current === 1}
        handleClick={() => {
          setCurrent(1);
          setCurrSub(null);
        }}
      >
        {current === 1 && (
          <div className={`flex flex-col gap-y-2 pl-12 `}>
            <SubNavItem
              isCurrent={current === 1 && currSub === 0}
              icon={
                <ProductCategoriesSvg
                  color={
                    current === 1 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Product Categories"}
              path={"/admin/product-categories"}
              handleClick={() => setCurrSub(0)}
            />
            <SubNavItem
              isCurrent={current === 1 && currSub === 1}
              icon={
                <ProductsSvg
                  color={
                    current === 1 && currSub === 1
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Products"}
              path={"/admin/products"}
              handleClick={() => setCurrSub(1)}
            />
          </div>
        )}
      </NavItem>
      {/*Roof plans info*/}
      <NavItem
        icon={<RoofplanSvg />}
        title={"Roof Plans"}
        isCurrent={current === 2}
        handleClick={() => {
          setCurrent(2);
          setCurrSub(null);
        }}
      >
        {current === 2 && (
          <div className={`flex flex-col gap-y-2 pl-12 `}>
            <SubNavItem
              isCurrent={current === 2 && currSub === 0}
              icon={
                <ProductCategoriesSvg
                  color={
                    current === 2 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Pending Quotes"}
              path={"/admin/pending-quotes"}
              handleClick={() => setCurrSub(0)}
            />
            <SubNavItem
              isCurrent={current === 2 && currSub === 0}
              icon={
                <ProductsSvg
                  color={
                    current === 2 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Completed Quotes"}
              path={"/admin/completed-quotes"}
              handleClick={() => setCurrSub(2)}
            />
          </div>
        )}
      </NavItem>

      <NavItem
        title={"Carousel Images"}
        path={"/admin/carousels"}
        icon={<CarouselSvg />}
      />
      <NavItem
        icon={<RoofplanSvg />}
        title={"Locations"}
        isCurrent={current === 3}
        handleClick={() => {
          setCurrent(3);
          setCurrSub(null);
        }}
      >
        {current === 3 && (
          <div className={`flex flex-col gap-y-2 pl-12 `}>
            <SubNavItem
              isCurrent={current === 3 && currSub === 0}
              icon={
                <ProductCategoriesSvg
                  color={
                    current === 3 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Pickup Centers"}
              path={"/admin/pickup-centers"}
              handleClick={() => setCurrSub(1)}
            />
            <SubNavItem
              isCurrent={current === 3 && currSub === 0}
              icon={
                <ProductsSvg
                  color={
                    current === 3 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Regions"}
              path={"/admin/regions"}
              handleClick={() => setCurrSub(3)}
            />
          </div>
        )}
      </NavItem>
      <NavItem
        icon={<RoofplanSvg />}
        title={"Orders"}
        isCurrent={current === 4}
        handleClick={() => {
          setCurrent(4);
          setCurrSub(null);
        }}
      >
        {current === 4 && (
          <div className={`flex flex-col gap-y-2 pl-12 `}>
            <SubNavItem
              isCurrent={current === 4 && currSub === 0}
              icon={
                <ProductCategoriesSvg
                  color={
                    current === 4 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Open Orders"}
              path={"/admin/open-orders"}
              handleClick={() => setCurrSub(1)}
            />
            <SubNavItem
              isCurrent={current === 4 && currSub === 0}
              icon={
                <ProductsSvg
                  color={
                    current === 4 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Closed Orders"}
              path={"/admin/closed-orders"}
              handleClick={() => setCurrSub(3)}
            />
            <SubNavItem
              isCurrent={current === 4 && currSub === 0}
              icon={
                <ProductsSvg
                  color={
                    current === 4 && currSub === 0
                      ? COLORS["primary-blue"]
                      : "#000"
                  }
                />
              }
              title={"Canceled Orders"}
              path={"/admin/canceled-orders"}
              handleClick={() => setCurrSub(3)}
            />
          </div>
        )}
      </NavItem>
      <NavItem
        icon={<RoofplanSvg />}
        title={"Payments"}
        isCurrent={current === 5}
        handleClick={() => {
          setCurrent(5);
          setCurrSub(null);
        }}
        path={"/admin/payments"}
      />
      <NavItem
        icon={<RoofplanSvg />}
        title={"Fundis"}
        isCurrent={current === 6}
        handleClick={() => {
          setCurrent(6);
          setCurrSub(null);
        }}
        path={"/admin/fundis"}
      />
    </div>
  );
};

export default SideNav;

const NavItem = ({
  path,
  icon,
  title,
  isCurrent,
  isCollapsible,
  children,
  handleClick,
}) => {
  return (
    <>
      <Link to={path} onClick={handleClick}>
        <button
          className={`flex px-5 py-4 gap-x-4 items-center ${
            isCurrent ? "bg-[#FBFBFF] opacity-100" : "bg-none opacity-70"
          }`}
          onClick={handleClick}
        >
          {icon}

          <span
            className={`font-Barlow text-sm font-[500]  ${
              isCurrent ? "text-primary-blue" : "text-#000"
            }`}
          >
            {title}
          </span>
        </button>
        {children}
      </Link>
    </>
  );
};

const SubNavItem = ({ icon, title, isCurrent, path, handleClick }) => (
  <Link to={path} onClick={handleClick}>
    <div
      className={`flex px-0 py-4 gap-x-4 items-center ${
        isCurrent ? "opacity-100" : "opacity-70"
      }`}
    >
      {icon}

      <span
        className={`font-Barlow text-sm font-[500] ${
          isCurrent ? "text-primary-blue" : "text-#000"
        }`}
      >
        {title}
      </span>
    </div>
  </Link>
);
