import React, { useEffect, useState } from "react";
import AdminLayout from "../../layouts/Admin";
import {
  ClosedOrderSvg,
  CustomerSvg,
  OpenOrderSvg,
  ProductsSvg,
} from "../../assets/icons/CardSvgs";
import { Outlet } from "react-router-dom";
import useCustomQuery from "../../services/query/useCustomQuery";
import Paths from "../../services/query/Paths";

const Admin = () => {
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [closedOrders, setclosedOrders] = useState([]);

  const { data: _products } = useCustomQuery(`${Paths.productSearchUrl}`);
  const { data: _customers, isLoading: isFetchingCustomers } = useCustomQuery(
    Paths.customerStatsUrl
  );
  const { data: _orders } = useCustomQuery(Paths.ordersUrl);
  const { data: _ordersclosed } = useCustomQuery(Paths.closedOrders);

  useEffect(() => {
    setProducts(_products?.data);

    setOrders(_orders?.data?.results);
    setclosedOrders(_ordersclosed?.data?.results);
  }, [_products, _customers, _orders, _ordersclosed]);

  return (
    <AdminLayout>
      <div className={"h-full w-full ml-[270px] "}>
        <div className={"flex gap-x-4 mb-4 flex-1"}>
          <TopCardItem
            number={`${products?.length ? products?.length : "Loading.."}`}
            subtitle={"Listed Products"}
            icon={<ProductsSvg />}
          />
          <TopCardItem
            number={`${
              isFetchingCustomers
                ? "Loading . . ."
                : _customers?.data?.active_customers ?? 0
            }`}
            subtitle={"Active Customers"}
            icon={<CustomerSvg />}
          />
          <TopCardItem
            number={`${orders?.length ? orders?.length : "Loading.."}`}
            subtitle={"Open Orders"}
            icon={<OpenOrderSvg />}
          />
          <TopCardItem
            number={`${closedOrders?.length}`}
            subtitle={"Closed Orders"}
            icon={<ClosedOrderSvg />}
          />
        </div>
        <div
          className={"bg-white mb-2 h-full w-full overflow-hidden rounded-md"}
        >
          <Outlet />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Admin;

const TopCardItem = ({ icon, number, subtitle }) => (
  <div
    className={
      "flex justify-between p-5 bg-white rounded-md border  border-[#D9D9D9] flex-[0.8]"
    }
  >
    <div>
      <h6 className={"text-[20px] font-[700] font-Barlow mb-1"}>{number}</h6>
      <p className={"text-sm font-[500] text-[#1E1E1E]"}>{subtitle}</p>
    </div>
    {/*    icon*/}
    <div
      className={
        "bg-[#FBFBFF] w-10 h-10 rounded-md border border-[#DBDBDB] flex items-center justify-center"
      }
    >
      {icon}
    </div>
  </div>
);
