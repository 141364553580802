import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

export const ENDPOINT = process.env.REACT_APP_BASE_URL;

export const PrivateAxiosUtility = axios.create({
  baseURL: `${ENDPOINT}`,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AxiosUtility = axios.create({
  baseURL: `${ENDPOINT}`,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json",
  },
});

PrivateAxiosUtility.interceptors.request.use(async (req) => {
  const state = await JSON.parse(localStorage?.getItem("_token"));
  let token;
  // check if access-token exists
  if (state["access-token"]) {
    token = state["access-token"];
    req.headers.Authorization = `Bearer ${token}`;
  } else {
    window.location.href = "/";
  }

  const user = jwt_decode(token);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  if (!isExpired) return req;
  // Refresh token request
  let prevTokens = state;
  const response = await axios.post(`${ENDPOINT}/api/v1/auth/token/refresh/`, {
    refresh: state.refresh_token,
  });
  prevTokens["access-token"] = response?.access;
  // update new token to local storage
  localStorage.setItem("_token", JSON.stringify(prevTokens));
  const { data } = response;
  // inject new token to local storage
  req.headers.Authorization = `Bearer ${data["access"]}`;

  return req;
});

export default AxiosUtility;
