import React, { useEffect, useRef, useState } from "react";
import { Box, Center, FormLabel, HStack, Show, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";

import { set } from "lodash";
import QuoteServices from "../../../services/QuoteServices";
import { useCustomToast } from "../../../hooks/useToast";
import Images from "../pending_quotes/images";
import QuoteImage from "./QuoteImage";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";


const EditQuote = ({
    isOpen,
    id,
    handleClose,
    filecode,
    quote_file_code,
    file_name,
    file_date, quote_date, quote_name
}) => {
    const showToast = useCustomToast()

    const [quote, setQuotes] = useState('')


    let formData = new FormData()

    for (const key of Object.keys(quote)) {
        formData.append("quote", quote[key]);
    }


    const handleUploadQuote = async () => {
        await QuoteServices.uploadQuote(formData, id)
        showToast("Quote uploaded")
        window.location.reload()
    }



    return (
        <Modal
            style={{ margin: "0 0", padding: "0 0" }}
            centered
            open={isOpen}
            width={900}
            footer={null}
            onCancel={handleClose}

        >
            <form>
                <Box
                    py={"4"}
                    px={"8"}
                    fontFamily={"Barlow"}
                    className={"border-b-2 border-gray-300 border-bottom"}
                >
                    <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
                        <span>View Quote </span>
                    </Text>
                </Box>

                <div className="bg-white py-5 px-10 flex flex-col gap-7 w-full">

                    <Box>
                        <FormLabel>Customer's Plan</FormLabel>
                        <div className="flex border h-32 w-[100%]  items-center justify-between p-3">
                            <div className="flex gap-8 h-auto">
                                <Images filecode={filecode} classname={'h-24 w-24'} />
                                <div className="flex flex-col gap-4">
                                    <span>{file_name}</span>
                                    <span className="text-[#888888]">{file_date}</span>
                                </div>
                            </div>
                            <button className="h-12 bg-[#FAFAFF] text-[Blue] w-48 rounded-sm">Download Plan <DownloadOutlined /></button>
                        </div>
                    </Box>

                    <Box mt={2} w="full">
                        <HStack justifyContent="space-between" flex={1}>
                            <FormLabel className="font-Barlow text-sm text-gray-400">
                                Uploaded Quotes
                            </FormLabel>

                            {Object.keys(quote)?.length > 0 && (
                                <FormLabel className="font-Barlow text-sm text-primary-blue">
                                    {quote?.length} Added
                                </FormLabel>
                            )}
                        </HStack>

                        <Box
                            className=" border flex-wrap border-gray-400 justify-between gap-y-3 h-auto"
                            display="flex"
                            mt="2"
                            p="3"
                            borderRadius="sm"
                        >
                            <div className="gap-4 flex">
                                <QuoteImage filecode={quote_file_code} classname={'h-20'} />
                                <div className="flex flex-col gap-4 mt-2">
                                    <span>{quote_name}</span>
                                    <span className="text-[#888888]">{quote_date}</span>
                                </div>
                            </div>
                            {Object?.keys(quote)?.map((i) => {
                                if (!quote[i]) return <></>;
                                return (
                                    <div className="w-[30%] relative h-48" key={i}>
                                        <img
                                            src={URL.createObjectURL(quote[i])}
                                            className="h-full max-h-40 object-contain mb-2"
                                            alt={URL.createObjectURL(quote[i])}
                                        />
                                        <CButton
                                            type="button"
                                            className="bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                                            onClick={() => {
                                                setQuotes((prev) => ({ ...prev, [i]: null }));
                                            }}
                                        >
                                            Remove
                                        </CButton>
                                    </div>
                                );
                            })}

                            {/* Display existing images here */}

                            <Uploader handleChange={(e) => setQuotes(e.target?.files)} />
                            <button className={'h-10 border items-center mt-5 w-auto pl-1 pr-1 text-primary-blue bg-[#FAFAFF] rounded-sm'}  >Download <DownloadOutlined /></button>
                        </Box>
                    </Box>

                </div>

                {/*  footer*/}
                <HStack
                    py={"4"}
                    px={"8"}
                    fontFamily={"Barlow"}
                    className={
                        "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
                    }
                >
                    <CButton
                        className={
                            "text-primary-red border border-primary-red font-[700] text-base px-8"
                        }
                        text={"Cancel"}
                        type={"button"}
                        onClick={() => handleClose()}

                    />
                    <CButton
                        className={
                            "text-white border border-primary-red font-[700] bg-red-500 text-base px-8"
                        }
                        onClick={() => handleUploadQuote()}
                        text={"Edit Quote"}
                        type={"button"}

                    />
                </HStack>
            </form>
        </Modal >
    );
};

export default EditQuote;

const Uploader = ({ handleChange }) => {
    const uploadRef = useRef();


    const handleUpload = () => {
        if (uploadRef?.current) {
            uploadRef.current.click();
            console.log("thubnails", uploadRef.current)
        }
    };

    return (
        <Center
            className={"border border-dashed border-grey_500 h-20 w-[30%] rounded-lg"}
        >
            <CButton
                className={
                    "text-primary-blue bg-[#FAFAFF] border  font-[700] text-sm px-5"
                }
                text={"Replace Quote"}
                type={"button"}
                onClick={handleUpload}
            />

            <input
                type={"file"}
                multiple
                className={"hidden"}
                ref={uploadRef}
                onChange={handleChange}
            />
        </Center>
    );
};