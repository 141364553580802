import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  FormLabel,
  HStack,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Modal } from "antd";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";
import Images from "./images";
import { set } from "lodash";
import QuoteServices from "../../../services/QuoteServices";
import { useCustomToast } from "../../../hooks/useToast";
import { DownloadOutlined } from "@ant-design/icons";
import DownloadButton from "./Download";
import { ENDPOINT } from "../../../services/AxiosServices";

const ViewPendingQuote = ({
  isOpen,
  id,
  handleClose,
  filecode,
  file_name,
  file_date,
}) => {
  const showToast = useCustomToast();

  const [quote, setQuotes] = useState("");
  const [loading, setLoading] = useState(false);
  let formData = new FormData();

  for (const key of Object.keys(quote)) {
    formData.append("quote", quote[key]);
  }

  const handleUploadQuote = async () => {
    setLoading(true);
    await QuoteServices.uploadQuote(formData, id);
    showToast("Quote uploaded");
    setLoading(false);
  };

  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            <span>Upload Quote </span>
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex flex-col gap-7 w-full">
          <Box>
            <FormLabel>Customer's Plan</FormLabel>
            <div className="flex border h-32 w-[100%]  items-center justify-between p-3">
              <div className="flex gap-8 h-auto">
                <Images filecode={filecode} classname={"h-24 w-24"} />
                <div className="flex flex-col gap-4">
                  <span>{file_name}</span>
                  <span className="text-[#888888]">{file_date}</span>
                </div>
              </div>
            </div>
          </Box>

          <Box mt={2} w={"full"}>
            <HStack justifyContent={"space-between"} flex={1}>
              <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                Uploaded Quotes
              </FormLabel>

              {Object.keys(quote)?.length > 0 && (
                <FormLabel className={"font-Barlow text-sm text-primary-blue"}>
                  {quote?.length} Added
                </FormLabel>
              )}
            </HStack>

            <Box
              className={
                "flex-wrap border border-gray-400 justify-between gap-y-3 h-auto"
              }
              display={"flex"}
              mt={"2"}
              p={"3"}
              borderRadius={"sm"}
            >
              {Object?.keys(quote)?.map((i) => {
                if (!quote[i]) return <></>;
                return (
                  <div className={"w-[30%] relative h-48 "}>
                    <img
                      src={URL.createObjectURL(quote[i])}
                      className={"h-full max-h-40 object-contain mb-2"}
                      alt={URL.createObjectURL(quote[i])}
                    />

                    <CButton
                      type={"button"}
                      className={
                        "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                      }
                      onClick={() => {
                        setQuotes((prev) => ({ ...prev, [i]: null }));
                      }}
                    >
                      Remove
                    </CButton>
                  </div>
                );
              })}
              <Uploader handleChange={(e) => setQuotes(e.target?.files)} />
            </Box>
          </Box>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
          />
          <CButton
            className={
              "text-white border border-primary-red font-[700] bg-red-500 text-base px-8"
            }
            onClick={() => handleUploadQuote()}
            text={loading ? "Saving..." : "Send"}
            type={"button"}
          />
        </HStack>
      </form>
    </Modal>
  );
};

export default ViewPendingQuote;

const Uploader = ({ handleChange }) => {
  const uploadRef = useRef();

  const handleUpload = () => {
    if (uploadRef?.current) {
      uploadRef.current.click();
      console.log("thubnails", uploadRef.current);
    }
  };

  return (
    <Center
      className={"border border-dashed border-grey_500 h-40 w-[30%] rounded-lg"}
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload File"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={uploadRef}
        onChange={handleChange}
      />
    </Center>
  );
};
