import React, { useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import CInput from "../../../components/general/CInput";
import { ConfigProvider, Switch, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import CButton from "../../../components/general/CButton";
import { Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import { Link, useNavigate } from "react-router-dom";
import { ENDPOINT } from "../../../services/AxiosServices";
import Images from "../pending_quotes/images";
import ViewCompletedQuote from "./ViewCompletedQuote";
import ArrowDownSvg from "../../../assets/icons/ArrowDownSvg";
import Replace from "../../../assets/icons/tabler_replace.svg";
import EditQuote from "./EditQuote";
import { render } from "@testing-library/react";
import QuoteServices from "../../../services/QuoteServices";
import DownloadButton from "../pending_quotes/Download";

const CompletedQuotes = () => {
  const { isLoading: loadingSearch, data: searchData } = useCustomQuery(
    Paths.completeQuoteUrl
  );
  console.log(searchData?.data);

  const showToast = useCustomToast();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showForm2, setShowForm2] = useState(false);
  const [filename, setFileName] = useState("");
  const [filedate, setFileDate] = useState("");
  const [quoteDate, setQuoteDate] = useState("");
  const [quoteName, setQuoteName] = useState("");
  const [file_code, setFilecode] = useState("");
  const [quoteFile, setQuoteFile] = useState("");
  const [id, setID] = useState("");

  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = "en-US";

    return new Date(serverDate).toLocaleDateString(locale, options);
  }

  const columns = [
    {
      title: "Plan Review",
      dataIndex: "roof_plan_file_code",

      render: (_, item) => {
        const hasFullLink = item?.roof_plan_file_code[0]?.includes(ENDPOINT);
        return (
          <Box
            className={"bg-white relative border border-gray-200 rounded-lg"}
            h={"12"}
            w={20}
            px={"1"}
          >
            <Images
              filecode={item.roof_plan_file_code}
              classname={"h-full w-full object-contain"}
            />
          </Box>
        );
      },
    },
    {
      title: "Quote Name",
      dataIndex: "quote_file_name",
      key: "roof_plan_file_name",
      render: (_, item) => {
        return (
          <a
            className={"text-ellipsis line-clamp-1"}
            onClick={() => {
              setShowForm2(true);
              setFilecode(item.roof_plan_file_code);
              setFileDate(formatDate(item.created_at));
              setQuoteDate(formatDate(item.replied_at));
              setQuoteName(item.quote_file_name);
              setFileName(item.roof_plan_file_name);
              setID(item.id);
              setQuoteFile(item.quote_file_code);
            }}
          >
            {item.quote_file_name}
          </a>
        );
      },
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => (
        <p className={"text-ellipsis line-clamp-1"}>
          {item.user.first_name} {item.user.last_name}
        </p>
      ),
    },
    {
      title: "Response Date",
      dataIndex: "replied_at",
      key: "replied_at",
      render: (_, item) => (
        <p className={"text-ellipsis line-clamp-1"}>
          {formatDate(item?.replied_at)}
        </p>
      ),
    },
    {
      title: "Downloads",
      dataIndex: "description",
      render: (_, item) => (
        <DownloadButton
          fileUrl={`${ENDPOINT}/media/quotes/${item.quote_file_name}`}
          fileName={item.quote_file_name}
          name="Download"
        />
      ),
    },
    {
      title: "Replace Quote",
      dataIndex: "unit_name",
      render: (_, item) => (
        <p
          className={
            "text-ellipsis line-clamp-1 bg-[#FAFAFF] p-2 flex gap-4 text-[Blue] rounded"
          }
          onClick={() => {
            setShowForm(true);
            setFilecode(item.roof_plan_file_code);
            setFileDate(formatDate(item.created_at));
            setFileName(item.roof_plan_file_name);
            setID(item.id);
            setQuoteFile(item.quote_file_code);
          }}
        >
          <div>Upload New</div>
          <img src={Replace} />
        </p>
      ),
    },
  ];

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav />

      <div className={"pt-5"}>
        {/*header */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS["primary-blue"],
            },
          }}
        >
          <Table
            loading={loadingSearch}
            dataSource={
              searchQuery?.trim() !== ""
                ? searchData?.data
                : searchData?.data?.results
            }
            columns={columns}
            rowKey={"id"}
            rowClassName={"cursor-pointer"}
            onRow={(record) => {
              return {
                // click row
              };
            }}
          />
        </ConfigProvider>
        <ViewCompletedQuote
          isOpen={showForm}
          handleClose={() => setShowForm(false)}
          filecode={file_code}
          file_date={filedate}
          file_name={filename}
          id={id}
          quote_file_code={quoteFile}
        />
        <EditQuote
          isOpen={showForm2}
          handleClose={() => setShowForm2(false)}
          filecode={file_code}
          file_date={filedate}
          file_name={filename}
          id={id}
          quote_file_code={quoteFile}
          quote_date={quoteDate}
          quote_name={quoteName}
        />
      </div>
    </div>
  );
};

export default CompletedQuotes;

const TopNav = ({ current = 0 }) => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    <h6 className={"text-xl font-Barlow font-[700] py-4"}>Completed Quotes</h6>
  </div>
);
