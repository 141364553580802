import React, { useState } from "react";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";
import { ConfigProvider, Popconfirm, Table } from "antd";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import { COLORS } from "../../../assets/Theme";
import { AddStaff } from "../../../components/Users";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import staffServices from "../../../services/StaffServices";
import { useCustomToast } from "../../../hooks/useToast";
import { EditSvg } from "../../../assets/icons";

const All = () => {
  const { isLoading, data, refetch } = useCustomQuery(
    Paths.staffUrl + "?page_size=500"
  );

  const [mode, setMode] = useState("create");
  const [showForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState({});
  const [query, setQuery] = useState("");
  const showToast = useCustomToast();

  // activate/deactivate staff mutation
  const mutation = useMutation(
    (data) =>
      staffServices?.activateDeactivateStaff(data?.id, {
        status: data?.status,
      }),
    {
      onSuccess: async () => {
        // handleClose();
        await refetch();
        showToast("Staff updated.");
        queryClient.invalidateQueries(Paths.staffUrl);
      },
    }
  );

  const filteredStaff = data?.data?.results.filter((name) => {
    const firstNameMatch = name.first_name
      .toLowerCase()
      .includes(query.toLowerCase());
    const lastNameMatch = name.last_name
      .toLowerCase()
      .includes(query.toLowerCase());

    // Use the logical OR operator to combine the conditions
    return firstNameMatch || lastNameMatch;
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => {
        return (
          <span>
            {item?.first_name} {item?.last_name}
          </span>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return <span>{item?.is_terminated ? "Verified" : "Not Verified"}</span>;
      },
    },
    {
      title: "Actions",
      width: 250,
      render: (_, item) => (
        <div className={"flex gap-x-5"}>
          <Popconfirm
            title={item?.is_terminated ? "Activate User?" : "Deactivate user?"}
            description={
              item?.is_terminated
                ? `Are you sure you want to Activate ${item?.first_name}`
                : `Are you sure to Deactivate ${item?.first_name}?`
            }
            onConfirm={() =>
              mutation.mutate({
                status: item?.is_terminated ? "activate" : "deactivate",
                id: item?.id,
              })
            }
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <CButton
              className={`h-12 text-sm text-white font-[500] ${
                item?.is_terminated ? "bg-[#15CF74]" : "bg-primary-red"
              }`}
              text={item?.is_terminated ? "Activate" : "Deactivate"}
            />
          </Popconfirm>
          <CButton
            className={"px-4"}
            onClick={() => {
              setMode("update");
              setUserData(item);
              setShowForm(true);
            }}
          >
            <EditSvg />
          </CButton>
        </div>
      ),
    },
  ];
  return (
    <div className={"pt-5"}>
      {/*header */}
      <div className={"flex justify-between px-8 py-3"}>
        <CInput
          placeholder={"Search"}
          className={"rounded-xl min-w-[340px]"}
          onChange={(e) => setQuery(e.target.value)}
        />

        <CButton
          onClick={() => setShowForm(true)}
          className={
            "bg-primary-blue py-0 px-4 h-10 text-white font-inter flex items-center justify-center gap-x-3"
          }
          text={"Add Staff"}
          icon={<span className={"text-xl leading-3 mb-1"}>+</span>}
        />
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={isLoading || mutation?.isLoading}
          dataSource={filteredStaff}
          columns={columns}
          rowKey={"id"}
        />
      </ConfigProvider>

      <AddStaff
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        user={userData}
        reset={() => {
          setMode("create");
          setUserData({});
        }}
        refetch={refetch}
        mode={mode}
      />
    </div>
  );
};

export default All;
