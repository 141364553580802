import React, { useEffect, useState } from "react";
import TopNav from "../../../components/products/view/TopNav";
import { Box } from "@chakra-ui/react";
import { Table } from "antd";
import { ENDPOINT } from "../../../services/AxiosServices";
import axios from "axios";
import ReactSelect from "react-select";
import CsvDownload from "react-csv-downloader";

const Installments = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date_from, setDate_from] = useState("");
  const [date_to, setDate_to] = useState("");
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const fetchRecords = async () => {
    setLoading(true);
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    let url =
      ENDPOINT +
      `/api/v1/cart/payment-records/?order_payment_plan=3_MONTHS,6_MONTHS,9_MONTHS,12_MONTHS&page_size=1000&date_from=${date_from}&date_to=${date_to}&payment_status=${
        selectedPaymentPlan?.value || ""
      }`;
    if (selectedOption?.value === "most-recent") {
      url += "&ordering=desc";
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const recordsWithOrderStatus = await Promise.all(
      response?.data?.results.map(async (record) => {
        // Fetch the order details for each record and include the order status
        const orderResponse = await axios.get(
          ENDPOINT + `/api/v1/cart/order/${record.order}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const orderData = orderResponse?.data;
        return {
          ...record,
          payment_plan: orderData.payment_plan,
          order_status: orderData.order_status,
        };
      })
    );
    // if (selectedOption?.value === "most-recent") {
    //   recordsWithOrderStatus.sort((a, b) =>
    //     a.created_at < b.created_at ? 1 : -1
    //   );
    setData(recordsWithOrderStatus);
    setLoading(false);
    // }
  };

  useEffect(() => {
    fetchRecords();
  }, [date_from, date_to, selectedPaymentPlan, selectedOption]);

  console.log(data);

  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = "en-US";

    return new Date(serverDate).toLocaleDateString(locale, options);
  }
  const payment_plan = [
    { value: "Future", label: "Future" },
    { value: "Success", label: "Success" },
    { value: "Failed", label: "Failed" },
    { value: "Pending", label: "Pending" },
  ];

  const options = [{ value: "most-recent", label: "Most Recent" }];

  const transformDataForCSV = (records) => {
    return records.map((record) => {
      return {
        "Phone Number": record.phone_number ? record.phone_number : "---",
        "Instalment Plan": record.payment_plan,
        "Payment Status": record.payment_status,
        "Paid Amount": record.amount,
        "Payment Mode": record.payment_mode,
        "Closed Date": `"${
          record.closed_date ? formatDate(record.closed_date) : "---"
        }"`,
        "Order Status": record.order_status,
      };
    });
  };
  const csvHeaders = [
    { id: "Phone Number", displayName: "Phone Number" },
    { id: "Instalment Plan", displayName: "Instalment Plan" },
    { id: "Payment Status", displayName: "Payment Status" },
    { id: "Paid Amount", displayName: "Paid Amount" },
    { id: "Payment Mode", displayName: "Payment Mode" },
    { id: "Closed Date", displayName: "Closed Date" },
    { id: "Order Status", displayName: "Order Status" },
  ];

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return <p>{item.phone_number ? item.phone_number : "---"}</p>;
      },
    },
    {
      title: "Instalment Plan",
      dataIndex: "payment_plan",
      key: "payment_plan",
    },
    {
      title: " Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "Amount (Ksh)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
    },
    {
      title: "Expected Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, item) => {
        return (
          <>{item.expected_date ? formatDate(item.expected_date) : "---"}</>
        );
      },
    },
    {
      title: "Closed Date",
      dataIndex: "closed_date",
      key: "closed_date",
      render: (_, item) => {
        return <>{item.closed_date ? formatDate(item.closed_date) : "---"}</>;
      },
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "order_status",
    },
  ];
  return (
    <>
      <TopNav name="Installment Payment Records" />
      <CsvDownload
        text="Export to CSV"
        datas={transformDataForCSV(data)}
        filename={`Instalment-records${Date.now()}`}
        extension=".csv"
        className="h-12 mt-2 border bg-blue-500 text-white items-center w-[300px] p-2 rounded-xl ml-8"
        columns={csvHeaders}
      />
      <div className={"flex justify-between px-8 py-3 text-center"}>
        {/* <ReactSelect
          options={options}
          isClearable={true}
          className="w-48"
          onChange={setSelectedOption}
        /> */}

        <ReactSelect
          options={payment_plan}
          isClearable={true}
          className="w-48 h-12 text-black"
          onChange={setSelectedPaymentPlan}
        />

        <div className="h-10 items-center flex border ">
          <input
            type="date"
            placeholder="from date"
            className=" text-center w-32"
            value={date_from}
            onChange={(e) => setDate_from(e.target.value)}
          />
          <input
            type="date"
            placeholder="to date"
            className=" text-center w-32"
            value={date_to}
            onChange={(e) => setDate_to(e.target.value)}
          />
        </div>
      </div>
      <Box>
        <Table columns={columns} dataSource={data} loading={loading} />
      </Box>
    </>
  );
};

export default Installments;
