import React from "react";

const CInput = ({ icon, placeholder, className, ...rest }) => {
  return (
    <div
      className={`${className}  px-5 h-14 border border-gray-300 rounded-sm flex gap-4 items-center `}
    >
      {/*    icon*/}
      {icon}

      <input
        className={
          "focus:outline-none border-none text-primary-blue flex-1 font-Barlow"
        }
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default CInput;
