import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopNav from "../../../components/products/view/TopNav";
import OrderApproved from "../../../assets/icons/OrderApproved";
import OrderNotApproved from "../../../assets/icons/OrderNotApproved";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ENDPOINT } from "../../../services/AxiosServices";
import OrderedProducts from "../../../components/orders/views/ordered_products";
import PaymentBreakDown from "../../../components/orders/views/payment_breakdown";
import PaymentTransactions from "../../../components/orders/views/payment_transactions";

const ViewOrder = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchOrders = async () => {
    setLoading(true);
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url = ENDPOINT + `/api/v1/cart/order/${id}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const orderData = response?.data;
    setData((prev) => [...prev, orderData]);
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);
  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = "en-US";

    return new Date(serverDate).toLocaleDateString(locale, options);
  }

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return (
          <span>
            {item.user.first_name}

            {item.user.last_name}
          </span>
        );
      },
    },
    {
      title: "Ordered Items",
      dataIndex: "payment_plan",
      key: "payment_plan",
      render: (_, item) => {
        return <>{item.order_items.map((items, key) => items.items.length)}</>;
      },
    },
    {
      title: "Payment Plan",
      dataIndex: "payment_plan",
      key: "payment_plan",
    },
    {
      title: "Order Type",
      dataIndex: "order_type",
      key: "order_type",
    },
    {
      title: "Total Cost (Ksh)",
      dataIndex: "grand_total_price",
      key: "grand_total_price",
      render: (_, item) => {
        return <span>Ksh {item.grand_total_price}</span>;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "order_status",
      key: "order_status",
      render: (_, item) => {
        return (
          <span>
            <div className="flex gap-1">
              <span className="bg-[#FFC806] rounded sm p-1 pl-2 pr-2">
                Pending
              </span>
            </div>
          </span>
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <span>
            {item?.order_status === "ACCEPTED" ? (
              <span>Approved</span>
            ) : (
              <div className="flex gap-10">
                <OrderNotApproved />
                <OrderApproved />
              </div>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <>
      {data.map((item) => (
        <TopNav name={item.order_code} />
      ))}
      <Box flex={1} height={"auto"} border={"1px solid grey "}>
        <div className="flex flex-col items-center justify-center p-20">
          <span>40%</span>
          <span>ORDER PROGRESS</span>
          <span>
            Order status:{" "}
            {data.map((item) => (
              <>
                {item.order_status !== "ACCEPTED"
                  ? "Pending Approval"
                  : "Approved"}
              </>
            ))}
          </span>
        </div>
        <div className="flex justify-between pl-4 pr-4">
          <div className="flex flex-col">
            <span>Order Placed</span>
            {data.map((item) => (
              <span>{formatDate(item.created_at)}</span>
            ))}
          </div>
          <div className="flex flex-col">
            <span>Fully Paid</span>
            {data.map((item) => (
              <span>{formatDate(item.created_at)}</span>
            ))}
          </div>
          <div className="flex flex-col">
            <span>Order Approved</span>
            {data.map((item) => (
              <span>{formatDate(item.created_at)}</span>
            ))}
          </div>
          <div className="flex flex-col">
            <span>Order Delivered</span>
            {data.map((item) => (
              <span>{formatDate(item.created_at)}</span>
            ))}
          </div>
          <div className="flex flex-col">
            <span>Order Closed</span>
            {data.map((item) => (
              <span>{formatDate(item.created_at)}</span>
            ))}
          </div>
        </div>
        <div className="flex justify-center gap-4 m-10">
          <div className="flex">
            <OrderApproved />
            <span className="bg-[#FAFAFF] text-[#15CF74] h-auto w-24 flex justify-center text-xl">
              Approved
            </span>
          </div>
          <div className="flex">
            <OrderNotApproved />
            <span className="bg-[#FAFAFF] text-[#DC2A25] h-auto w-24 flex justify-center text-xl">
              {" "}
              Cancel
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data}
          rowKey={"id"}
        />
      </Box>
      <OrderedProducts />
      <PaymentBreakDown />
      <PaymentTransactions />
    </>
  );
};

export default ViewOrder;
