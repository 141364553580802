import React, { useContext, useState } from "react";
import CInput from "../../../components/general/CInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomToast } from "../../../hooks/useToast";
import CLoadingBtn from "../../../components/general/CLoadingBtn";
import CButton from "../../../components/general/CButton";
import AuthServices from "../../../services/AuthServices";
import { Context } from "../../../store/MainContext";

const Password = () => {
  const params = useLocation();
  const showToast = useCustomToast();
  const navigate = useNavigate();
  const { setToken } = useContext(Context);

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!params?.state?.email) {
      showToast("Email required", "error");
      return navigate("/");
    } else if (!password) {
      return showToast("Password required!", "error");
    }

    setLoading(true);
    try {
      const res = await AuthServices.Login({
        email: params?.state?.email,
        password,
      });

      const { message, ...rest } = res;
      // save tokens to local storage & context
      setToken(rest);
      await localStorage.setItem("_token", JSON.stringify(rest));

      // toast success
      showToast("Signin successful");
      setLoading(false);
      navigate("/admin/staff");
    } catch (error) {
      showToast(
        "Signin error. " + error?.response?.data?.message || error?.message,
        "error"
      );
      setLoading(false);
    }
  };

  return (
    <div
      className={
        "flex justify-center items-center bg-white min-h-screen font-Barlow"
      }
    >
      <div className={"w-3/4 bg-white shadow-lg flex h-[60vh]"}>
        {/*    image*/}
        <div className="flex-1 flex-shrink-0 relative">
          <img
            className="h-full w-full"
            src={"/images/login-bg.jpg"}
            alt={"signin bg"}
          />
          <div
            className={
              "bg-gradient-to-b  from-[#2C3699B0] to-[#172398DF] absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center "
            }
          >
            <img
              className="object-cover"
              src="/images/royal-mabati-logo.png"
              alt="logo"
            />
          </div>
        </div>

        <form className=" w-[50%] py-10 px-12 relative" onSubmit={handleSubmit}>
          <h5 className={"text-3xl font-semibold text-primary-blue"}>
            Sign in to your account
          </h5>

          <div className={"mt-10"}>
            <h5 className={"text-xl font-semibold text-primary-blue mb-3"}>
              Enter your Password
            </h5>

            <CInput
              icon={
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.30729 7.125H5.69999C4.81324 7.125 4.36954 7.125 4.03084 7.29757C3.73292 7.44937 3.49088 7.69142 3.33908 7.98934C3.1665 8.32803 3.1665 8.77174 3.1665 9.65849V14.0918C3.1665 14.9786 3.1665 15.4217 3.33908 15.7604C3.49088 16.0583 3.73292 16.3008 4.03084 16.4526C4.36921 16.625 4.81239 16.625 5.69741 16.625H13.3023C14.1873 16.625 14.6298 16.625 14.9682 16.4526C15.2661 16.3008 15.509 16.0583 15.6608 15.7604C15.8332 15.422 15.8332 14.9795 15.8332 14.0945V9.65588C15.8332 8.77087 15.8332 8.3277 15.6608 7.98934C15.509 7.69142 15.2661 7.44937 14.9682 7.29757C14.6295 7.125 14.1867 7.125 13.3 7.125H11.6919M7.30729 7.125H11.6919M7.30729 7.125C7.20639 7.125 7.12484 7.04321 7.12484 6.94231V4.75C7.12484 3.43832 8.18816 2.375 9.49984 2.375C10.8115 2.375 11.8748 3.43832 11.8748 4.75V6.94231C11.8748 7.04321 11.7928 7.125 11.6919 7.125"
                    stroke="#2C3699"
                    strokeWidth="1.67647"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              type={"password"}
              required
              placeholder={"Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className={"absolute bottom-10 px-16 right-0 left-0 flex"}>
              {loading ? (
                <CLoadingBtn text={"signing in"} />
              ) : (
                <CButton
                  className={
                    "bg-primary-red text-white font-medium p-3 text-lg w-10/12 mx-auto"
                  }
                  text={"Sign in"}
                  type={"submit"}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Password;
