import React, { useEffect, useRef, useState } from "react";
import { Box, Center, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";
import CInput from "../general/CInput";
import CButton from "../general/CButton";
import { useMutation } from "react-query";
import { useCustomToast } from "../../hooks/useToast";
import CLoadingBtn from "../general/CLoadingBtn";
import useError from "../../hooks/useError";
import ProductServices from "../../services/ProductServices";

const AddCategory = ({
  handleClose: cHandleClose,
  isOpen,
  mode,
  editData,
  refetch,
  reset,
}) => {
  const showToast = useCustomToast();
  const handleError = useError();

  const [name, setName] = useState("");
  const [priority, setPriority] = useState("");
  const [id, setId] = useState("");

  const handleClose = () => {
    setName("");
    setPriority("");
    setId("");
    cHandleClose();
  };

  useEffect(() => {
    if (mode === "update") {
      setName(editData?.name);
      setPriority(editData?.priority);
      setId(editData?.id);
    }
  }, [mode, editData]);

  const handleSaveCategory = async () => {
    let formData = new FormData();

    formData.append("name", name);
    formData.append("priority", priority);

    await ProductServices.createCategory(formData);
  };
  const handleUpdateCategory = async () => {
    let formData = new FormData();

    formData.append("name", name);
    formData.append("priority", priority);

    await ProductServices.updateCategory(id, formData);
  };

  const mutation = useMutation(() => handleSaveCategory(), {
    onSuccess: async () => {
      handleClose();
      reset();
      await refetch();
      showToast("Category saved.");
    },
  });

  const updateMutation = useMutation(() => handleUpdateCategory(), {
    onSuccess: async () => {
      reset();
      handleClose();
      await refetch();
      showToast("Category updated.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mode === "update") {
      updateMutation.mutate();
    } else {
      mutation?.mutate();
    }
  };

  useEffect(() => {
    if (mutation?.isError) {
      handleError(mutation?.error);
    } else if (updateMutation?.isError) {
      handleError(updateMutation?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation]);

  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            Create Product Category
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex w-full">
          <VStack gap={5} w={"full"}>
            <HStack gap={5} w={"full"}>
              <Box flex={1}>
                <FormLabel className={" font-Barlow text-sm text-gray-400"}>
                  Category Name
                </FormLabel>

                <CInput
                  required
                  className={"rounded-md"}
                  placeholder={"Enter Name"}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Box>

              <Box flex={1}>
                <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                  Priority
                </FormLabel>

                <CInput
                  required
                  className={"rounded-md"}
                  placeholder={"Enter Priority"}
                  value={priority}
                  onChange={(e) => {
                    setPriority(e.target.value);
                  }}
                />
              </Box>
            </HStack>
          </VStack>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />

          {mutation.isLoading || updateMutation.isLoading ? (
            <div>
              <CLoadingBtn
                className={"w-44"}
                text={
                  updateMutation.isLoading
                    ? "Updating category"
                    : "Creating category"
                }
              />
            </div>
          ) : (
            <CButton
              className={
                "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
              }
              text={mode === "update" ? "Update" : "Complete"}
              type={"submit"}
            />
          )}
          {/*)}*/}
        </HStack>
      </form>
    </Modal>
  );
};

export default AddCategory;

const Uploader = ({ handleChange }) => {
  const uploadRef = useRef();

  const handleUpload = () => {
    if (uploadRef?.current) {
      uploadRef.current.click();
    }
  };

  return (
    <Center
      className={"border border-dashed border-grey_500 h-40 w-[30%] rounded-lg"}
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload Image"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={uploadRef}
        onChange={handleChange}
      />
    </Center>
  );
};
