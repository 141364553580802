import {PrivateAxiosUtility} from "./AxiosServices";

/**
 *
 * @param {Number} page
 * @param {Number} size
 * @returns {Promise<Object>}
 */
const fetchCustomers = async (page = 1, size = 10) => {
  const res = await PrivateAxiosUtility.get(
    `/dashboard/customers/?page=${page}&page_size=${size}`,
  );

  return res?.data;
};

/**
 * @param {String} user_id
 * @returns {Promise<Object>}
 */
const fetchSingleCustomer = async (user_id) => {
  const res = await PrivateAxiosUtility.get(`/dashboard/customer/${user_id}/`);

  return res?.data;
};

/**
 * @param {String} user_id
 * @returns {Promise<Object>}
 * @desc updates customer details
 */
const updateCustomer = async (user_id, data) => {
  const res = await PrivateAxiosUtility.put(
    `/dashboard/customer/update/${user_id}/`,
    data,
  );

  return res.data;
};

/**
 * @param {Number} user_id
 * @param {{status:String}} aData
 * @returns {Promise<void>}
 */
const activateDeactivateCustomer = async (user_id, aData) => {
  const res = await PrivateAxiosUtility.put(
    `/api/v1/auth/user/${user_id}/activate-or-deactivate/`,
    aData,
  );

  return res.data;
};
const CustomerServices = {
  fetchCustomers,
  fetchSingleCustomer,
  updateCustomer,
  activateDeactivateCustomer,
};

export default CustomerServices;
