import React from "react";
import ArrowDownSvg from "../../../assets/icons/ArrowDownSvg";
import { DownloadOutlined } from "@ant-design/icons";

const DownloadButton = ({ fileUrl, fileName, name }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <button
      onClick={handleDownload}
      className="bg-[#FAFAFF] text-primary-blue flex items-center gap-2"
    >
      {name}
      <DownloadOutlined />
    </button>
  );
};

export default DownloadButton;
