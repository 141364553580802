import React from "react";
import TopNav from "../../products/view/TopNav";
import { ConfigProvider, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import { useParams } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";

const Favorites = () => {
  const { id: user_id } = useParams();
  const { isLoading, data } = useCustomQuery(
    Paths.singleUserFavoritesPreUrl + user_id + "?page_size=500",
  );

  const columns = [
    {
      title: "Thumbnails",
      dataIndex: "thumbnails",
      render: (_, item) => {
        return (
          <Box
            className={"bg-white relative border border-gray-200 rounded-lg"}
            h={"12"}
            w={20}
            px={"1"}
          >
            <img
              className={"h-full w-full object-contain"}
              src={item?.product?.thumbnails[0]?.image}
              alt={"img"}
            />
          </Box>
        );
      },
    },
    {
      title: "Product Name",
      dataIndex: "name",
      render: (_, item) => (
        <Text className={"font-Barlow "}>{item?.product?.name}</Text>
      ),
    },
    {
      title: "Product Category",
      dataIndex: "category",
      render: (_, item) => (
        <Text className={"font-Barlow "}>{item?.product?.category?.name}</Text>
      ),
    },
  ];

  return (
    <div
      className={
        "bg-white rounded-md overflow-hidden border border-[#D9D9D9] w-full"
      }
    >
      <TopNav name={"Favorites"} />

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={isLoading}
          dataSource={data?.data?.results}
          columns={columns}
          rowKey={"id"}
        />
      </ConfigProvider>
    </div>
  );
};

export default Favorites;
