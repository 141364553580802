import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopNav from "../../../components/products/view/TopNav";
import axios from "axios";
import { ENDPOINT } from "../../../services/AxiosServices";
import FullPayments from "./FullPayments";
import Installments from "./Installments";

const Payments = () => {
  const [date_from, setDate_from] = useState("");
  const [date_to, setDate_to] = useState("");
  const [data, setData] = useState([]);
  const fetchTotal = async () => {
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url =
      ENDPOINT +
      `/api/v1/cart/total-earned/?start_date=${date_from}&end_date=${date_to}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    setData(response?.data);
  };
  useEffect(() => {
    fetchTotal();
  }, [date_from, date_to]);

  let total_full = data.payment_plan_stats?.FULL_PAYMENT?.total_amount;
  let percentage_full = (total_full / data.total_earned) * 100;
  let percentage_inst = 100 - percentage_full;

  return (
    <Box>
      <TopNav name={"Cumulative Earnings"} />
      <div>
        <div className="flex flex-col justify-center items-center mt-16 gap-2">
          <span className="text-[30px] font-bold">Ksh {data.total_earned}</span>
          <span className="text-[#888888]">TOTAL EARNINGS</span>
          <span>Based on {data.total_transactions} Transactions</span>
        </div>
        <div className="mt-20 flex flex-col">
          <div className="flex pl-28 pr-28">
            <div
              className={`h-4 bg-primary-red`}
              style={{ width: `${percentage_full.toFixed()}%` }}
            ></div>
            <div
              className={`bg-primary-blue h-4`}
              style={{ width: `${percentage_inst.toFixed()}%` }}
            ></div>
          </div>
          <div className="flex pr-[20%] pl-[20%] justify-between">
            <span>
              {percentage_full ? (
                <>Full Payment {percentage_full.toFixed()}%</>
              ) : (
                0
              )}
            </span>
            <span>
              {percentage_full ? (
                <>Installments {percentage_inst.toFixed()}%</>
              ) : (
                0
              )}
            </span>
          </div>
          <div className="flex justify-center gap-4">
            <input
              type="date"
              placeholder="start date"
              className="border text-center"
              value={date_from}
              onChange={(e) => setDate_from(e.target.value)}
            />
            <input
              type="date"
              placeholder="end date"
              className="border h-10 text-center"
              value={date_to}
              onChange={(e) => setDate_to(e.target.value)}
            />
          </div>
          <div className="flex gap-20 items-center justify-between mt-10 ">
            <div>
              <span className=" font-bold">Payment Options</span>
              <div className="flex flex-col">
                <span>Full Payment</span>
                <span>Instalments</span>
              </div>
            </div>
            <div>
              <span className=" font-bold">Transactions</span>
              <div className="flex flex-col">
                <span>
                  {data?.payment_plan_stats?.FULL_PAYMENT?.total_count}
                </span>
                <span>
                  {data?.total_transactions -
                    data?.payment_plan_stats?.FULL_PAYMENT?.total_count || 0}
                </span>
              </div>
            </div>
            <div>
              <span className=" font-bold">Earnings (Ksh)</span>
              <div className="flex flex-col gap-2">
                <span>
                  {data?.payment_plan_stats?.FULL_PAYMENT?.total_amount}
                </span>
                <span>
                  {" "}
                  {data.total_earned -
                    data?.payment_plan_stats?.FULL_PAYMENT?.total_amount || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FullPayments />
      <Installments />
    </Box>
  );
};

export default Payments;
