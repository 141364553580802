import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import {
  LoginEmail,
  LoginPassword,
  RequestResetPassword,
  ResetPwd,
} from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainContext from "./store/MainContext";
import { ChakraProvider } from "@chakra-ui/react";
import Admin from "./pages/admin";
import { QueryClient, QueryClientProvider } from "react-query";
import Customers from "./pages/admin/customers";
import Staff from "./pages/admin/staff";
import React, { useEffect } from "react";
import ProductCategory from "./pages/admin/product_categories";
import Products from "./pages/admin/products";
import PrivateRoute from "./utils/PrivateRoute";
import ViewProduct from "./pages/admin/products/ViewProduct";
import View from "./pages/admin/customers/View";
import PendingQuotes from "./pages/admin/pending_quotes";
import CompletedQuotes from "./pages/admin/completed_quotes";
import ViewPendingQuote from "./pages/admin/pending_quotes/ViewPendingQuote";
import Carousels from "./pages/admin/Carousels";
import PickupCenter from "./pages/admin/Pickup_centers";
import Regions from "./pages/admin/regions";
import OpenOrders from "./pages/admin/open-orders/Index";
import ClosedOrders from "./pages/admin/closed_orders/Index";
import CanceledOrders from "./pages/admin/canceled_orders/Index";
import ViewOrder from "./pages/admin/open-orders/ViewOrder";
import Payments from "./pages/admin/payments/payments";
import Fundis from "./pages/admin/fundis/Fundis";
export const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <MainContext>
            <ToastContainer theme="colored" />
            <Routes>
              {/*<Route path={"/"} element={<PrivateRoute><Users/></PrivateRoute>}/>*/}
              <Route path={"/"} element={<LoginEmail />} />
              <Route
                path={"/admin"}
                element={
                  // <PrivateRoute>
                  <Admin />
                  // </PrivateRoute>
                }
              >
                {/*<PrivateRoute>*/}
                <Route
                  path={"/admin/staff"}
                  element={
                    <PrivateRoute>
                      <Staff />
                    </PrivateRoute>
                  }
                />

                {/*  customer routes*/}
                <Route
                  path={"/admin/customers"}
                  element={
                    <PrivateRoute>
                      <Customers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"customers/:id"}
                  element={
                    <PrivateRoute>
                      <View />
                    </PrivateRoute>
                  }
                />

                {/*product category*/}
                <Route
                  path={"/admin/product-categories"}
                  element={
                    <PrivateRoute>
                      <ProductCategory />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/products"}
                  element={
                    <PrivateRoute>
                      <Products />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/products/:id"}
                  element={
                    <PrivateRoute>
                      <ViewProduct />
                    </PrivateRoute>
                  }
                />

                <Route
                  path={"/admin/pending-quotes"}
                  element={
                    <PrivateRoute>
                      <PendingQuotes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/pending-quote/:id"}
                  element={
                    <PrivateRoute>
                      <ViewPendingQuote />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/completed-quotes"}
                  element={
                    <PrivateRoute>
                      <CompletedQuotes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/carousels"}
                  element={
                    <PrivateRoute>
                      <Carousels />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/pickup-centers"}
                  element={
                    <PrivateRoute>
                      <PickupCenter />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/regions"}
                  element={
                    <PrivateRoute>
                      <Regions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/open-orders"}
                  element={
                    <PrivateRoute>
                      <OpenOrders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/closed-orders"}
                  element={
                    <PrivateRoute>
                      <ClosedOrders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/canceled-orders"}
                  element={
                    <PrivateRoute>
                      <CanceledOrders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/open-orders/:id"}
                  element={
                    <PrivateRoute>
                      <ViewOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"canceled-orders/:id"}
                  element={
                    <PrivateRoute>
                      <ViewOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/closed-orders/:id"}
                  element={
                    <PrivateRoute>
                      <ViewOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/payments"}
                  element={
                    <PrivateRoute>
                      <Payments />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/admin/fundis"}
                  element={
                    <PrivateRoute>
                      <Fundis />
                    </PrivateRoute>
                  }
                />

                {/*</PrivateRoute>*/}
              </Route>

              <Route
                path={"/login/password"}
                exact
                element={<LoginPassword />}
              />
              <Route
                path={"/reset_pwd_request"}
                element={<RequestResetPassword />}
              />
              <Route path={"/reset_pwd"} element={<ResetPwd />} />
            </Routes>
          </MainContext>
        </QueryClientProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
