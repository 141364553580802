import { PrivateAxiosUtility } from "./AxiosServices";

/**
 *
 * @param {id} number
 * @param {Number} size
 * @returns {Promise<Object>}
 */
const deleteCategory = async (id) => {
  const res = await PrivateAxiosUtility.delete(
    `/api/v1/core/category/delete/${id}`
  );

  return res?.data;
};

/**
 *
 * @param body form data
 * @returns {Promise<*>}
 */
const createCategory = async (body) => {
  const res = await PrivateAxiosUtility({
    method: "post",
    url: `/dashboard/e-store/product/category/create/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res?.data;
};
/**
 *
 * @param body updates category
 * @returns {Promise<*>}
 */
const updateCategory = async (id, body) => {
  const res = await PrivateAxiosUtility({
    method: "put",
    url: `/api/v1/core/category/edit/${id}/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res?.data;
};

/**
 *
 * @param {number} id review id
 * @returns {Promise<*>}
 * @desc hides a product review
 */
const hideReview = async (id) => {
  const res = await PrivateAxiosUtility.patch(`/api/v1/core/review/hide/${id}`);

  return res?.data;
};

/**
 *@param {body} body takes product body
 
 * @returns {Promise<*>}
 * @desc create product object
 */

const createProduct = async (body) => {
  const res = await PrivateAxiosUtility({
    method: "post",
    url: `/api/v1/core/products/create/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res?.data;
};

/**
 *
 * @param {number} id review id
 * @returns {Promise<*>}
 * @desc shows a product review
 */
const unhideReview = async (id) => {
  const res = await PrivateAxiosUtility.patch(
    `/api/v1/core/review/unhide/${id}`
  );

  return res?.data;
};

/**
 *
 * @param {number} id pricing id
 * @returns {Promise<Object>}
 */
const deletePricing = async (id) => {
  const res = await PrivateAxiosUtility.delete(
    `/api/v1/core/product/pricing/delete/${id}`
  );

  return res?.data;
};

/**
 *
 * @param {Pricing} data pricing body
 * @returns {Promise<Object>}
 */
const createProductPricing = async (data) => {
  const res = await PrivateAxiosUtility.post(
    `/api/v1/core/product/pricing/create/`,
    data
  );

  return res?.data;
};

/**
 *
 * @param {Pricing} data pricing body
 * @param {number} p_id
 * @returns {Promise<Object>}
 */
const updateProductPricing = async (data, p_id) => {
  const res = await PrivateAxiosUtility.patch(
    `api/v1/core/product/pricing/update/${p_id}`,
    data
  );

  return res?.data;
};

const toggleHide = async (p_id, data) => {
  const res = await PrivateAxiosUtility.post(
    `/api/v1/core/product/toggle-hide/${p_id}/`,
    data
  );
  return res?.data;
};
const updateProduct = async (p_id, data) => {
  const res = await PrivateAxiosUtility.put(
    `/api/v1/core/product/edit/${p_id}`,
    data
  );

  return res.data;
};
const addThumbnail = async (body, id) => {
  const res = await PrivateAxiosUtility({
    method: "post",
    url: `/api/v1/core/thumbnail/add/${id}/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

const updateRoofThumbnail = async (id, body) => {
  const res = await PrivateAxiosUtility({
    method: "put",
    url: `/api/v1/core/roofdetail/update/${id}/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};
const updateProdThumbnail = async (id, body) => {
  const res = await PrivateAxiosUtility({
    method: "put",
    url: `/api/v1/core/thumbnail/update/${id}/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};
const removeThumbnail = async (id) => {
  const res = await PrivateAxiosUtility.delete(
    `/api/v1/core/thumbnail/delete/${id}/`
  );
  return res?.data;
};
const addRoofPlan = async (body, id) => {
  const res = await PrivateAxiosUtility({
    method: "post",
    url: `/api/v1/core/roofdetail/add/${id}/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

const removeRoofPlan = async (id) => {
  const res = await PrivateAxiosUtility.delete(
    `/api/v1/core/roofdetail/delete/${id}/`
  );
  return res?.data;
};
const fetchFavourites = async () => {
  const res = await PrivateAxiosUtility.get(`/api/v1/core/favorites/highest/`);

  return res?.data;
};
const deleteProduct = async (id) => {
  const res = await PrivateAxiosUtility.delete(
    `/api/v1/core/product/delete/${id}/`
  );
  return res?.data;
};

const ProductServices = {
  deleteCategory,
  fetchFavourites,
  addRoofPlan,
  removeRoofPlan,
  updateRoofThumbnail,
  updateProdThumbnail,
  createCategory,
  createProduct,
  addThumbnail,
  removeThumbnail,
  updateProduct,
  toggleHide,
  updateCategory,
  hideReview,
  unhideReview,
  deletePricing,
  createProductPricing,
  updateProductPricing,
  deleteProduct,
};

export default ProductServices;

/**
 * @typedef {{product:number,gauge_size:number,finish:string,width:string,price:number,quantity:number}} Pricing
 */
