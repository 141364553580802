import React from "react";

const CButton = ({ className, text, icon, children, ...rest }) => {
  return (
    <button className={`rounded-md p-3 ${className} text-center`} {...rest}>
      {icon}
      {text || children}
    </button>
  );
};

export default CButton;
