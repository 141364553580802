import React, { useContext, useEffect, useState } from "react";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomToast } from "../../../hooks/useToast";
import { Context } from "../../../store/MainContext";

const Email = () => {
  const navigate = useNavigate();
  const showToast = useCustomToast();
  const { setToken } = useContext(Context);

  const [email, setEmail] = useState("");

  const location = useLocation();
  const query = location.search.substring(1, location.search.length);

  useEffect(() => {
    // const saved_token = JSON.parse(localStorage.getItem("_token"));
    if (query && query.includes("isExpired")) {
      setToken("");
    } else {
      // if (saved_token && saved_token["access-token"]) {
      //   navigate("/admin/staff");
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      return showToast("Email required!", "error");
    }
    navigate("/login/password", { state: { email }, replace: true });
  };

  return (
    <div
      className={
        "flex justify-center items-center bg-white min-h-screen font-Barlow"
      }
    >
      <div className={"w-3/4 bg-white shadow-lg flex h-[60vh]"}>
        {/*    image*/}
        <div className="flex-1 flex-shrink-0 relative">
          <img
            className="h-full w-full"
            src={"/images/login-bg.jpg"}
            alt={"signin bg"}
          />
          <div
            className={
              "bg-gradient-to-b  from-[#2C3699B0] to-[#172398DF] absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center "
            }
          >
            <img
              className="object-cover"
              src="/images/royal-mabati-logo.png"
              alt="logo"
            />
          </div>
        </div>

        <form className=" w-[50%] py-10 px-12 relative" onSubmit={handleSubmit}>
          <h5 className={"text-3xl font-semibold text-primary-blue"}>
            Sign in to your account
          </h5>

          <div className={"mt-8"}>
            <h5 className={"text-xl font-semibold text-primary-blue mb-3"}>
              Enter your email
            </h5>

            <CInput
              icon={
                <svg
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.24992C1 3.46752 1.63426 2.83325 2.41667 2.83325H16.5833C17.3657 2.83325 18 3.46752 18 4.24992V13.4583C18 14.2407 17.3657 14.8749 16.5833 14.8749H2.41667C1.63426 14.8749 1 14.2407 1 13.4583V4.24992Z"
                    stroke="#2C3699"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.00676 3.75612C1.64961 3.42872 1.88123 2.83325 2.36574 2.83325H16.6343C17.1188 2.83325 17.3504 3.42872 16.9932 3.75612L10.9359 9.30866C10.1235 10.0534 8.87652 10.0534 8.06408 9.30866L2.00676 3.75612Z"
                    stroke="#2C3699"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              type={"email"}
              required
              placeholder={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div className={"absolute bottom-10 px-16 right-0 left-0 flex"}>
              <CButton
                className={
                  "bg-primary-red text-white font-medium text-lg p-3 w-10/12 mx-auto"
                }
                text={"Continue"}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Email;
