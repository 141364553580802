import React from 'react';

const ArrowDownSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 7L12 13L18 7L20 9L12 17L4 9L6 7Z" fill="#DBDBDB"/>
        </svg>
    );
};

export default ArrowDownSvg;