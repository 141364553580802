import React from "react";
import { VStack } from "@chakra-ui/react";
import {
  CustomerDetails,
  CustomerFavorites,
} from "../../../components/Users/customers";
import { useLocation } from "react-router-dom";

const View = () => {
  const { state } = useLocation();

  return (
    <VStack gap={5} className={"bg-[#FBFBFF] h-full border-none"}>
      {/*  User details */}
      <CustomerDetails details={state} />

      {/*  favorite list */}
      <CustomerFavorites />
    </VStack>
  );
};

export default View;
