import React, {useState} from "react";

import {useLocation, useNavigate} from "react-router-dom";
import {useCustomToast} from "../hooks/useToast";
import AuthServices from "../services/AuthServices";
import {Spinner} from "../components/general";

const ResetPwd = () => {
    const navigate = useNavigate()
    const {state: {email}} = useLocation();
    const showToast = useCustomToast();

    const [state, setState] = useState({
        otp: "",
        password: "",
        confirm_password: "",
    });
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const {name, value} = e.target;

        setState((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        const {otp, password, confirm_password} = state
        if (otp?.length < 6) {
            showToast("Invalid OTP ", "error")
        } else if (password !== confirm_password) {
            showToast("Passwords do not match! ", "error")
        }

        setLoading(true)

        try {
            await AuthServices.ResetPwd({
                email,
                new_password: password,
                otp: otp
            });

            showToast("Password reset successful. ")
            setLoading(false)
            navigate("/login")
        } catch (e) {
            showToast("Reset password error. " + e?.message, "error")
            setLoading(false)

        }
    };

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div
                    className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Reset Password
                        </h1>

                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            {/* Otp sent  */}
                            <div>
                                <label
                                    htmlFor="otp"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    OTP
                                </label>
                                <input
                                    type="otp"
                                    name="otp"
                                    id="otp"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="_ _ _ _ _ _"
                                    required
                                    maxLength={6}
                                    onChange={handleChange}
                                    value={state?.otp}
                                />
                            </div>

                            {/* New Password */}
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter new password"
                                    required
                                    maxLength={6}
                                    onChange={handleChange}
                                    value={state?.password}
                                />
                            </div>

                            {/* Confirm new Password */}
                            <div>
                                <label
                                    htmlFor="confirm_password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    name="confirm_password"
                                    id="cPassword"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="confirm password"
                                    required
                                    maxLength={6}
                                    onChange={handleChange}
                                    value={state?.confirm_password}
                                />
                            </div>

                            <button
                                type="submit"
                                className={`w-full text-white bg-red-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${loading && "opacity-50 cursor-not-allowed"}`}
                                disabled={loading}
                            >
                                {loading ? (
                                    <div
                                        className={`flex justify-center gap-3`}>
                                        resetting <Spinner/>
                                    </div>
                                ) : (
                                    "Reset"
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResetPwd;
