import React from 'react'

const CarouselSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <g clip-path="url(#clip0_3179_3745)">
                <path d="M16 4.44922C16.5304 4.44922 17.0391 4.65993 17.4142 5.03501C17.7893 5.41008 18 5.91879 18 6.44922V18.4492C18 18.9797 17.7893 19.4884 17.4142 19.8634C17.0391 20.2385 16.5304 20.4492 16 20.4492H2C1.46957 20.4492 0.960859 20.2385 0.585786 19.8634C0.210714 19.4884 0 18.9797 0 18.4492V6.44922C0 5.91879 0.210714 5.41008 0.585786 5.03501C0.960859 4.65993 1.46957 4.44922 2 4.44922H16ZM16 12.4492H2V18.4492H16V12.4492ZM16 6.44922H2V10.4492H16V6.44922ZM4 7.44922C4.26522 7.44922 4.51957 7.55458 4.70711 7.74211C4.89464 7.92965 5 8.184 5 8.44922C5 8.71444 4.89464 8.96879 4.70711 9.15633C4.51957 9.34386 4.26522 9.44922 4 9.44922C3.73478 9.44922 3.48043 9.34386 3.29289 9.15633C3.10536 8.96879 3 8.71444 3 8.44922C3 8.184 3.10536 7.92965 3.29289 7.74211C3.48043 7.55458 3.73478 7.44922 4 7.44922ZM7 7.44922C7.26522 7.44922 7.51957 7.55458 7.70711 7.74211C7.89464 7.92965 8 8.184 8 8.44922C8 8.71444 7.89464 8.96879 7.70711 9.15633C7.51957 9.34386 7.26522 9.44922 7 9.44922C6.73478 9.44922 6.48043 9.34386 6.29289 9.15633C6.10536 8.96879 6 8.71444 6 8.44922C6 8.184 6.10536 7.92965 6.29289 7.74211C6.48043 7.55458 6.73478 7.44922 7 7.44922ZM10 7.44922C10.2652 7.44922 10.5196 7.55458 10.7071 7.74211C10.8946 7.92965 11 8.184 11 8.44922C11 8.71444 10.8946 8.96879 10.7071 9.15633C10.5196 9.34386 10.2652 9.44922 10 9.44922C9.73478 9.44922 9.48043 9.34386 9.29289 9.15633C9.10536 8.96879 9 8.71444 9 8.44922C9 8.184 9.10536 7.92965 9.29289 7.74211C9.48043 7.55458 9.73478 7.44922 10 7.44922Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_3179_3745">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.449219)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CarouselSvg