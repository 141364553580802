export const Paths = {
  staffUrl: "/dashboard/staff/",
  customersUrl: "/dashboard/customers/",
  customerStatsUrl: "/dashboard/customers/statistics",

  productCategoriesUrl: "/api/v1/core/categories",
  productCategorySearchUrl: "/api/v1/core/categories/search/",

  productsUrl: "/api/v1/core/products/admin/",
  productSearchUrl: "/api/v1/core/products/search/",

  singleProductPreUrl: "/api/v1/core/products/admin/",
  productReviewsUrl: "/api/v1/core/reviews/all/product",
  singleCustomerPreUrl: "",
  singleUserFavoritesPreUrl: "/api/v1/core/favorites/user/",

  roofUrl: "/api/v1/core/roof-plans/all/",
  quoteUrl: "/api/v1/core/roof-plans/all?status=pending",
  completeQuoteUrl: "/api/v1/core/roof-plans/all?status=received",

  carouselUrl: "/api/v1/core/carousels/",
  pickupUrl: "/api/v1/cart/pickupcenters/",
  regionsUrl: "/api/v1/cart/deliverycosts/",

  ordersUrl: "/api/v1/auth/users/orders",
  closedOrders: "/api/v1/auth/users/orders?order_status=SUCCESS",

  userFundisUrl: "",
  adminFundisUrl: "/api/v1/cart/order/fundis/admin/",
};

export default Paths;
