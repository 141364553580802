import { Box, Center, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";
import CLoadingBtn from "../../../components/general/CLoadingBtn";
import { useMutation } from "react-query";
import { useCustomToast } from "../../../hooks/useToast";
import useError from "../../../hooks/useError";
import ProductServices from "../../../services/ProductServices";
import Paths from "../../../services/query/Paths";
import useCustomQuery from "../../../services/query/useCustomQuery";
import { SketchPicker } from "react-color";
import { CloseOutlined } from "@ant-design/icons";

function AddProduct({
  isOpen,
  handleClose: cHandleClose,
  reset,
  user,
  mode,
  refetch,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnails, setThumbnails] = useState({});
  const [category, setCategory] = useState("");
  const [thumbnailColors, setThumbnailColors] = useState({});
  const [roof_thumbnails, setRoof_thumbnails] = useState({});
  const [roofDetailsColors, setRoofDetailsColors] = useState({});
  const [length, setLength] = useState("");
  const [unit_name, setUnit_name] = useState("");
  const [hideRoofPallete, setHideRoofPallete] = useState(false);
  const [hideProdPallete, setHideProdPallete] = useState(true);

  const handleError = useError();
  const showToast = useCustomToast();
  const handleClose = () => {
    cHandleClose();
  };
  const handleSaveProduct = async () => {
    let formData = new FormData();

    formData.append("name", name);
    formData.append("category", category);
    formData.append("description", description);
    formData.append("length", length);
    formData.append("unit_name", unit_name);

    // Add product thumbnails
    Object.keys(thumbnails).forEach((key) => {
      formData.append("thumbnails", thumbnails[key]);
    });

    // Add roof thumbnails
    Object.keys(roof_thumbnails).forEach((key) => {
      formData.append("roof_thumbnails", roof_thumbnails[key]);
    });

    // Add thumbnail colors
    formData.append(
      "thumbnail_colors",
      Object.values(thumbnailColors).join(",")
    );
    formData.append(
      "roofdetails_colors",
      Object.values(roofDetailsColors).join(",")
    );

    await ProductServices.createProduct(formData);
  };

  const handleColorChange = (type, key, color) => {
    if (type === "thumbnails") {
      setThumbnailColors((prevColors) => ({
        ...prevColors,
        [key]: color.hex,
      }));
    } else if (type === "roofthumbnails") {
      setRoofDetailsColors((prevColors) => ({
        ...prevColors,
        [key]: color.hex,
      }));
    }
  };

  const mutation = useMutation(() => handleSaveProduct(), {
    onSuccess: async () => {
      handleClose();
      showToast("Product created successfully.");
      window.location.reload();
    },
  });

  const { data: categories } = useCustomQuery(Paths.productCategoriesUrl);

  const _categories = categories?.data?.results;

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  useEffect(() => {
    if (mutation?.isError) {
      handleError(mutation?.error);
    }
  }, [mutation]);

  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            {mode === "update" ? (
              <span>Update Product</span>
            ) : (
              <span>Add Product</span>
            )}
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex w-full">
          <VStack gap={5} w={"full"}>
            <HStack gap={5} w={"full"}>
              <Box flex={1}>
                <FormLabel>Product Name</FormLabel>
                <CInput
                  className={"rounded-md"}
                  placeholder={"Enter product name"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>

              <Box flex={1}>
                <FormLabel>Category</FormLabel>
                <select
                  className="w-[100%] h-12 bg-white border "
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {_categories?.map((_category) => (
                    <option value={_category.id}>{_category.name}</option>
                  ))}
                </select>
              </Box>
            </HStack>

            <Box w={"full"}>
              <FormLabel>Description</FormLabel>
              <CInput
                className={"rounded-md"}
                placeholder={"Enter description"}
                type={"text"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>

            <Box w={"full"}>
              <FormLabel>Length</FormLabel>
              <CInput
                className={"rounded-md"}
                placeholder={"Enter comma-separated values e.g 2,2.3,2.5"}
                type={"text"}
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
            </Box>

            <Box mt={2} w={"full"}>
              <HStack justifyContent={"space-between"} flex={1}>
                <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                  Add Product Thumbnails
                </FormLabel>

                {Object.keys(thumbnails)?.length > 0 && (
                  <FormLabel
                    className={"font-Barlow text-sm text-primary-blue"}
                  >
                    {Object.keys(thumbnails).length} Added
                  </FormLabel>
                )}
              </HStack>

              <Box
                className={
                  "flex-wrap border border-gray-400 justify-between gap-y-3 h-auto"
                }
                display={"flex"}
                mt={"2"}
                p={"3"}
                borderRadius={"sm"}
              >
                {Object?.keys(thumbnails)?.map((i) => {
                  if (!thumbnails[i]) return <></>;
                  return (
                    <div className={"w-[30%] h-auto relative "} key={i}>
                      <img
                        src={URL.createObjectURL(thumbnails[i])}
                        className={"h-auto max-h-40 object-contain mb-2"}
                        alt={URL.createObjectURL(thumbnails[i])}
                      />

                      <div className="flex justify-between gap-8">
                        <CButton
                          type={"button"}
                          className={
                            "bg-none border border-primary-red  text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                          }
                          onClick={() => {
                            setThumbnails((prev) => ({ ...prev, [i]: null }));
                            setThumbnailColors((prevColors) => {
                              const { [i]: deletedColor, ...restColors } =
                                prevColors;
                              return restColors;
                            });
                          }}
                        >
                          Remove
                        </CButton>
                        <a
                          className="h-6 border-primary-red text-primary-red rounded  flex items-center"
                          onClick={() => setHideProdPallete(true)}
                        >
                          open color
                        </a>
                        <div className="w-32">
                          <input
                            placeholder="add color"
                            className={`border w-full bg-[${
                              thumbnailColors[i] || ""
                            }] `}
                            value={thumbnailColors[i] || ""}
                            onChange={(e) => {
                              handleColorChange("thumbnails", i, {
                                hex: e.target.value,
                              });
                            }}
                          />
                          {hideProdPallete && (
                            <>
                              <a
                                className="mt-10"
                                onClick={() => setHideProdPallete(false)}
                              >
                                <CloseOutlined
                                  style={{ fontSize: 20, color: "red" }}
                                />
                              </a>
                              <SketchPicker
                                color={thumbnailColors[i] || ""}
                                onChange={(color) => {
                                  handleColorChange("thumbnails", i, color);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Uploader
                  handleChange={(e) => setThumbnails(e.target?.files)}
                />
              </Box>
            </Box>

            <Box mt={2} w={"full"}>
              <HStack justifyContent={"space-between"} flex={1}>
                <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                  Add Roof Thumbnails
                </FormLabel>

                {Object.keys(roof_thumbnails)?.length > 0 && (
                  <FormLabel
                    className={"font-Barlow text-sm text-primary-blue"}
                  >
                    {Object.keys(roof_thumbnails).length} Added
                  </FormLabel>
                )}
              </HStack>

              <Box
                className={
                  "flex-wrap border border-gray-400 justify-between gap-y-3 h-auto"
                }
                display={"flex"}
                mt={"2"}
                p={"3"}
                borderRadius={"sm"}
              >
                {Object?.keys(roof_thumbnails)?.map((i) => {
                  if (!roof_thumbnails[i]) return <></>;
                  return (
                    <div className={"w-[30%] relative "} key={i}>
                      <img
                        src={URL.createObjectURL(roof_thumbnails[i])}
                        className={"h-auto max-h-40 object-contain mb-2"}
                        alt={URL.createObjectURL(roof_thumbnails[i])}
                      />
                      <div className="flex justify-between gap-8">
                        <CButton
                          type={"button"}
                          className={
                            "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                          }
                          onClick={() => {
                            setRoof_thumbnails((prev) => ({
                              ...prev,
                              [i]: null,
                            }));
                            setRoofDetailsColors((prevColors) => {
                              const { [i]: deletedColor, ...restColors } =
                                prevColors;
                              return restColors;
                            });
                          }}
                        >
                          Remove
                        </CButton>{" "}
                        <a
                          className="h-6 border-primary-red text-primary-red rounded flex items-center"
                          onClick={() => setHideRoofPallete(true)}
                        >
                          open color
                        </a>
                        <div className="w-32">
                          <input
                            placeholder="add color"
                            className={`border w-full bg-[${
                              roofDetailsColors[i] || ""
                            }] `}
                            value={roofDetailsColors[i] || ""}
                            onChange={(e) => {
                              handleColorChange("roofthumbnails", i, {
                                hex: e.target.value,
                              });
                            }}
                          />
                          {hideRoofPallete && (
                            <>
                              <a
                                className="mt-10"
                                onClick={() => setHideRoofPallete(false)}
                              >
                                <CloseOutlined
                                  style={{ fontSize: 20, color: "red" }}
                                />
                              </a>
                              <SketchPicker
                                color={roofDetailsColors[i] || ""}
                                onChange={(color) => {
                                  handleColorChange("roofthumbnails", i, color);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Uploader2
                  handleChange={(e) => setRoof_thumbnails(e.target?.files)}
                />
              </Box>
            </Box>

            <Box w={"full"}>
              <FormLabel>Unit Name</FormLabel>
              <CInput
                className={"rounded-md"}
                placeholder={"Enter unit name"}
                type={"text"}
                value={unit_name}
                onChange={(e) => setUnit_name(e.target.value)}
              />
            </Box>
          </VStack>
        </div>

        {/* footer */}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />
          {mutation.isLoading ? (
            <div>
              <CLoadingBtn
                className={"w-44"}
                text={
                  !mutation?.isLoading ? "Updating Product" : "Adding Product"
                }
              />
            </div>
          ) : (
            <CButton
              className={
                "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
              }
              text={mode === "update" ? "Update" : "complete"}
              type={"submit"}
            />
          )}
        </HStack>
      </form>
    </Modal>
  );
}

export default AddProduct;

const Uploader = ({ handleChange }) => {
  const uploadRef = useRef();

  const handleUpload = () => {
    if (uploadRef?.current) {
      uploadRef.current.click();
    }
  };

  return (
    <Center
      className={"border border-dashed border-grey_500 h-40 w-[30%] rounded-lg"}
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload Image"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={uploadRef}
        onChange={handleChange}
      />
    </Center>
  );
};

const Uploader2 = ({ handleChange }) => {
  const upload2Ref = useRef();

  const handleUpload = () => {
    if (upload2Ref?.current) {
      upload2Ref.current.click();
    }
  };

  return (
    <Center
      className={"border border-dashed border-grey_500 h-40 w-[30%] rounded-lg"}
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload Image"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={upload2Ref}
        onChange={handleChange}
      />
    </Center>
  );
};
