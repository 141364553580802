import React from "react";

const ProductCategories = ({ color = "#2A3698" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6.86219H12.3103L9.75 4.30188C9.61122 4.16198 9.44601 4.05107 9.26398 3.97559C9.08196 3.90011 8.88674 3.86156 8.68969 3.86219H3.75C3.35218 3.86219 2.97064 4.02023 2.68934 4.30153C2.40804 4.58283 2.25 4.96437 2.25 5.36219V18.9203C2.2505 19.3026 2.40257 19.669 2.67286 19.9393C2.94316 20.2096 3.30962 20.3617 3.69188 20.3622H20.3334C20.709 20.3617 21.069 20.2123 21.3346 19.9467C21.6001 19.6812 21.7495 19.3212 21.75 18.9456V8.36219C21.75 7.96437 21.592 7.58283 21.3107 7.30153C21.0294 7.02023 20.6478 6.86219 20.25 6.86219ZM3.75 5.36219H8.68969L10.1897 6.86219H3.75V5.36219ZM20.25 18.8622H3.75V8.36219H20.25V18.8622Z"
        fill={color}
      />
    </svg>
  );
};

export default ProductCategories;
