import React, { useEffect, useState } from "react";
import TopNav from "../../products/view/TopNav";
import { Table } from "antd";
import { ENDPOINT } from "../../../services/AxiosServices";
import axios from "axios";
import { useParams } from "react-router-dom";

const PaymentTransactions = () => {
  const [data, setData] = useState([]);
  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = "en-US";

    return new Date(serverDate).toLocaleDateString(locale, options);
  }

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const fetchOrders = async () => {
    setLoading(true);
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url = ENDPOINT + `/api/v1/cart/order/${id}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const orderData = response?.data;
    setData(orderData?.payment_records);
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);
  function getTimeFromServerTime(serverTime) {
    // Create a JavaScript Date object from the server time string
    const date = new Date(serverTime);

    // Extract the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${hours}:${minutes}:${seconds}`;
  }

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      render: (_, item) => {
        return <>{item.order ? item.order : "null"}</>;
      },
    },
    {
      title: "Transaction Code",
      dataIndex: "vat",
      key: "vat",
      render: (_, item) => {
        return <>{item.unique_code}</>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: "payment_mode",
      key: "payment_mode",
    },
    {
      title: "Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return <>{item.phone_number ? item.phone_number : "null"}</>;
      },
    },
    {
      title: "Amount (Ksh)",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => {
        return <>{item.amount ? item.amount : "null"}</>;
      },
    },
    {
      title: "Date",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => {
        return <>{formatDate(item.created_at)}</>;
      },
    },
    {
      title: "Time",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => {
        return <>{getTimeFromServerTime(item.created_at)}</>;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
  ];
  return (
    <>
      <TopNav name={"Payment Transactions"} />
      <Table columns={columns} dataSource={data} loading={loading} />
    </>
  );
};

export default PaymentTransactions;
