import React, { useEffect, useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import CInput from "../../../components/general/CInput";
import { AutoComplete, ConfigProvider, Select, Switch, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import CButton from "../../../components/general/CButton";
import { Box, background, useFocusEffect } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import { DeleteSvg, EditSvg } from "../../../assets/icons";
import { Link, useNavigate } from "react-router-dom";
import { ENDPOINT } from "../../../services/AxiosServices";
import AddProduct from "./AddProduct";
import axios from "axios";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import sort_png from "../../../assets/icons/bx_sort.svg";
import arrow_down from "../../../assets/icons/fe_arrow-down.svg";
import heroicons_adjustments from "../../../assets/icons/heroicons_adjustments-vertical.svg";
import ReactSelect from "react-select";
import OrderNotApproved from "../../../assets/icons/OrderNotApproved";

const Products = () => {
  const { isLoading, data } = useCustomQuery(
    Paths.productsUrl + "?page_size=500"
  );
  const { data: _categories } = useCustomQuery(Paths.productCategoriesUrl);

  const showToast = useCustomToast();
  const navigate = useNavigate();

  useEffect(() => {
    setCategories(_categories?.data?.results);
  }, [_categories?.data?.results]);

  const [searchQuery, setSearchQuery] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [favorites, selectedFavourites] = useState(null);
  const [priceRange, setPriceRange] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const { isLoading: loadingSearch, data: searchData } = useCustomQuery(
    `${Paths.productSearchUrl}?search_query=${searchQuery}&page_size=1000`,
    searchQuery?.trim() !== ""
  );

  useEffect(() => {
    setProducts(data?.data?.results);
  }, [data?.data?.results]);

  async function fetchProductsByCategory(id) {
    try {
      setLoading(true);
      const url = `${ENDPOINT}/api/v1/core/products/category/${id}`;
      const response = await axios.get(url);
      setProducts(response.data.results);
      setLoading(false);
      console.log(response.data.results);
    } catch (error) {
      showToast("Error fetching products by price:", error);
    }
  }

  useEffect(() => {
    if (selectedOption?.value) {
      fetchProductsByCategory(selectedOption?.value);
    } else {
      setProducts(data?.data?.results);
    }
  }, [selectedOption]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchProductsByPrice();
    }
  };

  async function fetchProductsByPrice() {
    try {
      setLoading(true);
      const [minPrice, maxPrice] = priceRange.split("-").map(Number);
      if (!isNaN(minPrice) && !isNaN(maxPrice)) {
        const url = `${ENDPOINT}/api/v1/core/products/filter/?min_price=${minPrice}&max_price=${maxPrice}`;
        const response = await axios.get(url);
        setProducts(response.data.results);
        setLoading(false);
      } else {
        console.log("invalid price range");
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (priceRange === "") {
      setProducts(data?.data?.results);
    }
  }, []);

  async function fetchFavourites() {
    try {
      if (favorites?.value === "favourites") {
        setLoading(true);
        const data = await productServices.fetchFavourites();
        const productsArray = data?.results.map((item) => item.product);
        setProducts(productsArray);
        setLoading(false);
      } else {
        setProducts(data?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const select_options = [
    { value: "favourites", label: "Favourites" },
    { value: "All", label: "All" },
  ];

  function sort() {
    const prods = products.reverse();
    setProducts(prods);
  }

  useEffect(() => {
    fetchFavourites();
  }, [favorites]);

  // activate/deactivate staff mutation
  const deleteMutation = useMutation(
    (data) => productServices?.deleteCategory(data?.id),
    {
      onSuccess: () => {
        // handleClose();
        showToast("customer updated.");
        queryClient.invalidateQueries(Paths.productsUrl);
      },
    }
  );
  const toggleHide = async (id, newAction) => {
    let token;
    const state = await JSON.parse(localStorage?.getItem("_token"));
    token = state["access-token"];
    try {
      await axios.post(
        `${ENDPOINT}/api/v1/core/product/toggle-hide/${id}/`,
        {
          action: newAction,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle any success or feedback messages
      window.location.reload(false);
      if (newAction === "hide") {
        showToast("Product is now hidden");
      } else {
        // Product is now unhidden
        showToast("Product is now unhidden");
      }
    } catch (error) {
      // Handle errors here
      console.error("Error toggling hide/unhide:", error);
      // You can show an error message here
    }
  };
  useEffect(() => {
    const categories_options = _categories?.data?.results.map((cat) => ({
      label: cat.name,
      value: cat.id,
    }));
    setOptions(categories_options);
  }, [_categories?.data?.results]);

  const handleDeletProduct = async (id) => {
    await productServices.deleteProduct(id);
    showToast("Product Deleted successfully");
    window.location.reload();
  };

  const handlePriceClear = () => {
    setPriceRange(" ");
    window.location.reload();
  };

  const columns = [
    {
      title: "Thumbnails",
      dataIndex: "thumbnail",

      render: (_, item) => {
        return (
          <Box
            className={"bg-white relative border border-gray-200 rounded-lg"}
            h={"12"}
            w={20}
            px={"1"}
          >
            <img
              className={"h-full w-full object-contain"}
              src={item?.thumbnails[0]?.image}
              alt={"img"}
            />
          </Box>
        );
      },
    },
    {
      title: "Roof Images",
      dataIndex: "roof-images",
      render: (_, item) => {
        return (
          <Box
            className={"bg-white relative border border-gray-200 rounded-lg"}
            h={"12"}
            w={20}
            px={"1"}
          >
            <img
              className={"h-full w-full object-contain"}
              src={
                item?.roof_details[0]?.roof_thumbnail
                  ? ENDPOINT + item?.roof_details[0]?.roof_thumbnail
                  : "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
              }
              alt={"img"}
            />
          </Box>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, item) => (
        <Link to={`/admin/products/${item?.id}`}>{text}</Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "priority",
      render: (_, item) => (
        <Link to={`/admin/products/${item?.id}`}>{item.category?.name}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, item) => (
        <Link
          to={`/admin/products/${item?.id}`}
          className="text-ellipsis line-clamp-1"
        >
          {item?.description}
        </Link>
      ),
    },
    {
      title: "Unit Name",
      dataIndex: "unit_name",
      key: "unit_name",
      render: (_, item) => (
        <Link to={`/admin/products/${item?.id}`}>{item.unit_name}</Link>
      ),
    },
    {
      title: "Hidden",
      dataIndex: "hidden",
      key: "hidden",
      render: (_, item) => (
        <p className={"text-ellipsis line-clamp-1"}>
          {item.hidden ? "Yes" : "No"}
        </p>
      ),
    },
    {
      title: "Show on Site",
      dataIndex: "show",
      key: "show",
      render: (_, item) => (
        <ConfigProvider
          theme={{
            switch: {
              background: "#f00",
              border: "#00f",
            },
          }}
        >
          <Switch
            checked={!item.hidden}
            onChange={() => {
              toggleHide(item.id, item.hidden ? "unhide" : "hide");
            }}
          />
        </ConfigProvider>
      ),
    },

    {
      title: "Actions",
      width: 100,
      render: (_, item) => (
        <div className={"flex gap-x-3 relative"}>
          <CButton
            className={
              " px-1  text-primary-red font-Barlow font-[500] gap-x-2 "
            }
            onClick={() => handleDeletProduct(item?.id)}
          >
            <DeleteSvg />
          </CButton>
        </div>
      ),
    },
  ];

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav />

      <div className={"pt-5"}>
        {/*header */}
        <div className={"flex justify-between px-8 py-3 text-center"}>
          <input
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            className="h-12 border"
            placeholder="search product..."
          />

          <ReactSelect
            options={select_options}
            isClearable={true}
            placeholder="select favourites"
            className="w-48"
            defaultValue={favorites}
            onChange={selectedFavourites}
          />

          <ReactSelect
            options={options}
            isClearable={true}
            placeholder="filter data.."
            className="w-48 h-12 text-black"
            onChange={setSelectedOption}
            defaultValue={selectedOption}
          />

          <div className="h-10  items-center flex border ">
            <input
              placeholder="100-200"
              className=" text-center w-48"
              value={priceRange}
              onChange={(e) => setPriceRange(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <div className="flex gap-2">
              <SearchOutlined
                style={{ fontSize: 20 }}
                onClick={() => fetchProductsByPrice()}
              />
              <a onClick={handlePriceClear}>
                <OrderNotApproved />
              </a>
            </div>
          </div>
          <CButton
            // onClick={() => setShowForm(true)}
            className={
              "bg-primary-blue py-0 px-4 h-10 text-white font-inter flex items-center justify-center gap-x-3"
            }
            onClick={() => setShowForm(true)}
            text={"Add Product"}
            icon={<span className={"text-xl leading-3 mb-1"}>+</span>}
          />
        </div>

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS["primary-blue"],
            },
          }}
        >
          <Table
            loading={
              loading || deleteMutation?.isLoading || loadingSearch || isLoading
            }
            dataSource={
              searchQuery?.trim() !== "" ? searchData?.data : products
            }
            columns={columns}
            rowKey={"name"}
            rowClassName={"cursor-pointer"}
            onRow={(record) => {
              return {
                // onClick: () => {
                //   navigate(`/products/${record?.id}`);
                // }, // click row
              };
            }}
          />
        </ConfigProvider>
        <AddProduct isOpen={showForm} handleClose={() => setShowForm(false)} />
      </div>
    </div>
  );
};

export default Products;

const TopNav = ({ current = 0 }) => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    <h6 className={"text-xl font-Barlow font-[700] py-4"}>Products</h6>
  </div>
);
