import UsersSvg from "./Users";
import StaffSvg from "./Staff";
import CustomerSvg from "./Customer";
import EditSvg from "./Edit";
import DeleteSvg from "./Delete";
import ProductInfoSvg from "./ProductInfo";
import ProductCategoriesSvg from "./ProductCategories";
import ProductsSvg from "./Products";
import RatingStarSvg from "./RatingStar";

export {
  UsersSvg,
  StaffSvg,
  CustomerSvg,
  EditSvg,
  DeleteSvg,
  ProductInfoSvg,
  ProductCategoriesSvg,
  ProductsSvg,
  RatingStarSvg,
};
