import React from "react";
import TopNav from "./TopNav";
import { HStack, VStack } from "@chakra-ui/react";
import CButton from "../../general/CButton";

const ProductDetails = ({
  name,
  category,
  unit,
  description,
  colors,
  lengths,
  gaugeSize,
}) => {
  return (
    <div
      className={
        "bg-white rounded-md overflow-hidden border border-[#D9D9D9] w-full"
      }
    >


      <VStack gap={3} width={"full"} className={"p-4 "}>
        <DetailsItem cKey={"Product Name"} cVal={name} />
        <DetailsItem cKey={"Category"} cVal={category} />
        <DetailsItem cKey={"Unit Name"} cVal={unit} />
        <DetailsItem cKey={"Description"} cVal={description} />
        {gaugeSize && (
          <DetailsItem
            cKey={"Colors"}
            cVal={colors}
            renderComponent={(item) => {
              return (
                <div className={`h-3 w-3 `} style={{ backgroundColor: item }} />
              );
            }}
          />
        )}
        {gaugeSize && (
          <DetailsItem
            cKey={"Length"}
            cVal={lengths}
            renderComponent={(item) => {
              return <h6>{item}M</h6>;
            }}
          />
        )}
      </VStack>
    </div>
  );
};

export default ProductDetails;

const DetailsItem = ({ cKey, cVal, renderComponent }) => {
  return (
    <HStack
      flex={1}
      py={3}
      className={"border-b border-gray-200 w-full justify-between font-Barlow"}
    >
      <div className={"font-[600] text-base text-gray-500 flex-1 "}>{cKey}</div>

      <div
        className={
          "font-[600] text-base text-black flex-1 flex justify-end gap-x-3"
        }
      >
        {Array.isArray(cVal)
          ? cVal?.map((item) => renderComponent(item))
          : cVal}
      </div>
    </HStack>
  );
};
