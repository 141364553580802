import React, { useState } from "react";
import All from "./All";
import Active from "./Active";
import Inactive from "./Inactive";

const Customers = () => {
  const [activeTab, setActiveTab] = useState(0);

  const renderTab = () => {
    switch (activeTab) {
      case 0:
        return <All />;
      case 1:
        return <Active />;
      case 2:
        return <Inactive />;
      default:
        return <All />;
    }
  };

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav current={activeTab} handleClick={(ind) => setActiveTab(ind)} />

      {renderTab()}
    </div>
  );
};

export default Customers;

const TopNav = ({ current = 0, handleClick }) => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    {navList?.map((item) => (
      <TopNavItem
        title={item?.name}
        isActive={item?.index === current}
        handleClick={() => handleClick(item?.index)}
      />
    ))}
  </div>
);

const navList = [
  {
    name: "All",
    index: 0,
  },
  {
    name: "Active",
    index: 1,
  },
  {
    name: "Inactive",
    index: 2,
  },
];

const TopNavItem = ({ isActive, title, handleClick }) => (
  <button
    onClick={handleClick}
    className={`p-3 font-Barlow text-sm ${
      isActive
        ? "border-b-[2px] border-[#0601EA] text-[#0601EA] font-[600] "
        : "text-gray-400 font-[400]"
    }`}
  >
    {title}
  </button>
);
