import React from "react";
import { Box, HStack, Text } from "@chakra-ui/react";

const BarRatingItem = ({ text, width, color, noRates }) => {
  return (
    <HStack className={" items-center  w-full"}>
      {/*  text   */}
      <Box className={"flex-[0.45] mr-2"}>
        <Text className={"text-gray-400 font-barlow"}>
          {text} ({noRates})
        </Text>
      </Box>

      {/*  bar  */}
      <Box
        className={"flex bg-slate-200 rounded-full h-[10px] flex-1 mr-3 p-0"}
      >
        <Box
          className={` rounded-full h-full`}
          style={{
            width: `${width}%`,
            backgroundColor: color,
          }}
        />
      </Box>
    </HStack>
  );
};

export default BarRatingItem;
