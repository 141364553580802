import React from 'react'

const Map = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24" fill="none">
            <g clip-path="url(#clip0_2470_12942)">
                <path d="M4.62036 17.7793C5.31448 18.6639 5.94622 19.5956 6.511 20.5679C6.99322 21.4822 7.19425 22.1023 7.54666 23.204C7.76279 23.8127 7.95813 23.9943 8.37809 23.9943C8.83573 23.9943 9.04336 23.6853 9.20377 23.2073C9.5369 22.1669 9.79828 21.3728 10.2107 20.6226C11.0199 19.1741 12.0254 17.8869 13.0134 16.6498C13.2808 16.2997 15.0101 14.2599 15.7887 12.6505C15.7887 12.6505 16.7455 10.8826 16.7455 8.41343C16.7455 6.10381 15.8019 4.50195 15.8019 4.50195L13.085 5.22954L11.4351 9.57511L11.0269 10.1743L10.9453 10.2829L10.8368 10.4188L10.6462 10.6358L10.3739 10.9081L8.90466 12.105L5.23141 14.2259L4.62036 17.7793Z" fill="#34A853" />
                <path d="M0.829102 12.3525C1.7256 14.4002 3.45439 16.2002 4.62404 17.7799L10.8366 10.4208C10.8366 10.4208 9.9614 11.5654 8.37373 11.5654C6.60525 11.5654 5.17657 10.1532 5.17657 8.37251C5.17657 7.15139 5.91122 6.3125 5.91122 6.3125L1.69395 7.44253L0.829102 12.3525Z" fill="#FBBC04" />
                <path d="M10.906 0.378972C12.9694 1.04424 14.7354 2.44088 15.8037 4.50037L10.8385 10.4172C10.8385 10.4172 11.5732 9.56312 11.5732 8.34959C11.5732 6.52742 10.0388 5.16615 8.38126 5.16615C6.8138 5.16615 5.91357 6.30893 5.91357 6.30893V2.58141L10.906 0.378906V0.378972Z" fill="#4285F4" />
                <path d="M1.96729 2.99281C3.19966 1.51834 5.36816 0 8.35776 0C9.80822 0 10.901 0.380796 10.901 0.380796L5.90897 6.31167H2.37208L1.96729 2.99281Z" fill="#1A73E8" />
                <path d="M0.829157 12.352C0.829157 12.352 0.00439453 10.7369 0.00439453 8.39703C0.00439453 6.18556 0.864078 4.25248 1.96723 2.99219L5.91173 6.31158L0.829157 12.352Z" fill="#EA4335" />
            </g>
            <defs>
                <clipPath id="clip0_2470_12942">
                    <rect width="16.75" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Map