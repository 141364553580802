import React from "react";
import TopNav from "./TopNav";
import { Box } from "@chakra-ui/react";

const ProductImages = ({ images }) => {
  return (
    <div
      className={
        "bg-white rounded-md overflow-hidden border border-[#D9D9D9] w-full"
      }
    >
      <TopNav name={"Product Images"} />

      <Box
        className={"flex-wrap justify-between gap-y-3 grid grid-cols-4 gap-x-3"}
        mt={"2"}
        p={"3"}
        borderRadius={"sm"}
      >
        {images?.map((i) => {
          return (
            <div
              className={
                " relative rounded-md border border-gray-100 flex justify-center"
              }
            >
              <img
                src={i?.image}
                className={"h-full max-h-40 object-contain"}
                alt={i?.image}
              />
            </div>
          );
        })}
      </Box>
    </div>
  );
};

export default ProductImages;
