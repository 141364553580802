import React from 'react'

const RoofplanSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M18.8571 4.44922H5.14286C4.51429 4.44922 4 4.9635 4 5.59208V19.3064C4 19.9349 4.51429 20.4492 5.14286 20.4492H13.7143V19.3064C13.7143 17.7064 14.9714 16.4492 16.5714 16.4492V15.3064C14.3429 15.3064 12.5714 17.0778 12.5714 19.3064H10.8571V17.0206H9.71429V19.3064H5.14286V5.59208H9.71429V13.5921H10.8571V10.7349H13.1429V9.59208H10.8571V5.59208H18.8571V9.59208H16.5714V10.7349H18.8571V19.3064H16.5714V20.4492H18.8571C19.4857 20.4492 20 19.9349 20 19.3064V5.59208C20 4.9635 19.4857 4.44922 18.8571 4.44922Z" fill="#333333" />
        </svg>
    )
}

export default RoofplanSvg