import React from "react";

const RatingStar = ({ w = "22", h = "21" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9867 17.5869L16.3151 20.8096C17.2909 21.4002 18.4849 20.5271 18.2281 19.4229L16.8158 13.3627L21.5279 9.27973C22.3881 8.53504 21.9259 7.1227 20.796 7.03282L14.5946 6.5064L12.1679 0.779998C11.7314 -0.259999 10.242 -0.259999 9.80545 0.779998L7.37879 6.49356L1.17732 7.01998C0.0474515 7.10986 -0.41477 8.5222 0.445475 9.26689L5.15756 13.3498L3.74522 19.4101C3.48843 20.5143 4.6825 21.3874 5.6583 20.7967L10.9867 17.5869Z"
        fill="#F1C94C"
      />
    </svg>
  );
};

export default RatingStar;
