import React, { useEffect, useRef, useState } from "react";
import { Box, Center, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";

import { useMutation } from "react-query";

import { useCustomToast } from "../../../hooks/useToast";
import CButton from "../../../components/general/CButton";
import useError from "../../../hooks/useError";
import CarouselServices from "../../../services/CarouselServices";

const AddCarousel = ({
  handleClose: cHandleClose,
  isOpen,
  mode,
  editData,
  refetch,
  reset,
}) => {
  const showToast = useCustomToast();
  const handleError = useError();
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    cHandleClose();
  };

  const handleSaveCarousel = async () => {
    let formData = new FormData();
    Object.keys(image)?.forEach((i) => formData.append("image", image[i]));
    await CarouselServices.addCarousel(formData);
  };

  const mutation = useMutation(() => handleSaveCarousel(), {
    onSuccess: async () => {
      handleClose();
      reset();
      await refetch();
      showToast("Carousel saved.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation?.mutate();
  };

  useEffect(() => {
    if (mutation?.isError) {
      handleError(mutation?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation]);

  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            Addd Carousel
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex w-full">
          <VStack gap={5} w={"full"}>
            <Box mt={2} w={"full"}>
              <HStack justifyContent={"space-between"} flex={1}>
                <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                  Add Carousel Image
                </FormLabel>

                {Object.keys(image)?.length > 0 && (
                  <FormLabel
                    className={"font-Barlow text-sm text-primary-blue"}
                  >
                    {image?.length} Added
                  </FormLabel>
                )}
              </HStack>

              <Box
                className={
                  "flex-wrap border border-gray-400 justify-between gap-y-3"
                }
                display={"flex"}
                mt={"2"}
                p={"3"}
                borderRadius={"sm"}
              >
                {Object?.keys(image)?.map((i) => {
                  if (!image[i]) return <></>;
                  return (
                    <div className={"w-[30%] relative "}>
                      <img
                        src={URL.createObjectURL(image[i])}
                        className={"h-full max-h-40 object-contain mb-2"}
                        alt={URL.createObjectURL(image[i])}
                      />

                      <CButton
                        type={"button"}
                        className={
                          "bg-none border border-primary-red text-primary-red h-6 p-0 py-0 text-sm font-Barlow font-medium px-3"
                        }
                        onClick={() => {
                          setImage((prev) => ({ ...prev, [i]: null }));
                        }}
                      >
                        Remove
                      </CButton>
                    </div>
                  );
                })}
                <Uploader handleChange={(e) => setImage(e.target?.files)} />
              </Box>
            </Box>
          </VStack>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />
          <CButton
            className={
              "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
            }
            text={"Save"}
            type={"submit"}
          />

          {/*)}*/}
        </HStack>
      </form>
    </Modal>
  );
};

export default AddCarousel;

const Uploader = ({ handleChange }) => {
  const uploadRef = useRef();

  const handleUpload = () => {
    if (uploadRef?.current) {
      uploadRef.current.click();
    }
  };

  return (
    <Center
      className={"border border-dashed border-grey_500 h-40 w-[30%] rounded-lg"}
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload Image"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={uploadRef}
        onChange={handleChange}
      />
    </Center>
  );
};
