import React, { useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import CInput from "../../../components/general/CInput";
import { ConfigProvider, Popconfirm, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import CButton from "../../../components/general/CButton";
import { DeleteSvg, EditSvg } from "../../../assets/icons";
import { Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import { AddCategory } from "../../../components/product_category";
import { ENDPOINT } from "../../../services/AxiosServices";
import AddPickupCenter from "./AddPickupCenter";
import PickupCenterService from "../../../services/PickupCenterServices";

const PickupCenter = () => {
  const { isLoading, data, refetch } = useCustomQuery(
    Paths.pickupUrl + "?page_size=1000"
  );

  const showToast = useCustomToast();

  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState("create");
  const [editState, setEditState] = useState({});

  // activate/deactivate staff mutation

  const handleDelete = async (id) => {
    await PickupCenterService.deleteCenter(id);
    refetch();
    showToast("Pickup center deleted");
  };

  const handleEdit = (item) => {
    setMode("update");
    setEditState(item);
    setShowForm(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },

    {
      title: "Actions",
      width: 250,
      render: (_, item) => (
        <div className={"flex gap-x-5 "}>
          <CButton
            className={
              " h-10 px-3 border border-primary-red rounded flex items-center text-primary-red font-Barlow font-[500] gap-x-2"
            }
            onClick={() => handleEdit(item)}
          >
            <EditSvg />
            Edit
          </CButton>

          <CButton onClick={() => handleDelete(item.id)}>
            <DeleteSvg />
          </CButton>
        </div>
      ),
    },
  ];

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav />

      <div className={"pt-5 "}>
        {/*header */}
        <div className={"flex justify-end px-8 py-3"}>
          <CButton
            onClick={() => {
              setShowForm(true);
              setMode("create");
            }}
            className={
              "bg-primary-blue py-0 px-4 h-10 text-white font-inter flex items-center justify-center gap-x-3"
            }
            text={"Add Pickup Center"}
            icon={<span className={"text-xl leading-3 mb-1"}>+</span>}
          />
        </div>

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS["primary-blue"],
            },
          }}
        >
          <Table
            loading={isLoading}
            dataSource={data?.data?.results}
            columns={columns}
            rowKey={"id"}
          />
        </ConfigProvider>
      </div>

      <AddPickupCenter
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        editData={editState}
        reset={() => {
          setMode("create");
          setEditState({});
        }}
        mode={mode}
        refetch={refetch}
      />
    </div>
  );
};

export default PickupCenter;

const TopNav = () => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    <h6 className={"text-xl font-Barlow font-[700] py-4"}>Pickup Centers</h6>
  </div>
);
