import React from "react";

const Products = ({ color = "#333333" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.69 2.08193C11.7844 2.028 11.8913 1.99963 12 1.99963C12.1087 1.99963 12.2156 2.028 12.31 2.08193L16.9975 4.76074C17.0932 4.81538 17.1727 4.89434 17.228 4.98961C17.2833 5.08488 17.3125 5.19308 17.3125 5.30325V10.2971L21.685 12.7971C21.7803 12.8516 21.8596 12.9301 21.9149 13.0249C21.9702 13.1197 21.9995 13.2274 22 13.3372V18.6948C22 18.8049 21.9708 18.9131 21.9155 19.0084C21.8602 19.1037 21.7807 19.1826 21.685 19.2373L16.9975 21.9161C16.9031 21.97 16.7962 21.9984 16.6875 21.9984C16.5788 21.9984 16.4719 21.97 16.3775 21.9161L12 19.416L7.6225 21.9173C7.52808 21.9713 7.42123 21.9996 7.3125 21.9996C7.20377 21.9996 7.09692 21.9713 7.0025 21.9173L2.315 19.2385C2.21934 19.1839 2.13982 19.1049 2.08451 19.0097C2.02919 18.9144 2.00004 18.8062 2 18.696V13.3384C2.00004 13.2282 2.02919 13.12 2.08451 13.0248C2.13982 12.9295 2.21934 12.8505 2.315 12.7959L6.6875 10.2971V5.30325C6.68754 5.19308 6.71669 5.08488 6.77201 4.98961C6.82732 4.89434 6.90684 4.81538 7.0025 4.76074L11.69 2.08193ZM7.3125 11.3796L3.885 13.3384L7.3125 15.2972L10.74 13.3384L7.3125 11.3796ZM11.375 14.4159L7.9375 16.3797V20.2973L11.375 18.3335V14.4159ZM12.625 18.3335L16.0625 20.2973V16.3797L12.625 14.4159V18.3335ZM13.26 13.3384L16.6875 15.2972L20.115 13.3384L16.6875 11.3796L13.26 13.3384ZM16.0625 10.2971V6.37952L12.625 8.34456V12.2621L16.0625 10.2971ZM11.375 12.2621V8.34456L7.9375 6.37952V10.2971L11.375 12.2621ZM8.5725 5.30325L12 7.26204L15.4275 5.30325L12 3.34446L8.5725 5.30325ZM20.75 14.4159L17.3125 16.3797V20.2973L20.75 18.3335V14.4159ZM6.6875 20.2973V16.3797L3.25 14.4159V18.3335L6.6875 20.2973Z"
        fill={color}
      />
    </svg>
  );
};

export default Products;
