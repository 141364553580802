import React, { useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import CInput from "../../../components/general/CInput";
import { ConfigProvider, Switch, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import CButton from "../../../components/general/CButton";
import { Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import productServices from "../../../services/ProductServices";
import { useCustomToast } from "../../../hooks/useToast";
import { Link, useNavigate } from "react-router-dom";
import { ENDPOINT } from "../../../services/AxiosServices";
import Images from "./images";
import ViewPendingQuote from "./ViewPendingQuote";
import QuoteServices from "../../../services/QuoteServices";
import { ArrowUpOutlined, DownloadOutlined } from "@ant-design/icons";
import ReactNativeBlobUtil from "react-native-blob-util";
import DownloadButton from "./Download";

const PendingQuotes = () => {
  const { isLoading: loadingSearch, data: searchData } = useCustomQuery(
    Paths.quoteUrl
  );

  const showToast = useCustomToast();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [filename, setFileName] = useState("");
  const [filedate, setFileDate] = useState("");
  const [file_code, setFilecode] = useState("");
  const [id, setID] = useState("");
  const [url, setUrl] = useState("");

  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(serverDate).toLocaleDateString(undefined, options);
  }

  const columns = [
    {
      title: "Plan Review",
      dataIndex: "roof_plan_file_code",

      render: (_, item) => {
        const hasFullLink = item?.roof_plan_file_code[0]?.includes(ENDPOINT);
        return (
          <Box
            className={"bg-white relative border border-gray-200 rounded-lg"}
            h={"12"}
            w={20}
            px={"1"}
          >
            <Images
              filecode={item.roof_plan_file_code}
              classname={"h-full w-full object-contain"}
            />
          </Box>
        );
      },
    },
    {
      title: "Plan Name",
      dataIndex: "roof_plan_file_name",
      key: "roof_plan_file_name",
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => (
        <p className={"text-ellipsis line-clamp-1"}>
          {item.user.first_name} {item.user.last_name}
        </p>
      ),
    },
    {
      title: "Upload Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, item) => (
        <p className={"text-ellipsis line-clamp-1"}>
          {formatDate(item.created_at)}
        </p>
      ),
    },
    {
      title: "Download Plan",
      dataIndex: "description",
      render: (_, item) => (
        <DownloadButton
          fileUrl={`${ENDPOINT}/media/roofplans/${item.roof_plan_file_name}`}
          fileName={item.roof_plan_file_name}
          name="Download"
        />
      ),
    },
    {
      title: "Upload Quote",
      dataIndex: "unit_name",
      render: (_, item) => (
        <p
          className={
            "text-ellipsis line-clamp-1 bg-[#2A3698] p-2 text-white rounded text-center items-center"
          }
          onClick={() => {
            setShowForm(true);
            setFilecode(item.roof_plan_file_code);
            setFileDate(formatDate(item.created_at));
            setFileName(item.roof_plan_file_name);
            setID(item.id);
          }}
        >
          Upload <ArrowUpOutlined style={{ fontSize: 18 }} />
        </p>
      ),
    },
  ];

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav />

      <div className={"pt-5"}>
        {/*header */}

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS["primary-blue"],
            },
          }}
        >
          <Table
            loading={loadingSearch}
            dataSource={
              searchQuery?.trim() !== ""
                ? searchData?.data
                : searchData?.data?.results
            }
            columns={columns}
            rowKey={"id"}
            rowClassName={"cursor-pointer"}
            onRow={(record) => {
              return {
                // click row
              };
            }}
          />
        </ConfigProvider>
        <ViewPendingQuote
          isOpen={showForm}
          handleClose={() => setShowForm(false)}
          filecode={file_code}
          file_date={filedate}
          file_name={filename}
          id={id}
        />
      </div>
    </div>
  );
};

export default PendingQuotes;

const TopNav = ({ current = 0 }) => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    <h6 className={"text-xl font-Barlow font-[700] py-4"}>Pending Quotes</h6>
  </div>
);
