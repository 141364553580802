import { Table } from "antd";
import React, { useEffect, useState } from "react";
import TopNav from "../../products/view/TopNav";
import { ENDPOINT } from "../../../services/AxiosServices";
import axios from "axios";
import { useParams } from "react-router-dom";
import ViewPricing from "../../../assets/icons/ViewPricing";
import { Box } from "@chakra-ui/react";
import PricingModal from "./PricingModal";

const OrderedProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [prodid, setProid] = useState("");

  const { id } = useParams();

  const fetchOrders = async () => {
    setLoading(true);
    const state = JSON.parse(localStorage.getItem("_token"));
    const authToken = state["access-token"];

    const url = ENDPOINT + `/api/v1/cart/order/${id}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const orderData = response?.data;
      setData(orderData?.order_items?.flatMap((item) => item.items) || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      title: "Thumbnails",
      dataIndex: "id",
      key: "thumbnails",
      render: (_, item) => {
        return (
          <Box
            className={"bg-white relative border border-gray-200 rounded-lg"}
            h={"12"}
            w={20}
            px={"1"}
          >
            <div className="bg-white relative border border-gray-200 rounded-lg">
              {/* Add your thumbnail content here */}
              <img
                className={"h-full w-full object-contain"}
                src={item.product?.thumbnails[0]?.image}
                alt={"img"}
              />
            </div>
          </Box>
        );
      },
    },
    {
      title: "Product Name",
      dataIndex: "product",
      key: "product_name",
      render: (product) => {
        return <span>{product.name}</span>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Category",
      dataIndex: "product",
      key: "category",
      render: (product) => {
        return <span>{product.category}</span>;
      },
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
    },
    {
      title: "Pricing Model",
      dataIndex: "id",
      key: "pricing_model",
      render: (_, item) => {
        return (
          <a
            className="flex gap-2"
            onClick={() => {
              setShowForm(true);
              setProid(item?.id);
            }}
          >
            <ViewPricing /> view
          </a>
        );
      },
    },
  ];

  return (
    <div>
      <TopNav name={"Ordered Products"} />
      <Table columns={columns} loading={loading} dataSource={data} />
      <PricingModal
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        prodID={prodid}
      />
    </div>
  );
};

export default OrderedProducts;
