import React from "react";

const Edit = () => {
  return (
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9 16.7H13.5C13.7387 16.7 13.9676 16.7948 14.1364 16.9636C14.3052 17.1324 14.4 17.3613 14.4 17.6C14.4 17.8387 14.3052 18.0676 14.1364 18.2364C13.9676 18.4052 13.7387 18.5 13.5 18.5H0.9C0.661305 18.5 0.432387 18.4052 0.263604 18.2364C0.0948213 18.0676 0 17.8387 0 17.6C0 17.3613 0.0948213 17.1324 0.263604 16.9636C0.432387 16.7948 0.661305 16.7 0.9 16.7ZM0 12.2L9 3.2L11.7 5.9L2.7 14.9H0V12.2ZM9.9 2.3L11.7 0.5L14.4 3.2L12.5991 5.0009L9.9 2.3Z"
        fill="#DC2A25"
      />
    </svg>
  );
};

export default Edit;
