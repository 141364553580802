import React, { useEffect, useState } from "react";
import TopNav from "../../../components/products/view/TopNav";
import { Table } from "antd";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";

const Fundis = () => {
  const {
    isLoading,
    data: fundis,
    refetch,
  } = useCustomQuery(Paths.adminFundisUrl + "?page_size=1000");

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setData(fundis?.data?.results);
  }, [fundis]);

  const filteredOrders = data?.filter((item) => {
    return item.user.first_name.toLowerCase().includes(query.toLowerCase());
  });

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order",
    },
    {
      title: "Customer Name",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return (
          <p>
            {item?.user.first_name} {item?.user.last_name}
          </p>
        );
      },
    },
    {
      title: "Customer Email",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return <p>{item?.user.email}</p>;
      },
    },
    {
      title: "Fundi name",
      dataIndex: "payment_plan",
      key: "payment_plan",
      render: (_, item) => {
        return <p>{item?.name}</p>;
      },
    },
    {
      title: "Fundi contact",
      dataIndex: "payment_plan",
      key: "payment_plan",
      render: (_, item) => {
        return <p>{item?.contact}</p>;
      },
    },
  ];

  return (
    <>
      <TopNav name={"Fundis"} />
      <div className={"flex justify-between px-8 py-3 text-center"}>
        <input
          className="h-12 border w-64 rounded flex items-center"
          placeholder="search customer name.."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredOrders}
        loading={isLoading}
      />
    </>
  );
};

export default Fundis;
