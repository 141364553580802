import {Layout} from "antd";
import React from "react";
import {Navbar} from "../components/general";
import SideNav from "../components/SideNav";

// const {Content, Sider} = Layout;

const AdminLayout = ({ children }) => {
  // const {setToken, token} = useContext(Context)
  // const handleLogout = () => {
  //     if (window?.confirm('Are you sure you want to logout?')) {
  //         //     remove token from localstorage
  //         localStorage.removeItem("_token");
  //         setToken(null)
  //     }
  // }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/*<Sider theme="light" width={250}>*/}
      {/*    <div className="logo h-14 flex items-center justify-center font-semibold text-lg text-blue -900">*/}
      {/*        ROYAL MABATI {token?.role?.toUpperCase() || "ADMIN"}*/}
      {/*    </div>*/}
      {/*    /!*<MenuBar/>*!/*/}
      {/*</Sider>*/}

      <Layout className="site-layout">
        <Navbar />

        <div className={"px-3 pt-3 bg-[#FBFBFF]  flex flex-1"}>
          <div className={" relative flex flex-1"}>
            <SideNav />
            {children}
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
