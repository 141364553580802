import React, { useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import CInput from "../../../components/general/CInput";
import { ConfigProvider, Popconfirm, Table } from "antd";
import { COLORS } from "../../../assets/Theme";
import CButton from "../../../components/general/CButton";
import { DeleteSvg, EditSvg } from "../../../assets/icons";

import { useCustomToast } from "../../../hooks/useToast";

import CarouselImage from "./CarouselImage";
import AddCarousel from "./AddCarousel";
import CarouselServices from "../../../services/CarouselServices";
import { HolderOutlined } from "@ant-design/icons";

const Carousels = () => {
  const { isLoading, data, refetch } = useCustomQuery(
    Paths.carouselUrl + "?page_size=1000"
  );

  // search query state

  const showToast = useCustomToast();

  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState("create");
  const [editState, setEditState] = useState({});

  const handleDelete = async (id) => {
    await CarouselServices.deleteCarousel(id);
    refetch();
    showToast("Carousel Deleted");
  };

  const columns = [
    {
      title: "Order",
      dataIndex: "thumbnail",

      render: (_, item) => {
        return <HolderOutlined style={{ fontSize: 20 }} />;
      },
    },
    {
      title: "Image",
      dataIndex: "name",
      key: "name",
      render: (_, item) => {
        return (
          <CarouselImage filecode={item.image_code} classname={"h-24 w-32"} />
        );
      },
    },

    {
      title: "Actions",
      width: 250,
      render: (_, item) => (
        <div className={"flex gap-x-5 "}>
          <CButton onClick={() => handleDelete(item.id)}>
            <DeleteSvg />
          </CButton>
        </div>
      ),
    },
  ];

  return (
    <div className={"pt-5 rounded-md border border-[#D9D9D9] overflow-hidden"}>
      <TopNav />

      <div className={"pt-5 "}>
        {/*header */}
        <div className={"flex justify-end px-8 py-3"}>
          <CButton
            onClick={() => setShowForm(true)}
            className={
              "bg-primary-blue py-0 px-4 h-10 text-white font-inter flex items-center justify-center gap-x-3"
            }
            text={"Add Carousel"}
            icon={<span className={"text-xl leading-3 mb-1"}>+</span>}
          />
        </div>

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: COLORS["primary-blue"],
            },
          }}
        >
          <Table
            loading={isLoading}
            dataSource={data?.data?.results}
            columns={columns}
            rowKey={"id"}
          />
        </ConfigProvider>
      </div>

      <AddCarousel
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        editData={editState}
        reset={() => {
          setMode("create");
          setEditState({});
        }}
        mode={mode}
        refetch={refetch}
      />
    </div>
  );
};

export default Carousels;

const TopNav = () => (
  <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
    <h6 className={"text-xl font-Barlow font-[700] py-4"}>Carousels</h6>
  </div>
);
