import React from "react";

const TopNav = ({ name }) => {
  return (
    <div className={"px-8 flex gap-x-8 border-b-[2px] border-[#DADADA]"}>
      <h6 className={"text-xl font-Barlow font-[700] py-4"}>{name}</h6>
    </div>
  );
};

export default TopNav;
