import React, { useState } from "react";
import TopNav from "../../products/view/TopNav";
import { COLORS } from "../../../assets/Theme";
import { ConfigProvider, Popconfirm, Table } from "antd";
import { Text } from "@chakra-ui/react";
import moment from "moment";
import CButton from "../../general/CButton";
import { useMutation } from "react-query";
import Paths from "../../../services/query/Paths";
import customerServices from "../../../services/CustomerServices";
import { useCustomToast } from "../../../hooks/useToast";
import useCustomQuery from "../../../services/query/useCustomQuery";
import UpdateCustomer from "./UpdateCustomer";
import { useNavigate } from "react-router-dom";

const Details = ({ details }) => {
  const { refetch } = useCustomQuery(Paths.customersUrl + "?page_size=500");
  const showToast = useCustomToast();
  const navigate = useNavigate();

  const [mode, setMode] = useState("update");
  const [showForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState({});

  // activate/deactivate staff mutation

  const activateDeactivateMutation = useMutation(
    (data) =>
      customerServices?.activateDeactivateCustomer(data?.id, {
        status: data?.status,
      }),
    {
      onSuccess: async () => {
        navigate("/customers");
        await refetch();
        showToast("Customer updated.");
      },
    },
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => {
        return (
          <span>
            {item?.first_name} {item?.last_name}
          </span>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created Account",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (_, item) => (
        <Text>{moment(item?.date_joined).format("DD MMMM YYYY")}</Text>
      ),
    },
    {
      title: "Actions",
      width: 250,
      render: (_, item) => (
        <Popconfirm
          title={item?.is_terminated ? "Activate User?" : "Deactivate user?"}
          description={
            item?.is_terminated
              ? `Are you sure you want to Activate ${item?.first_name}`
              : `Are you sure to Deactivate ${item?.first_name}?`
          }
          onConfirm={() =>
            activateDeactivateMutation.mutate({
              status: item?.is_terminated ? "activate" : "deactivate",
              id: item?.id,
            })
          }
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <CButton
            className={`h-12 text-sm text-white font-[500] ${
              item?.is_terminated ? "bg-[#15CF74]" : "bg-primary-red"
            }`}
            text={item?.is_terminated ? "Activate" : "Deactivate"}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      className={
        "bg-white rounded-md overflow-hidden border border-[#D9D9D9] w-full"
      }
    >
      <TopNav name={details?.first_name + " " + details?.last_name} />

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={activateDeactivateMutation.isLoading}
          dataSource={[{ ...details }]}
          columns={columns}
          rowKey={"id"}
        />
      </ConfigProvider>

      <UpdateCustomer
        isOpen={showForm}
        handleClose={() => setShowForm(false)}
        user={userData}
        reset={() => {
          setMode("update");
          setUserData({});
        }}
        refetch={refetch}
        mode={mode}
      />
    </div>
  );
};

export default Details;
