import React, { useEffect, useRef, useState } from "react";
import { Box, Center, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";

import { useMutation } from "react-query";

import useError from "../../../hooks/useError";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";
import CLoadingBtn from "../../../components/general/CLoadingBtn";
import { useCustomToast } from "../../../hooks/useToast";
import RegionService from "../../../services/RegionServices";

const AddRegion = ({
  handleClose: cHandleClose,
  isOpen,
  mode,
  editData,
  refetch,
  reset,
}) => {
  const showToast = useCustomToast();
  const handleError = useError();

  const [region, setRegion] = useState("");
  const [total_fuel_cost, setFuelCost] = useState("");
  const [security_fee, setSecurityFee] = useState("");
  const [id, setId] = useState("");

  const handleClose = () => {
    setRegion("");
    setFuelCost("");
    setSecurityFee("");
    setId("");
    cHandleClose();
  };

  useEffect(() => {
    if (mode === "update") {
      setRegion(editData?.region);
      setFuelCost(editData?.total_fuel_cost);
      setSecurityFee(editData?.security_fee);
      setId(editData?.id);
    }
  }, [mode, editData]);

  console.log("Mode", mode);

  const handleSaveRegion = async () => {
    let formData = new FormData();
    formData.append("region", region);
    formData.append("total_fuel_cost", total_fuel_cost);
    formData.append("security_fee", security_fee);
    await RegionService.addRegion(formData);
  };
  const handleUpdateRegion = async () => {
    let formData = new FormData();
    formData.append("region", region);
    formData.append("total_fuel_cost", total_fuel_cost);
    formData.append("security_fee", security_fee);

    await RegionService.updateRegion(formData, id);
  };

  const mutation = useMutation(() => handleSaveRegion(), {
    onSuccess: async () => {
      handleClose();
      reset();
      await refetch();
      showToast("Region  saved.");
    },
  });

  const updateMutation = useMutation(() => handleUpdateRegion(), {
    onSuccess: async () => {
      reset();
      handleClose();
      await refetch();
      showToast("Pickup center updated.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === "update") {
      updateMutation.mutate();
    } else {
      mutation?.mutate();
    }
  };

  useEffect(() => {
    if (mutation?.isError) {
      handleError(mutation?.error);
    } else if (updateMutation?.isError) {
      handleError(updateMutation?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation]);

  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            {mode === "create" ? "Create Region" : "Update Region"}
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 gap-4  w-full">
          <Box flex={1}>
            <FormLabel className={" font-Barlow text-sm text-gray-400"}>
              Region's Name
            </FormLabel>

            <CInput
              required
              className={"rounded-md w-full"}
              placeholder={"Enter Region Name"}
              value={region}
              onChange={(e) => {
                setRegion(e.target.value);
              }}
            />
          </Box>

          <div className="mt-8">
            <VStack w={"full"}>
              <HStack w={"full"}>
                <Box flex={1}>
                  <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                    Fuel Cost (Ksh)
                  </FormLabel>
                  <CInput
                    required
                    className={"rounded-md"}
                    placeholder={"Enter total fuel cost"}
                    value={total_fuel_cost}
                    onChange={(e) => setFuelCost(e.target.value)}
                  />
                </Box>
                <Box flex={1}>
                  <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                    Security Fee (ksh)
                  </FormLabel>
                  <CInput
                    required
                    className={"rounded-md"}
                    placeholder={"Enter security fee"}
                    value={security_fee}
                    onChange={(e) => setSecurityFee(e.target.value)}
                  />
                </Box>
              </HStack>
            </VStack>
          </div>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />

          {mutation.isLoading || updateMutation.isLoading ? (
            <div>
              <CLoadingBtn
                className={"w-44"}
                text={updateMutation.isLoading ? "Updating" : "Creating"}
              />
            </div>
          ) : (
            <CButton
              className={
                "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
              }
              text={mode === "update" ? "Update" : "Complete"}
              type={"submit"}
            />
          )}
          {/*)}*/}
        </HStack>
      </form>
    </Modal>
  );
};

export default AddRegion;
