import React, { useState } from "react";
import useCustomQuery from "../../../services/query/useCustomQuery";
import Paths from "../../../services/query/Paths";
import { useNavigate } from "react-router-dom";
import { useCustomToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import customerServices from "../../../services/CustomerServices";
import { queryClient } from "../../../App";
import { ConfigProvider, Popconfirm, Table } from "antd";
import CButton from "../../../components/general/CButton";
import CInput from "../../../components/general/CInput";
import { COLORS } from "../../../assets/Theme";

const Inactive = () => {
  const { isLoading, data, refetch } = useCustomQuery(
    Paths.customersUrl + "?status=inactive&page_size=500"
  );

  const navigate = useNavigate();
  const showToast = useCustomToast();
  const [query, setQuery] = useState("");

  // activate/deactivate staff mutation
  const mutation = useMutation(
    (data) =>
      customerServices?.activateDeactivateCustomer(data?.id, {
        status: data?.status,
      }),
    {
      onSuccess: async () => {
        // handleClose();
        showToast("customer updated.");
        await refetch();
        queryClient.invalidateQueries(Paths.customersUrl);
      },
    }
  );
  const filteredStaff = data?.data?.results.filter((name) => {
    const firstNameMatch = name.first_name
      .toLowerCase()
      .includes(query.toLowerCase());
    const lastNameMatch = name.last_name
      .toLowerCase()
      .includes(query.toLowerCase());

    // Use the logical OR operator to combine the conditions
    return firstNameMatch || lastNameMatch;
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => {
        return (
          <span>
            {item?.first_name} {item?.last_name}
          </span>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Views",
      dataIndex: "views",

      render: (_, record) => {
        return (
          <button
            onClick={() => {
              navigate(`/customers/${record?.id}`, { state: record });
            }}
          >
            <span className={"underline"}>favorites</span>
          </button>
        );
      },
    },
    {
      title: "Actions",
      width: 150,
      render: (_, item) => (
        <div className={"flex gap-x-5"}>
          <Popconfirm
            title={item?.is_terminated ? "Activate User?" : "Deactivate user?"}
            description={
              item?.is_terminated
                ? `Are you sure you want to Activate ${item?.first_name}`
                : `Are you sure to Deactivate ${item?.first_name}?`
            }
            onConfirm={() =>
              mutation.mutate({
                status: item?.is_terminated ? "activate" : "deactivate",
                id: item?.id,
              })
            }
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <CButton
              className={`h-12 text-sm text-white font-[500] ${
                item?.is_terminated ? "bg-[#15CF74]" : "bg-primary-red"
              }`}
              text={item?.is_terminated ? "Activate" : "Deactivate"}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className={"pt-5"}>
      {/*header */}
      <div className={"flex justify-between px-8 py-3"}>
        <CInput
          placeholder={"Search"}
          className={"rounded-xl min-w-[340px]"}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={isLoading || mutation?.isLoading}
          dataSource={filteredStaff}
          columns={columns}
          rowKey={"id"}
        />
      </ConfigProvider>
    </div>
  );
};

export default Inactive;
