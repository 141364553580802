import React, { useEffect, useRef, useState } from "react";
import { Box, Center, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { Modal } from "antd";

import { useMutation } from "react-query";

import useError from "../../../hooks/useError";
import CInput from "../../../components/general/CInput";
import CButton from "../../../components/general/CButton";
import CLoadingBtn from "../../../components/general/CLoadingBtn";
import { useCustomToast } from "../../../hooks/useToast";
import Map from "../../../assets/icons/Map";
import PickupCenterService from "../../../services/PickupCenterServices";

const AddPickupCenter = ({
  handleClose: cHandleClose,
  isOpen,
  mode,
  editData,
  refetch,
  reset,
}) => {
  const showToast = useCustomToast();
  const handleError = useError();

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [id, setId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClose = () => {
    setName("");
    setDesc("");
    setLat("");
    setLong("");
    setId("");
    cHandleClose();
  };

  useEffect(() => {
    if (mode === "update") {
      setName(editData?.name);
      setDesc(editData?.desc);
      setLat(editData?.lat);
      setLong(editData?.long);
      setId(editData?.id);
    }
  }, [mode, editData]);

  const handleSaveCenter = async () => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("desc", desc);
    formData.append("lat", lat);
    formData.append("long", long);
    await PickupCenterService.addCenter(formData);
  };
  const handleUpdateCenter = async () => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("desc", desc);
    formData.append("lat", lat);
    formData.append("long", long);
    await PickupCenterService.updateCenter(formData, id);
  };

  const mutation = useMutation(() => handleSaveCenter(), {
    onSuccess: async () => {
      handleClose();
      reset();
      await refetch();
      showToast("Pickup center saved.");
    },
  });

  const updateMutation = useMutation(() => handleUpdateCenter(), {
    onSuccess: async () => {
      reset();
      handleClose();
      await refetch();
      showToast("Pickup center updated.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mode === "update") {
      updateMutation.mutate();
    } else {
      mutation?.mutate();
    }
  };

  useEffect(() => {
    if (mutation?.isError) {
      handleError(mutation?.error);
    } else if (updateMutation?.isError) {
      handleError(updateMutation?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation]);

  return (
    <Modal
      style={{ margin: "0 0", padding: "0 0" }}
      centered
      open={isOpen}
      width={900}
      footer={null}
      onCancel={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Box
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={"border-b-2 border-gray-300 border-bottom"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"} textColor={"dark_green"}>
            {mode === "update" ? "Update Pickup Center" : "Add Pickup Center"}
          </Text>
        </Box>

        <div className="bg-white py-5 px-10 flex w-full">
          <VStack gap={5} w={"full"}>
            <HStack gap={5} w={"full"}>
              <Box flex={1}>
                <FormLabel className={" font-Barlow text-sm text-gray-400"}>
                  Center's Name
                </FormLabel>

                <CInput
                  className={"rounded-md"}
                  placeholder={"Enter Name"}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Box>

              {/* <Box flex={1}>
                <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                  GPS Location
                </FormLabel>
                <div className="flex border items-center justify-between bg-white">
                  <CInput
                    className={"rounded-md w-[60%] border-none"}
                    placeholder={"Enter latitude"}
                    value={lat}
                    onChange={(e) => setLat(e.target.value)}
                  />
                  <CInput
                    className={"rounded-md w-[60%] border-none"}
                    placeholder={"Enter latitude"}
                    value={long}
                    onChange={(e) => setLong(e.target.value)}
                  />
                  <a className="flex gap-2 text-center pr-2">
                    <Map />
                    Find in Maps
                  </a>
                </div>
              </Box> */}
            </HStack>

            <Box flex={1} className="w-[100%]">
              <FormLabel className={"font-Barlow text-sm text-gray-400"}>
                Location's Description
              </FormLabel>

              <textarea
                className={"rounded-sm h-56 border w-[100%]"}
                placeholder={
                  "Describe the location by county, sub-county and exact location"
                }
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Box>
          </VStack>
        </div>

        {/*  footer*/}
        <HStack
          py={"4"}
          px={"8"}
          fontFamily={"Barlow"}
          className={
            "border-t-2 border-gray-300 border-bottom justify-end gap-x-5"
          }
        >
          <CButton
            className={
              "text-primary-red border border-primary-red font-[700] text-base px-8"
            }
            text={"Cancel"}
            type={"button"}
            onClick={handleClose}
          />

          {mutation.isLoading || updateMutation.isLoading ? (
            <div>
              <CLoadingBtn
                className={"w-44"}
                text={updateMutation.isLoading ? "Updating" : "Creating"}
              />
            </div>
          ) : (
            <CButton
              className={
                "bg-primary-red text-white border border-primary-red font-[700] text-base px-8"
              }
              text={mode === "update" ? "Update" : "Complete"}
              type={"submit"}
            />
          )}
          {/*)}*/}
        </HStack>
      </form>
    </Modal>
  );
};

export default AddPickupCenter;

const Uploader = ({ handleChange }) => {
  const uploadRef = useRef();

  const handleUpload = () => {
    if (uploadRef?.current) {
      uploadRef.current.click();
    }
  };

  return (
    <Center
      className={"border border-dashed border-grey_500 h-40 w-[30%] rounded-lg"}
    >
      <CButton
        className={
          "text-primary-red  border border-primary-red font-[700] text-sm px-5"
        }
        text={"Upload Image"}
        type={"button"}
        onClick={handleUpload}
      />

      <input
        type={"file"}
        multiple
        className={"hidden"}
        ref={uploadRef}
        onChange={handleChange}
      />
    </Center>
  );
};
