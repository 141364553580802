import { PrivateAxiosUtility } from "./AxiosServices";

const addCenter = async (body) => {
  const res = await PrivateAxiosUtility({
    method: "post",
    url: `/api/v1/cart/pickupcenter/add/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};
const updateCenter = async (data, pc_id) => {
  const res = await PrivateAxiosUtility.patch(
    `/api/v1/cart/pickupcenter/update/${pc_id}/`,
    data
  );

  return res?.data;
};
const deleteCenter = async (pc_id) => {
  const res = await PrivateAxiosUtility.delete(
    `/api/v1/cart/pickupcenter/delete/${pc_id}/`
  );

  return res?.data;
};

const PickupCenterService = {
  addCenter,
  updateCenter,
  deleteCenter,
};
export default PickupCenterService;
