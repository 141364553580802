import React, { useEffect, useState } from "react";

import { ConfigProvider, Table } from "antd";

import { COLORS } from "../../../assets/Theme";

import { useCustomToast } from "../../../hooks/useToast";
import { ENDPOINT } from "../../../services/AxiosServices";
import axios from "axios";

import ReactSelect from "react-select";
import { FilterOutlined } from "@ant-design/icons";
import sort_png from "../../../assets/icons/bx_sort.svg";
import { Link } from "react-router-dom";
import CsvDownload from "react-csv-downloader";

const FreeDelivery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date_from, setDate_from] = useState("");
  const [date_to, setDate_to] = useState("");
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [query, setQuery] = useState("");

  const showToast = useCustomToast();

  function formatDate(serverDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = "en-US";

    return new Date(serverDate).toLocaleDateString(locale, options);
  }

  const fetchOrders = async () => {
    setLoading(true);
    const state = await JSON.parse(localStorage?.getItem("_token"));
    let authToken;
    authToken = state["access-token"];

    const url =
      ENDPOINT +
      `/api/v1/auth/users/orders?order_status=SUCCESS,FULLY_PAID&order_type=FREE_DELIVERY&date_from=${date_from}&date_to${date_to}&payment_plan=${
        selectedPaymentPlan?.value || ""
      }`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    setData(response?.data?.results);
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [date_from, date_to, selectedPaymentPlan]);

  const payment_plan = [
    { value: "FULL_PAYMENT", label: "Full Payment" },
    { value: "3_MONTHS", label: "3 Months" },
    { value: "6_MONTHS", label: "6 Months" },
    { value: "9_MONTHS", label: "9 Months" },
    { value: "12_MONTHS", label: "12 Months" },
  ];

  const options = [{ value: "most-recent", label: "Most Recent" }];
  const filteredOrders = data.filter((order) => {
    return order.order_code.toLowerCase().includes(query.toLowerCase());
  });
  const transformDataForCSV = (orders) => {
    return orders.map((order) => {
      return {
        "Order Code": order.order_code,
        "Customer Name": `${order.user.first_name} ${order.user.last_name}`,
        "Payment Plan": order.payment_plan,
        "Payment Status": order.order_status,
        "Pickup Center": order?.pickup_center?.name,
        "Ordered On": `"${formatDate(order.created_at)}"`,
        "Total Price": order.grand_total_price,
        "Order Status":
          order.order_status === "ACCEPTED" ? "Approved" : "Declined",
      };
    });
  };
  const csvHeaders = [
    { id: "Order Code", displayName: "Order Code" },
    { id: "Customer Name", displayName: "Customer Name" },
    { id: "Payment Plan", displayName: "Payment Plan" },
    { id: "Payment Status", displayName: "Payment Status" },
    { id: "Ordered On", displayName: "Ordered On" },
    { id: "Total Price", displayName: "Total Price" },
    { id: "Order Status", displayName: "Order Status" },
  ];

  const columns = [
    {
      title: "Order Code",
      dataIndex: "order_code",
    },
    {
      title: "Customer Name",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, item) => {
        return (
          <Link to={`${item.id}`}>
            <span>
              {item.user.first_name}

              {item.user.last_name}
            </span>
          </Link>
        );
      },
    },
    {
      title: "Payment Plan",
      dataIndex: "payment_plan",
      key: "payment_plan",
    },
    {
      title: "Payment Status",
      dataIndex: "order_status",
      key: "order_status",
      render: (_, item) => {
        const success_payments = ["FULLY_PAID", "SUCCESS"];
        const isPaymentSuccessful = success_payments.includes(
          item?.order_status,
        );

        return (
          <span>
            <div className="flex gap-1">
              <span
                className={`bg-${
                  isPaymentSuccessful ? "[#15CF74]" : "[#FFC806]"
                } rounded sm p-1 pl-2 pr-2`}
              >
                {isPaymentSuccessful ? "Paid" : "Pending"}
              </span>
            </div>
          </span>
        );
      },
    },
    {
      title: "Pick-up Center",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <span>
            {item?.pickup_center?.name ? item?.pickup_center.name : "Null"}
          </span>
        );
      },
    },
    {
      title: "Ordered On",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return <span>{formatDate(item?.created_at)}</span>;
      },
    },
    {
      title: "Total Price",
      dataIndex: "grand_total_price",
      key: "grand_total_price",
      render: (_, item) => {
        return <span>Ksh {item.grand_total_price}</span>;
      },
    },
    {
      title: "Closed On",
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return <span>Canceled</span>;
      },
    },
  ];
  return (
    <div className={"pt-5"}>
      <CsvDownload
        text="Export to CSV"
        datas={transformDataForCSV(filteredOrders)}
        filename={`FreeDelivery${Date.now()}`}
        extension=".csv"
        className="h-12  border bg-blue-500 text-white items-center w-[300px] p-2 rounded-xl ml-8"
        columns={csvHeaders}
      />
      {/*header */}
      <div className={"flex justify-between px-8 py-3 text-center"}>
        <input
          className="h-12 border"
          placeholder="search order..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <div className="text-center flex border gap-4  items-center w-32 justify-center rounded-sm h-12 cursor-pointer">
          <img src={sort_png} height={"40"} />
          <span>Sort</span>
        </div>

        <ReactSelect options={options} isClearable={true} className="w-48" />

        <div className="text-center flex border items-center w-32 gap-4 pr-5 pl-5 justify-between rounded-sm h-12">
          <FilterOutlined style={{ fontSize: 20 }} />
          <span>Filter</span>
        </div>
        <ReactSelect
          options={payment_plan}
          isClearable={true}
          className="w-48 h-12 text-black"
          onChange={setSelectedPaymentPlan}
        />

        <div className="h-10 items-center flex border ">
          <input
            type="date"
            placeholder="from date"
            className=" text-center w-32"
            value={date_from}
            onChange={(e) => setDate_from(e.target.value)}
          />
          <input
            type="date"
            placeholder="to date"
            className=" text-center w-32"
            value={date_to}
            onChange={(e) => setDate_to(e.target.value)}
          />
        </div>
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS["primary-blue"],
          },
        }}
      >
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredOrders}
          rowKey={"id"}
        />
      </ConfigProvider>
    </div>
  );
};

export default FreeDelivery;
