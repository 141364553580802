import Axios from "./AxiosServices";

let url = "/api/v1";
/**
 *
 * @param {Object} aData
 * @desc signs in admin
 * @returns {Promise<Auth>}
 */
const Login = async (aData) => {
  const { data } = await Axios.post(`${url}/auth/user/login/`, aData);

  return data;
};

/**
 *
 * @param {ResetPwdRequest} data
 * @desc resets password
 * @returns {Promise}
 */
const RequestResetPwd = async (data) => {
  const res = await Axios.post(`${url}/auth/user/password/request/`, data);

  return res.data;
};

/**
 *
 * @param {ResetPwd} data
 * @desc resets password
 * @returns {Promise}
 */
const ResetPwd = async (data) => {
  const res = Axios.post(`${url}/auth/user/password/request/`, data);

  return res.data;
};

const AuthServices = { Login, RequestResetPwd, ResetPwd };

export default AuthServices;

/**
 * @typedef {{'access-token':String, 'refresh-token':String}} Auth
 */

/**
 * @typedef {{email:String}} ResetPwdRequest
 */

/**
 * @typedef {{email:String,new_password:String,otp:String}} ResetPwd
 */
